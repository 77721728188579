import React from "react";
import { withStyles} from '@mui/styles';
import { Button, Checkbox, Divider, Grid, Paper, Typography, TextField, IconButton, CircularProgress } from "@mui/material";
import { PermissionService } from "../../../services/PermissionService";
import INewPasswordState from "../../../interfaces/components/Public/PasswordReset/INewPasswordState";
import Header from "../../Layout/Header";
import LButton from "../../Layout/Elements/Pieces/LButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Background from "../../Layout/Background";
import { Redirect } from "react-router-dom";
import { config } from "../../../config";
import UserService from "../../../services/UserService";
import { ErrorCodes } from "../../../Enums/ErrorCodes";
import INewPasswordProps from "../../../interfaces/components/Public/PasswordReset/INewPasswordProps";
import StringDistributor from "../../../util/StringDistributor";
import IPasswordReset from "../../../models/Communication/IPasswordReset";

const useStyles = (theme: any) => ({
    paper: {
        margin: "auto",
        display: "flex" as 'flex',
        flexDirection: "column" as 'column',
        justifyContent: "space-between" as 'space-between',
        padding: "20px",
        alignItems: "center"
    },
    errorPaper: {
        marginTop: "10px",
        backgroundColor: "#f2625a !important",
        color: "white !important",
        padding: "20px",
        alignItems: "center" as 'center'
    },
    successPaper: {
        marginTop: "10px",
        backgroundColor: "#69db75 !important",
        color: "white !important",
        padding: "20px",
        alignItems: "center" as 'center'
    },
    body: {
        width: "70%",
        margin: "auto" as 'auto',
        display: "flex" as 'flex',
        flexDirection: "column" as 'column',
        justifyContent: "space-between" as 'space-between',
        alignItems: "center" as 'center'
    }
}); 

class NewPassword extends React.Component<INewPasswordProps, INewPasswordState> {
    constructor(props: INewPasswordProps) {
        super(props);

        this.state = {
            error: false,
            success: false,
            newPassword1: "",
            newPassword2: "",
            notIdentical: false,
            toLogin: false,
            progress: false
        }
    }

    componentDidMount() {
        let token = window.location.href.split("/")[4];
        console.log(token)
    }

    private resetPassword() {

        if((this.state.newPassword1 === this.state.newPassword2)) {
            let reset : IPasswordReset = {
                expiryToken: window.location.href.split("/")[4],
                newPassword: this.state.newPassword1
            }
            UserService.resetPasswort(this.resetSuccess.bind(this), this.resetError.bind(this), reset);
            this.setState({error: false, notIdentical: false, progress: true})
        } else {
            this.setState({notIdentical: true})
        }
    }

    private resetSuccess(ans: any) {
        console.log(ans)
        this.setState({success: true})
    }

    private resetError(err: any) {
        console.log(err)
        this.setState({error: true, progress: false})
    }

    private changeNewPass1(newPass: string) {
        this.setState({newPassword1: newPass})
    }

    private changeNewPass2(newPass: string) {
        this.setState({newPassword2: newPass})
    }

    private toLogin() {
        this.setState({toLogin: true})
    }

    render(){
        let {classes} = this.props;
        
        let strings = StringDistributor.get().login;

        if(this.state.toLogin) {
            return (<Redirect to={"/" + config.loginRoute}/>);
        }

        return(
            <div>
                <Header/>
                <Background >
                    {!this.state.success && 
                        <div className={classes.body}>
                            <Paper className={classes.paper}>
                                <Typography>{strings.enterNewPassword}</Typography>
                                <TextField
                                    label={"Neues Passwort"}
                                    value={this.state.newPassword1}
                                    size="small"
                                    onChange={(e) => {this.changeNewPass1(e.target.value)}}
                                    sx={{width: "100%", marginTop: "20px"}}
                                />
                                <TextField
                                    label={"Neues Passwort wiederholen"}
                                    value={this.state.newPassword2}
                                    size="small"
                                    onChange={(e) => {this.changeNewPass2(e.target.value)}}
                                    sx={{width: "100%", marginTop: "20px"}}
                                />
                                {!this.state.progress &&
                                    <LButton 
                                        sx={{width: "60%", marginTop: "20px"}} 
                                        label={strings.changePassword} 
                                        onClick={() => {this.resetPassword()}}
                                    />
                                }
                                {this.state.progress &&
                                    <CircularProgress 
                                        sx={{
                                            marginTop: "1em",
                                            color: "#e5342a"
                                        }}
                                    />
                                }
                            </Paper>
                            {this.state.notIdentical &&
                                <Paper className={classes.errorPaper}>
                                    <Typography>
                                        {strings.notIdentical}
                                    </Typography>
                                </Paper>
                            }
                            {this.state.error &&
                                <Paper className={classes.errorPaper}>
                                    <Typography>
                                        {strings.error}
                                    </Typography>
                                </Paper>
                            }
                        </div>
                    }
                    {this.state.success &&
                            <div className={classes.body}>
                                <Paper className={classes.successPaper}>
                                    <Typography style={{margin: "0 auto" as '0 auto'}}>
                                        {strings.changedSuccess}
                                    </Typography>
                                    <LButton 
                                        sx={{width: "30%", margin: "0 auto" as '0 auto', marginTop: "20px"}} 
                                        label={"Zurück zum Login"} 
                                        onClick={() => {this.toLogin()}}
                                    />
                                </Paper>
                            </div>
                        }
                </Background>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(NewPassword);