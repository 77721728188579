import * as React from 'react';
import { withStyles } from '@mui/styles';
import ILoginPageProps from '../../../interfaces/components/Public/Auth/ILoginPageProps';
import ILoginPageState from '../../../interfaces/components/Public/Auth/ILoginPageState';

import Grid from '@mui/material/Grid';
import Header from '../../Layout/Header';
import { Button, Box, Checkbox, FormControlLabel, Typography, InputBase, Tab, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CenterFocusStrong, Password } from '@mui/icons-material';
import { Tabs } from '@mui/material';
import { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ILoginForm from '../../../models/ILoginForm';
import AuthService from '../../../services/AuthService';
import { config } from '../../../config';
import StringDistributor from '../../../util/StringDistributor';
import Register from './Register';
import { Container } from '@mui/system';
import Login from './Login';

const useStyles = (theme: any) => ({
    container: {
        display: "flex" as 'flex',
        flexDirection: "row" as 'row',
        marginTop: "100px",
        margin: "auto"
    },
    test: {
        backgroundColor: "#F4F9F8",
        marginTop: "12%",
        padding: "5%",
        width: '100%'
    },
    title: {
        paddingBottom: "1%",
        color: theme.palette.primary.main,

    },
    mainTitle: {
        color: theme.palette.primary.main,
    },
    checkBokDiv: {

    },
    mainLogo: {
        width: "150%",
        display: 'flex',
        alignContent: "left",
        justifyContent: "center",
        color: theme.palette.primary.main,
        alignItems: 'center',
        maxHeight: "100%",

    },
    rightSide: {
        marginTop: "10%"
    },
    textFieldCss: {
        backGrounfColor: "color: theme.palette.primary.main,",
        width: "71%",
        borderLeft: 'red solid 2px'
    },
    textColor: {
        color: theme.palette.primary.main,
    },
    btnAnmelden: {
        color: theme.palette.primary.main,

    }
});



class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {

    constructor(props: ILoginPageProps) {
        super(props);

        this.state = {
            progress: false,
            finish: false,
            login: true,
            email: '',
            password: '',
            value: '',
            smaller: (window.innerWidth <= 910),
            mobile: (window.innerWidth <= 600)
        }
    }

    private handelSubmit(e: any) {
        this.setState({ password: e.target.value as string });
        console.log(Password)
    }

    private switchLogin() {
        this.setState({login: true})
    }

    private switchRegister() {
        this.setState({login: false})
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        let makeSmaller = (window.innerWidth <= 910);
        let makeMobile = (window.innerWidth <= 600);
        if (makeSmaller !== this.state.smaller || makeMobile !== this.state.mobile) {
            this.setState({smaller: makeSmaller, mobile: makeMobile});
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        let strings = StringDistributor.get().login;

        let { classes } = this.props;

        if(this.state.finish) {
            return (<Redirect to={"/" + config.overviewRoute}/>);
        }

        return (
            <div>
                <Header />
                <Grid container className={classes.container}>
                    {!this.state.smaller && !this.state.mobile &&
                        <Grid item xs={6} sm={6} md={6}
                            style={{display: "flex", justifyContent: "center"}}
                        >
                            <img style={{ height: "35%", }} src="./Assets/Imgs/main.png" alt="" />
                        </Grid>
                    }
                    {this.state.smaller && !this.state.mobile &&
                        <Grid item xs={6} sm={6} md={6}
                            style={{display: "flex", justifyContent: "center"}}
                        >
                            <img style={{ height: "35%", marginTop: "2em"}} src="./Assets/Imgs/Logo.png" alt="" />
                        </Grid>
                    }
                    {this.state.mobile &&
                        <Grid item 
                            xs={12} sm={12} md={12}
                            style={{marginLeft: "4em"}}
                        >
                            <Typography variant="h4" className={classes.title} style={{ paddingBottom: 10, }}>
                                {strings.nice}
                            </Typography>

                            <div style={{display: "flex", flexDirection: "row", marginBottom: "2em"}}>
                                <Button variant="contained"
                                    sx={{
                                        marginTop: "1em",
                                        marginRight: "2em",
                                        borderRadius: "25px",
                                        color: "#ffffff",
                                        backgroundColor: "#ed8a85",
                                        "&:hover": {
                                            backgroundColor: "#e5342a"
                                        },
                                        ...(this.state.login &&
                                                { 
                                                    backgroundColor: "#e5342a",
                                                    "&:hover": {
                                                        backgroundColor: "#e5342a"
                                                    }
                                                }
                                            ),
                                        
                                    }}
                                    onClick={() => this.switchLogin()}>
                                    {strings.login}
                                </Button>
                                <Button variant="contained"
                                    sx={{
                                        marginTop: "1em",
                                        color: "#ffffff",
                                        borderRadius: "25px",
                                        backgroundColor: "#ed8a85",
                                        "&:hover": {
                                            backgroundColor: "#e5342a"
                                        },
                                        ...(!this.state.login &&
                                            { 
                                                backgroundColor: "#e5342a",
                                                "&:hover": {
                                                    backgroundColor: "#e5342a"
                                                }
                                            }
                                        ),
                                    }}
                                    onClick={() => this.switchRegister()}>
                                    {strings.register}
                                </Button>
                            </div>
                            {this.state.login &&
                                <Login />
                            }   
                            {!this.state.login &&
                                <Register />
                            }   
                            
                        </Grid>
                    }
                    {!this.state.mobile && 
                        <Grid item 
                            xs={6} sm={6} md={6}
                        >
                            <Typography variant="h4" className={classes.title} style={{ paddingBottom: 10, }}>
                                {strings.nice}
                            </Typography>

                            <div style={{display: "flex", flexDirection: "row", marginBottom: "2em"}}>
                                <Button variant="contained"
                                    sx={{
                                        marginTop: "1em",
                                        marginRight: "2em",
                                        borderRadius: "25px",
                                        color: "#ffffff",
                                        backgroundColor: "#ed8a85",
                                        "&:hover": {
                                            backgroundColor: "#e5342a"
                                        },
                                        ...(this.state.login &&
                                                { 
                                                    backgroundColor: "#e5342a",
                                                    "&:hover": {
                                                        backgroundColor: "#e5342a"
                                                    }
                                                }
                                            ),
                                        
                                    }}
                                    onClick={() => this.switchLogin()}>
                                    {strings.login}
                                </Button>
                                <Button variant="contained"
                                    sx={{
                                        marginTop: "1em",
                                        color: "#ffffff",
                                        borderRadius: "25px",
                                        backgroundColor: "#ed8a85",
                                        "&:hover": {
                                            backgroundColor: "#e5342a"
                                        },
                                        ...(!this.state.login &&
                                            { 
                                                backgroundColor: "#e5342a",
                                                "&:hover": {
                                                    backgroundColor: "#e5342a"
                                                }
                                            }
                                        ),
                                    }}
                                    onClick={() => this.switchRegister()}>
                                    {strings.register}
                                </Button>
                            </div>
                            {this.state.login &&
                                <Login />
                            }   
                            {!this.state.login &&
                                <Register />
                            }   
                            
                        </Grid>
                    }
                </Grid> 
                    {/* <Grid container xs={12} sm={12} md={6} lg={6} className={classes.mainLogo} style={{
                            display: 'flex',
                            alignContent: "start",
                            justifyContent: "center",
                            marginTop: '5%'

                        }} >
                            <img style={{ height: "35%", }} src="./Assets/Imgs/main.png" alt="" />
                    </Grid>
                    <Grid container xs={12} sm={12} md={6} className={classes.rightSide}>
                        <Typography variant="h4" className={classes.title} style={{ paddingBottom: 10, }}>
                                schön, dass du da bist
                        </Typography>
                        <br />

                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Button variant="contained"
                                sx={{
                                    marginTop: "1em",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    "&:hover": {
                                        backgroundColor: "#eb7771"
                                    }
                                }}
                                onClick={() => this.switchLogin()}>
                                Login
                            </Button>
                            <Button variant="contained"
                                sx={{
                                    marginTop: "1em",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    "&:hover": {
                                        backgroundColor: "#eb7771"
                                    }
                                }}
                                onClick={() => this.switchRegister()}>
                                Registrieren
                            </Button>
                        </div>

                        <br />  
                    </Grid>   */}

                    {/* {this.state.login &&

                        <Grid container xs={12} sm={12} md={12} spacing={1} className={classes.test}>

                            <Typography style={{ fontFamily: 'Times New Roman' }}
                            >Willkomen zurück! Jetzt an Deinem Konto anmelden
                            </Typography>
                            <br />
                    
                            <Grid item xs={12} sm={11} md={6} lg={11} className={classes.textFieldCss}>
                                <TextField
                                    id="email"
                                    placeholder="Email*"
                                    required
                                    fullWidth
                                    name="email"
                                    type="email"
                                    InputLabelProps={{ className: classes.textColor }}
                                    onChange={(e) => {this.onMailChange(e.target.value)}}
                                />

                                <TextField
                                    onKeyUp={this.onKeyUp.bind(this)}
                                    id="password"
                                    placeholder="password"
                                    required
                                    fullWidth
                                    name="password"
                                    type="password"
                                // value={this.state.password}
                                    onChange={(e) => {this.onPasswordChange(e.target.value)}}
                                />
                            </Grid>
                            <Grid style={{ marginTop: '2%' }} item xs={12} md={12} sm={12} container spacing={2}
                                className={classes.checkBoxDiv}>
                                <Grid item xs={6} md={6} sm={6} container spacing={1}>
                                    <FormControlLabel control={<Checkbox
                                        style={{
                                            color: "#e5342a"
                                        }}

                                        defaultChecked />}
                                        label="an mich erinnern" />
                                </Grid>
                                <Grid item xs={6} md={6} sm={6} lg={6}>
                                    <Link style={{ textDecoration: 'none', color: "#333535" }} to="">
                                        Passwort vergessen?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: '10%' }} >
                                {!this.state.progress && 
                                    <Button variant="contained"
                                        sx={{
                                            marginTop: "1em",
                                            marginRight: "14.5em",
                                            float: "right",
                                            color: "#ffffff",
                                            backgroundColor: "#e5342a",
                                            "&:hover": {
                                                backgroundColor: "#eb7771"
                                            }
                                        }}
                                        onClick={() => this.login()}>
                                        anmelden
                                    </Button>
                                }
                                {this.state.progress &&
                                    <CircularProgress 
                                        sx={{
                                            marginTop: "1em",
                                            marginRight: "14.5em",
                                            float: "right",
                                            color: "#e5342a"
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid> */}
                
                    {/* {!this.state.login &&
                        <Register /> */}
            </div >
        )
    }
}
export default withStyles(useStyles, { withTheme: true })(LoginPage);