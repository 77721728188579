import React, { ReactNode } from "react";

import { withStyles} from '@mui/styles';
import ISideBarProps from "../../interfaces/components/Layout/ISideBarProps";
import ISideBarState from "../../interfaces/components/Layout/ISideBarState";

import  SideBarButton  from "../Layout/Elements/SidebarButton";
import SideBarButtonModel from "../../models/SideBarButtonModel";
import { Link, Redirect } from "react-router-dom";
import { config } from "../../config";
import ArrayHelper from "../../util/ArrayHelper";

import { lato } from "../../Fonts/Lato";
import { Collapse, Drawer, IconButton, Typography } from "@mui/material";
import AuthService from "../../services/AuthService";
import NavService from "../../services/NavService";
import { PermissionService } from "../../services/PermissionService";
import MenuIcon from '@mui/icons-material/Menu';

import SchoolIcon from '@mui/icons-material/School';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const useStyles = (theme: any) => ({
    buttonDiv: {
        width: "20%",
        display: 'flex',
        flexDirection: "column" as 'column',
        marginRight: "20px"
    },
    drawer: {
        display: 'flex',
        flexDirection: "column" as 'column',
        marginRight: "20px",
        // backgroundColor: "white",
        borderRadius: "5px",
        height: "65vh"
    },
    div: {
        marginBottom: "10px"
    },
    logo: {
        width: "13em",
        display: 'flex',
        flexDirection: "row" as 'row',
        color: theme.palette.primary.main,
        justifyContent: "space-evenly" as 'space-evenly',
        marginTop: "1em"
    },
});

const buttons: SideBarButtonModel[] = [
    {
        key: 1,
        text: "Stellen",
        redirect: config.overviewRoute,
        active: true,
        visible: true,
        permission: "*"
    },
    {
        key: 2,
        text: "Dein Profil",
        redirect: config.profileRoute,
        active: false,
        visible: true,
        permission: "*"
    },
    {
        key: 3,
        text: "Deine Dokumente",
        redirect: config.documentsRoute,
        active: false,
        visible: true,
        permission: "documents.access"
    },
    {
        key: 4,
        text: "Neue Ausschreibung",
        redirect: config.createPostingRoute,
        active: false,
        visible: true,
        permission: "posting.create"
    },
    // {
    //     key: 5,
    //     text: "Reporting",
    //     redirect: config.reportingRoute,
    //     active: false,
    //     visible: true,
    //     permission: "statistics.see"
    // },
    {
        key: 6,
        text: "Verwaltung",
        redirect: config.clerkAdministration,
        active: false,
        visible: true,
        permission: "administration.access"
    },
    {
        key: 7,
        text: "Bewerberliste",
        redirect: config.applicantListRoute,
        active: false,
        visible: true,
        permission: "applicantList.access"
    }
]

class SideBar extends React.Component<ISideBarProps, ISideBarState> {
    constructor(props: ISideBarProps){
        super(props);

        this.state = {
            buttons: buttons,
            openJobs: false,
            openProfile: false,
            openDocuments: false,
            opencreatePosting: false,
            openReporting: false,
            openApplicantList: false,
            openAdministration: false,
            mobile: (window.innerWidth <= 910),
            openDrawer: false
        }
    }

    private switchActiveButtons(key: number) {
        let index = ArrayHelper.indexOf(this.state.buttons, (button) => key === button.key)

        let array = this.state.buttons;

        switch (key) {
            
            case 1:                
                array.forEach((element: SideBarButtonModel) => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: true,
                    openProfile: false,
                    openDocuments: false,
                    opencreatePosting: false,
                    openReporting: false,
                    openApplicantList: false,
                    openAdministration: false
                })
                break;

            case 2:
                array.forEach(element => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: false,
                    openProfile: true,
                    openDocuments: false,
                    opencreatePosting: false,
                    openReporting: false,
                    openApplicantList: false,
                    openAdministration: false
                })
                break;

            case 3:
                array.forEach(element => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: false,
                    openProfile: false,
                    openDocuments: true,
                    opencreatePosting: false,
                    openReporting: false,
                    openApplicantList: false,
                    openAdministration: false
                })
                break;

                case 4:
                array.forEach(element => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: false,
                    openProfile: false,
                    openDocuments: false,
                    opencreatePosting: true,
                    openReporting: false,
                    openApplicantList: false,
                    openAdministration: false
                })
                break;
        
                case 5:
                array.forEach(element => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: false,
                    openProfile: false,
                    openDocuments: false,
                    opencreatePosting: false,
                    openReporting: true,
                    openApplicantList: false,
                    openAdministration: false
                })
                break;

                case 6:
                array.forEach(element => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: false,
                    openProfile: false,
                    openDocuments: false,
                    opencreatePosting: false,
                    openReporting: false,
                    openApplicantList: false,
                    openAdministration: true
                })
                break;

                case 7:
                array.forEach(element => {
                    element.active = false;
                });
                array[index].active = true;

                NavService.get().setNav(array[index].redirect);

                this.setState({
                    buttons: array,
                    openJobs: false,
                    openProfile: false,
                    openDocuments: false,
                    opencreatePosting: false,
                    openReporting: false,
                    openApplicantList: true,
                    openAdministration: false
                })
                break;
            default:
                break;
        }
    }

    private isActiveButton(route: string): boolean {
        
        if(NavService.get().getNav() === route) {
            return true
        }
        return false;   
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        // this.setState({mobile: window.innerWidth <= 760});
        let currentHideNav = (window.innerWidth <= 910);
        if (currentHideNav !== this.state.mobile) {
            this.setState({mobile: currentHideNav});
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    openDrawer() {
        this.setState({openDrawer: !this.state.openDrawer})
    }

    render() {
        let {classes} = this.props;

        //console.log(AuthService.get().getToken())

        if(window.innerWidth > 768) {
            return(
                <div className={classes.buttonDiv}>
                    {
                        buttons.map((button) => (
                            ((PermissionService.checkPermission(button.permission) || button.permission === "*") &&
                                <div className={classes.div} 
                                    key={button.key}
                                    // onClick={() => {this.switchActiveButtons(button.key)}}
                                    >
                                    <SideBarButton
                                        text={button.text}
                                        redirect={button.redirect}
                                        active={this.isActiveButton(button.redirect)}
                                        visible={button.visible}
                                        click={() => {this.switchActiveButtons(button.key)}}
                                    />
                                </div>
                            )
                        ))
                    }
                </div>
            )
        } else {
            return(
                <div style={{position: "absolute", zIndex: 1, height: "100%", marginRight: "2em"}}>
                    <IconButton
                        onClick={() => this.openDrawer()}
                        style={{marginRight: "2em"}}
                    >
                        <MenuIcon/>
                    </IconButton>
                        <Drawer
                            open={this.state.openDrawer}
                            onClick={() => this.openDrawer()}
                        >
                            <div className={classes.logo}>
                                <SchoolIcon style={{fontSize: "4em"}} fontSize="inherit"/>
                            </div>
                            <div style={{marginTop: "3em"}}>
                                {buttons.map((button) => (
                                        ((PermissionService.checkPermission(button.permission) || button.permission === "*") &&
                                            <div className={classes.div} 
                                                key={button.key}
                                                // onClick={() => {this.switchActiveButtons(button.key)}}
                                                >
                                                <SideBarButton
                                                    text={button.text}
                                                    redirect={button.redirect}
                                                    active={this.isActiveButton(button.redirect)}
                                                    visible={button.visible}
                                                    click={() => {this.switchActiveButtons(button.key)}}
                                                />
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                        </Drawer>
                    {/* {this.state.openDrawer && */}
                        {/* <Collapse orientation="horizontal" in={this.state.openDrawer}>
                            <div className={classes.drawer}>
                                {
                                    buttons.map((button) => (
                                        ((PermissionService.checkPermission(button.permission) || button.permission === "*") &&
                                            <div className={classes.div} 
                                                key={button.key}
                                                // onClick={() => {this.switchActiveButtons(button.key)}}
                                                >
                                                <SideBarButton
                                                    text={button.text}
                                                    redirect={button.redirect}
                                                    active={this.isActiveButton(button.redirect)}
                                                    visible={button.visible}
                                                    click={() => {this.switchActiveButtons(button.key)}}
                                                />
                                            </div>
                                        )
                                    ))
                                }
                            </div>
                        </Collapse> */}
                    {/* } */}
                </div>
            )
        }
    }
}
export default withStyles(useStyles, {withTheme : true})(SideBar);