import React from "react";

import { styled, StylesProvider, withStyles} from '@mui/styles';
import ISideBarButtonProps from "../../../interfaces/components/Layout/Elements/ISideBarButtonProps";
import ISideBarButtonState from "../../../interfaces/components/Layout/Elements/ISideBarButtonState";
import { Button } from "@mui/material";

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ThemeService from "../../../services/ThemeService";
import { Link } from "react-router-dom";

const useStyles = (theme: any) => ({
    div: {
        display: "flex"
    },
    link: {
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
        color: theme.palette.secondary.light,
        textAlign: "left" as 'left',
        paddingLeft: "10px",  
        paddingRight: "30px",
        marginBottom: "10px",
    },
    activeLink: {
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
        color: theme.palette.primary.light,
        backgroundColor: ThemeService.getAlphaColor(theme.palette.primary.light, 0.2),
        textAlign: "left" as 'left',
        padding: "10px",
        marginBottom: "10px",
        paddingRight: "30px"
    }
});

const roundButton = {
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    textAlign: "left",
    padding: "10px",
    paddingRight: "30px"
}

class SideBarButton extends React.Component<ISideBarButtonProps, ISideBarButtonState> {
    constructor(props: ISideBarButtonProps) {
        super(props);

        this.state = {
            active: this.props.active
        }
    }

    routeChange() {
        console.log(this.props.text)
        // return(
        //     <Link to={"/" + this.props.redirect}/>
        // )
    }

    render() {

        let {classes} = this.props;

        if(this.props.visible) {
            if(this.props.active) {
                return(
                    <Link 
                        style={{textDecoration: "none"}} 
                        to={"/" + this.props.redirect}>
                            <Button
                                onClick={() => this.props.click()}
                                sx={{
                                    borderTopRightRadius: "50px",
                                    borderBottomRightRadius: "50px",
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    textAlign: "left" as 'left',
                                    padding: "10px",
                                    paddingRight: "30px",
                                    textTransform: "none",
                                    "&:hover": { 
                                        backgroundColor: "#e5342a"
                                    }
                                }}
                                startIcon={<FiberManualRecordIcon/>}
                            >
                                {this.props.text}
                            </Button>
                    </Link>
                )
            } else {
                return(
                    <Link 
                        style={{textDecoration: "none"}} 
                        to={"/" + this.props.redirect}>
                            <Button
                                onClick={() => this.props.click()}
                                sx={{
                                    borderTopRightRadius: "50px",
                                    borderBottomRightRadius: "50px",
                                    color: "#797b7d",
                                    textAlign: "left" as 'left',
                                    padding: "10px",  
                                    paddingRight: "30px",
                                    textTransform: "none",
                                }}
                                startIcon={<FiberManualRecordIcon/>}
                            >
                                {this.props.text}
                            </Button>
                    </Link>
                )
            }
        } else {
            return(
                <div></div>
            )
        }
    }
}
export default withStyles(useStyles, {withTheme : true})(SideBarButton);