import { config } from "../config";
import IParamFunction from "../Functions/IParamFunction";
import IPermission from "../models/Communication/IPermission";
import IRole from "../models/Communication/IRole";
import AxiosHelper from "../util/AxiosHelper";

export default class RoleService {

    public static getAll(success: IParamFunction<any>, error: IParamFunction<object>){
        AxiosHelper.get().get(config.api + config.restExtension + "/roles/")
        .then(response => {
            console.log(response)
            let data = response.data as Array<IRole>
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static get(success: IParamFunction<any>, error: IParamFunction<object>, id: number){
        AxiosHelper.get().getParam(config.api + config.restExtension + "/roles/", id)
        .then(response => {
            console.log(response)
            let data = response.data as IRole
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static getAllPermissions(success: IParamFunction<any>, error: IParamFunction<object>){
        AxiosHelper.get().get(config.api + config.restExtension + "/roles/permissions")
        .then(response => {
            console.log(response)
            let data = response.data as IPermission[]
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }
}