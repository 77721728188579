import * as React from 'react';
import { withRouter } from 'react-router-dom';
import IAuthContainerProps from "../../interfaces/components/Layout/IAuthContainerProps";
import IAuthContainerState from "../../interfaces/components/Layout/IAuthContainerState";
import { Routes } from '../Routes';
import ArrayHelper from '../../util/ArrayHelper';
import IRoute from '../../models/IRoute';
import AuthService from '../../services/AuthService';
import { Redirect } from 'react-router-dom';
import LoadingSkeleton from './LoadingSkeleton';
import {config} from "../../config"; 

class AuthContainer extends React.Component<IAuthContainerProps, IAuthContainerState>{
    constructor(props: IAuthContainerProps){
        super(props);

        this.state = {
            valid: false,
            contained: false,
            checked: false,
            prevLoc: ""
        }
    }

    componentDidMount(){
        this.props.history.listen((location: any) => {
            this.handleRouteChange(location);
        });

        let loc = window.location;

        this.handleRouteChange(loc);
    }

    private handleRouteChange(location: any){
        if(location.pathname !== this.state.prevLoc){
            if(ArrayHelper.contains(Routes, (route) => route.name === location.pathname)){
                let foundRoutes = ArrayHelper.where(Routes, (route) => route.name === location.pathname) as Array<IRoute>;
           
                let route = foundRoutes[0];
           
                if(route.contained){
                    this.onRouteChange();
                }else{
                  this.setState({contained: false});
                }
            }
        
         this.setState({prevLoc: location.pathname});
        }
    }

    private onRouteChange(){
        AuthService.get().isValid(this.handleValidate.bind(this));
        this.setState({prevLoc: window.location.href, checked: false, contained: true});
    }
    
    private handleValidate(valid: boolean){
        if(!valid){
            AuthService.get().refresh(this.handleRefresh.bind(this))
        }else if(this.state.contained){
            this.setState({valid: true, checked: true});
        }
    }
    
    private handleRefresh(refreshed: boolean){
        if(refreshed && this.state.contained){
            this.setState({valid: true, checked: true});
        }else if(!refreshed){
            this.setState({valid: false, checked: true})
        }
    }

    render(){
        let currentLoc = this.state.prevLoc;
        let index = ArrayHelper.indexOf(Routes, (route) => route.name === currentLoc);

        if(this.state.contained){
            if(!this.state.checked){
               
                    return (<LoadingSkeleton/>);
            }

            if(!this.state.valid){
                return (<Redirect to={"/" + config.logoutRoute}/>);
            }
        }

        return (<div>{this.props.children}</div>)
    }
}
export default withRouter(AuthContainer);