import React from "react";
import { withStyles} from '@mui/styles';

import { Button, Grid, IconButton, Paper, Skeleton, Typography } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StringDistributor from "../../util/StringDistributor";
import IBewerberlisteProps from "../../interfaces/components/Closed/IBewerberlisteProps";
import IBewerberlisteState from "../../interfaces/components/Closed/IBewerberlisteState";
import MainPage from "../Public/MainPage";
import IApplicant from "../../models/Communication/IApplicant";
import ApplicantCard from "../Layout/Elements/ApplicantCard";
import PageTitle from "../Layout/Elements/PageTitle";
import ApplicantDetail from "./ApplicantDetail";
import ApplicantFilter from "../Layout/Elements/ApplicantFilter";
import UserService from "../../services/UserService";
import IUser from "../../models/Communication/IUser";

const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        padding: "20px"
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "24px"
    }
}); 

class Bewerberliste extends MainPage<IBewerberlisteProps, IBewerberlisteState> {
    constructor(props: IBewerberlisteProps) {
        super(props);

        this.state = {
            loading: true,
            applicants: [],
            openDetail: false,
            openIndex: 0,
            backup: []
        }
    }

    componentDidMount() {
        UserService.getApplicants(this.getSuccess.bind(this), this.getError.bind(this));
    }

    private getSuccess(ans: IUser[]) {
        console.log(ans)
        this.setState({applicants: ans, loading: false, backup: ans})
    }

    private getError(err: any) {
        console.log(err)
    }

    private back() {
        let arr = this.state.backup
        this.setState({openDetail: false, applicants: arr})
    }

    private openDetail(index: number) {
        this.setState({openDetail: true, openIndex: index})
    }

    private getFiltered(arr: any) {
        this.setState({applicants: arr})
    }

    private markUser(id: number) {
        UserService.markUser(this.markSuccess.bind(this), this.markError.bind(this), id);
    }

    private markSuccess(ans: any) {
        console.log(ans)
    }

    private markError(err: any) {
        console.log(err)
    }

    getContent(){
        let {classes} = this.props;
        let strings = StringDistributor.get().applicantList
        if(this.state.openDetail) {
            return (
                <div className={classes.detailsDiv}>
                    <IconButton style={{marginBottom: "5px"}} aria-label="bearbeiten" 
                        onClick={() => {this.back()}}>
                        <ArrowBackIcon />
                    </IconButton>
                    <ApplicantDetail applicant={this.state.applicants[this.state.openIndex]}/>
                </div>
            )
        }
        if(this.state.loading) {
            return(
                <div style={{display: "flex", flexDirection: "column", width: "90%"}}>
                    <PageTitle title={strings.applicantList}/>
                
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                </div>
            )
        }

        return(
            <div>
                <PageTitle title={strings.applicantList}/>

                <ApplicantFilter getFiltered={this.getFiltered.bind(this)} applicants={this.state.applicants}/>

                <div style={{height: "70vh", overflow: "scroll"}}>
                    {
                        this.state.applicants.map((b, index: number) => {
                            return(<ApplicantCard 
                                click={() => {this.openDetail(index)}} 
                                mark={() => {this.markUser(b.id)}}
                                key={b.id} 
                                applicant={b}/>)
                        })
                    }
                </div>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(Bewerberliste);