import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material';
import { withStyles} from '@mui/styles';
import IMessageBoxProps from '../../../interfaces/components/Layout/Elements/IMessageBoxProps';

const useStyles = (theme: any) => ({

});

class MessageBox extends React.Component<IMessageBoxProps>{
    constructor(props: IMessageBoxProps){
        super(props);
    }

    private getMessage(){
        let lines = this.props.message.split('\r\n');

        let rows = [] as JSX.Element[];

        for(let i = 0; i < lines.length; i++){
            let line = lines[i];

            if(i !== 0)
                rows.push(<br/>);
            
            rows.push(<span>{line}</span>)
        }

        return rows;
    }

    render(){
        return (
            <Dialog open={this.props.open} onClose={this.props.onClose}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.getMessage()}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={this.props.onClose} color="secondary" sx={{backgroundColor: "#e5342a"}}>{this.props.buttonText}</Button>
                </DialogActions>
            </Dialog>
        );
    }

}
export default withStyles(useStyles, {withTheme: true})(MessageBox)