import cookie from 'react-cookies';
import IfHelper from "./IfHelper";
import ICookieInfo from '../models/ICookieInfo';
import { CookieType } from '../Enums/CookieType'; 
import moment from "moment";
import IStrings from '../localization/IStrings';

export default class CookieHelper{
    private static readonly ACCEPT_COOKIE = "LEHRERPORTAL_COOKIES";
    private static readonly ACCEPT_COOKIETYPE = "LEHRERPORTAL_COOKIES_TYPE";

    public static hasCookiesAccepted(){
        let accepted = cookie.load(CookieHelper.ACCEPT_COOKIE);

        return IfHelper.isNull(accepted);
    }

    public static hasAccepted(type: CookieType){
        let accepted = this.getAcceptedCookies();

        return accepted.get(type) as boolean;
    }

    public static saveCookies(cookies: Map<string, boolean>){
        let expires = moment().add(1, 'years').toDate();

        cookie.save(CookieHelper.ACCEPT_COOKIE, "true", {
            sameSite: true,
            expires: expires
        });

        let data = Array.from(cookies).reduce((o, [key, value]) => { 
            o[key] = value; 
        
            return o; 
          }, {} as any);

        cookie.save(CookieHelper.ACCEPT_COOKIETYPE, JSON.stringify(data), {
            sameSite: true,
            expires: expires
        })
    }

    public static getAcceptedCookies(){
        let type = cookie.load(CookieHelper.ACCEPT_COOKIETYPE);

        let values = new Map<CookieType, boolean>();

        if(IfHelper.isNull(type)){
            for(let item in CookieType){
                if(isNaN(Number(item))){
                    let val : CookieType = CookieType[item as keyof typeof CookieType];
                    values.set(val, val === CookieType.Essential);
                }
            }

            return values;
        }

        let parsedType = type as any;

        for(let key in parsedType){
            let typeVal = CookieType[key as keyof typeof CookieType];
            let val = parsedType[key];

            values.set(typeVal, Boolean(val));
        }

        return values;
    }

    public static getCookieInformation(strings: IStrings){
        let cookieInfos = [
            {
                provider: strings.cookies.defaultProvider,
                name: "LEHRERPORTAL_COOKIES",
                type: CookieType.Essential,
                purpose: strings.cookies.cookiePurpose.get("LEHRERPORTAL_COOKIES"),
                expiration: strings.cookies.cookieExpiry.get("LEHRERPORTAL_COOKIES"),
            },
            {
                provider: strings.cookies.defaultProvider,
                name: "LEHRERPORTAL_COOKIES_TYPE",
                type: CookieType.Essential,
                purpose: strings.cookies.cookiePurpose.get("LEHRERPORTAL_COOKIES_TYPE"),
                expiration: strings.cookies.cookieExpiry.get("LEHRERPORTAL_COOKIES_TYPE"),
            },
            {
                provider: strings.cookies.defaultProvider,
                name: "LEHRERPORTAL_NAV",
                type: CookieType.Essential,
                purpose: strings.cookies.cookiePurpose.get("LEHRERPORTAL_NAV"),
                expiration: strings.cookies.cookieExpiry.get("LEHRERPORTAL_NAV"),
            },
            {
                provider: strings.cookies.defaultProvider,
                name: "LEHRERPORTAL_AUTH_JWT",
                type: CookieType.Essential,
                purpose: strings.cookies.cookiePurpose.get("LEHRERPORTAL_AUTH_JWT"),
                expiration: strings.cookies.cookieExpiry.get("LEHRERPORTAL_AUTH_JWT"),
            }
        ] as ICookieInfo[]

        return cookieInfos;
    }
}