
import React from "react";
import { withStyles} from '@mui/styles';
import { Box, Button, Grid, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IAngebotNachfrageProps from "../../../interfaces/components/Closed/Reporting/IAngebotNachfrageProps";
import IAngebotNachfrageState from "../../../interfaces/components/Closed/Reporting/IAngebotNachfrageState";
import MainPage from "../../Public/MainPage";
import StringDistributor from "../../../util/StringDistributor";


const useStyles = (theme: any) => ({

}); 

class AngebotNachfrage extends React.Component<IAngebotNachfrageProps, IAngebotNachfrageState> {
    constructor(props: IAngebotNachfrageProps) {
        super(props);

        this.state = {

        }
    }
    render(){
        let {classes} = this.props;
        let strings = StringDistributor.get().reporting

        return(
            <div>
                AN
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(AngebotNachfrage);