import * as React from 'react';

import {  Dialog, DialogTitle, DialogActions, DialogContent, Button} from '@mui/material';
import { withStyles } from '@mui/styles';
import IConfirmDialogProps from '../../../interfaces/components/Layout/Elements/IConfirmDialogProps';

const useStyles = (theme: any) => ({

}); 

class ConfirmDialog extends React.Component<IConfirmDialogProps>{
    constructor(props: IConfirmDialogProps){
        super(props);
    }

    render(){
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    {this.props.message}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={this.props.accept} sx={{backgroundColor: "#e5342a"}}>{this.props.acceptButtonText}</Button>
                    <Button variant="text" color="secondary" onClick={this.props.decline} sx={{color: "#e5342a"}}>{this.props.declineButtonText}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(useStyles, {withTheme: true})(ConfirmDialog);