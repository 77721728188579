
import React from "react";
import { withStyles} from '@mui/styles';
import { Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import { Redirect } from "react-router-dom";
import IAppliantCardProps from "../../../interfaces/components/Layout/Elements/IAppliantCardProps";
import StringDistributor from "../../../util/StringDistributor";
import FieldValueGetter from "../../../util/FieldValueGetter";
import { PermissionService } from "../../../services/PermissionService";

const useStyles = (theme: any) => ({
    paper: {
        marginBottom: "1.25em",
        padding: "1em",
        width: "80%",
    },
    gridContainer: {
        display: "flex",
        flexDirection: "row" as 'row'
    },
    gridItem: {
        display: "flex",
        justifyContent: "space-evenly" as 'space-evenly',
        padding: "auto"
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "600",
        marginRight: "1em"
    },

}); 

class AppliantCard extends React.Component<IAppliantCardProps, any> {
    constructor(props: IAppliantCardProps) {
        super(props);

        this.state = {
        }
    }

    render(){
        let {classes} = this.props;

        let strings = StringDistributor.get().profile;

        return(
            <Paper className={classes.paper} sx={{':hover': {boxShadow: 4, cursor: "pointer"},}} >
                <Grid container direction={'row'} spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}  onClick={() => this.props.click()}>
                        <Typography 
                            style={{fontWeight: 600}}
                            className={classes.title}
                        >
                            {/* Test Test */}
                            {FieldValueGetter.getValue("Vorname", this.props.applicant.fieldGroups) 
                                    + " " + 
                                    FieldValueGetter.getValue("Nachname", this.props.applicant.fieldGroups)}
                        </Typography>
                    </Grid>

                    <Grid className={classes.gridItem} item xs={0} md={0} sm={1} lg={1} onClick={() => this.props.click()}>
                        <Typography noWrap className={classes.title}>{strings.subject + " 1:"}</Typography>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={2} onClick={() => this.props.click()}>
                        <Typography>
                            {FieldValueGetter.getValue("Fach 1", this.props.applicant.fieldGroups)}
                        </Typography>

                    </Grid>

                    <Grid className={classes.gridItem} item xs={0} md={0} sm={1} lg={1} onClick={() => this.props.click()}>
                        <Typography noWrap className={classes.title}>{strings.subject + " 2:"}</Typography>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={2} onClick={() => this.props.click()}>
                        <Typography>
                            {FieldValueGetter.getValue("Fach 2", this.props.applicant.fieldGroups)}
                        </Typography>
                    </Grid> 

                    <Grid className={classes.gridItem} item xs={0} md={0} sm={1} lg={1} onClick={() => this.props.click()}>
                        <Typography noWrap className={classes.title}>{strings.email + ":"}</Typography>
                    </Grid>
                    <Grid className={classes.gridItem} item xs={2} onClick={() => this.props.click()}>
                        <Typography>{this.props.applicant.email}</Typography>
                    </Grid> 
                    <Grid className={classes.gridItem} item xs={1}>
                        {PermissionService.checkPermission("user.mark") && 
                            <FormControlLabel control={<Checkbox
                                    onClick={() => {this.props.mark()}}
                                    style={{
                                        color: "#e5342a"
                                    }}
                                />}
                                    label={"Markieren"}
                            />
                        }
                    </Grid> 
                </Grid>
            </Paper>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(AppliantCard);