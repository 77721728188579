import React from "react";
import { styled, withStyles} from '@mui/styles';
import MainPage from "../Public/MainPage";
import IProfileProps from "../../interfaces/components/Closed/IProfileProps";
import IProfileState from "../../interfaces/components/Closed/IProfileState";
import {Skeleton, Stack, Accordion, AccordionDetails, AccordionSummary, Divider, Grid, IconButton, Menu, MenuItem, MenuProps, Paper, Switch, Typography, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import IProfile from "../../models/Communication/IProfile";
import StringDistributor from "../../util/StringDistributor";
import PageTitle from "../Layout/Elements/PageTitle";
import { config } from "../../config";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import IUser from "../../models/Communication/IUser";
import { groupEnd } from "console";
import IFieldGroup from "../../models/Communication/Field/IFieldGroup";
import ArrayHelper from "../../util/ArrayHelper";
import PasswordChange from "./PasswordChange";
import INewValueField from "../../models/Communication/Field/INewValueField";
import IFieldValue from "../../models/Communication/Field/IFieldValue";
import Editblock from "../Layout/Elements/Editblock";
import FieldValueGetter from "../../util/FieldValueGetter";

const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        padding: "1.25em",
        marginTop: "10px",
        height: "65vh",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "600",
        marginRight: "1em"
    },
    mainTitle: {
        color: theme.palette.primary.main,
    },
    listItem: {
        display: "flex",
        flexDirection: "row" as 'row',
        width: "100%"
    }
}); 

class SachbearbeiterProfile extends MainPage<IProfileProps, IProfileState> {
    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            loading: true,
            edit: false,
            openSettingsMenu: false,
            changePw: false,
            menuAnchor: undefined,
            user: this.props.user,
            editedFields: [],
            editFieldsProgress: false,
            onlineProgress: false,
            switch: false,
            newFields: [],
            fieldGroups: this.props.user?.fieldGroups,
            editProgress: false,
        }
    }

    private openSettingsMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({
                openSettingsMenu: true,
                menuAnchor: event.currentTarget
        });
    }

    private closeSettingsMenu() {
        this.setState({openSettingsMenu: false})
    }

    private changePassword() {
        this.setState({changePw: !this.state.changePw, openSettingsMenu: false})
    }

    private editField(newValue: INewValueField) {
        let fields = this.state.editedFields;
        
        if(ArrayHelper.contains(fields, (field) => field.id === newValue.field?.id)) {
            let valueO: IFieldValue = {
                value: newValue.newValue
            }
            fields[ArrayHelper.indexOf(fields, (field) => field.id === newValue.field?.id)].value = valueO;
        } else {
            if(newValue.field !== undefined) 
                fields.push(newValue.field);
        }
        this.setState({editedFields: fields})
    }

    private saveEdit() {
        UserService.editFields(this.editFieldSuccess.bind(this), this.editFieldError.bind(this), this.state.editedFields);
        this.setState({openSettingsMenu: false, editProgress: true})
    }

    private enableEdit() {
        this.setState({edit: true})
    }

    private editFieldSuccess(ans: IUser) {
        this.setState({edit: false, user: ans, fieldGroups: ans.fieldGroups, editProgress: false})
    }

    private editFieldError(err: any) {
        this.setState({edit: false, editProgress: false})
    }

    private abortEdit() {
        this.setState({edit: false, openSettingsMenu: false, editedFields: []})
    }

    render(){
        let {classes} = this.props;
        let strings= StringDistributor.get().profile

        if(this.state.changePw) {
            return(
                <div>
                     <IconButton style={{marginBottom: "5px"}} aria-label="bearbeiten" 
                        onClick={() => {this.changePassword()}}>
                        <ArrowBackIcon />
                    </IconButton>
                    <PasswordChange/>
                </div>
            )
        }

        return(
            <div>
                <PageTitle title={strings.profile}/>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={11} style={{display: "flex", flexDirection: "row"}}>
                            <Editblock 
                                name={"Vorname"} 
                                edit={this.state.edit} 
                                title
                                editFunction={this.editField.bind(this)}
                                field={FieldValueGetter.getField("Vorname", this.state.user?.fieldGroups)}
                                value={FieldValueGetter.getValue("Vorname", this.state.user?.fieldGroups)}
                            />
                            <Editblock 
                                style={{marginLeft: "10px"}}
                                name={"Nachname"} 
                                edit={this.state.edit} 
                                title
                                editFunction={this.editField.bind(this)}
                                field={FieldValueGetter.getField("Nachname", this.state.user?.fieldGroups)}
                                value={FieldValueGetter.getValue("Nachname", this.state.user?.fieldGroups)}/>
                        </Grid>
                        <Grid item xs={1}>
                        {!this.state.edit && !this.state.editProgress &&
                                    <div>
                                        <IconButton aria-label="einstellungen" aria-haspopup="true" aria-expanded={this.state.openSettingsMenu ? 'true' : undefined}
                                            onClick={event => this.openSettingsMenu(event)}>
                                            <EditIcon/>
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={this.state.menuAnchor}
                                            open={this.state.openSettingsMenu}
                                            onClose={() => this.closeSettingsMenu()}
                                            MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => this.enableEdit()}>{strings.editProfile}</MenuItem>
                                            <MenuItem onClick={() => this.changePassword()}>{strings.changePassword}</MenuItem>
                                        </Menu>
                                    </div>
                                }
                                {this.state.edit && !this.state.editProgress &&
                                    <div>
                                        <IconButton aria-label="einstellungen" aria-haspopup="true" aria-expanded={this.state.openSettingsMenu ? 'true' : undefined}
                                            onClick={() => this.saveEdit()}>
                                            <CheckIcon/>
                                        </IconButton>
                                        <IconButton aria-label="einstellungen" aria-haspopup="true" aria-expanded={this.state.openSettingsMenu ? 'true' : undefined}
                                            onClick={() => this.abortEdit()}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                }
                                {this.state.editProgress && 
                                    <CircularProgress 
                                        sx={{
                                            marginTop: "1em",
                                            float: "right",
                                            color: "#e5342a"
                                        }}
                                    />
                                }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{this.props.user?.email}</Typography>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={2}>
                            <Typography 
                                style={{fontWeight: 600}}
                                className={classes.title}
                            >
                                {"Telefon-Nr.: "}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Editblock 
                                style={{marginRight: "0.3em"}}
                                name={"Telefon-Nr."} 
                                edit={this.state.edit} 
                                editFunction={this.editField.bind(this)}
                                field={FieldValueGetter.getField("Telefon-Nr.", this.state.user?.fieldGroups)}
                                value={FieldValueGetter.getValue("Telefon-Nr.", this.state.user?.fieldGroups)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography 
                                style={{fontWeight: 600}}
                                className={classes.title}
                            >
                                {"Standort:"}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Editblock 
                                name={"Standort"} 
                                edit={this.state.edit} 
                                editFunction={this.editField.bind(this)}
                                field={FieldValueGetter.getField("Standort", this.state.user?.fieldGroups)}
                                value={FieldValueGetter.getValue("Standort", this.state.user?.fieldGroups)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography 
                                style={{fontWeight: 600}}
                                className={classes.title}
                            >
                                {"Raum-Nr.:"}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Editblock 
                                style={{marginRight: ".3em"}}
                                name={"Raum-Nr."} 
                                edit={this.state.edit} 
                                editFunction={this.editField.bind(this)}
                                field={FieldValueGetter.getField("Raum-Nr.", this.state.user?.fieldGroups)}
                                value={FieldValueGetter.getValue("Raum-Nr.", this.state.user?.fieldGroups)}/>
                        </Grid>
                    </Grid>
                </Paper>
                {this.state.editFieldsProgress &&
                    <CircularProgress 
                        sx={{
                            position: "absolute",
                            marginTop: "1em",
                            marginRight: "14.5em",
                            color: "#e5342a",
                            fontSize: "5em"
                        }}
                    />
                }
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(SachbearbeiterProfile);