import * as React from 'react';

import {  Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl,FormHelperText, Select, MenuItem, SelectChangeEvent, TextField} from '@mui/material';
import { withStyles } from '@mui/styles';
import IConfirmDialogProps from '../../../interfaces/components/Layout/Elements/IConfirmDialogProps';
import ITemplateDialogProps from '../../../interfaces/components/Layout/Elements/ITemplateDialogProps';
import PostingService from '../../../services/PostingService';
import ITemplateDialogState from '../../../interfaces/components/Layout/Elements/ITemplateDialogState';
import IPosting from '../../../models/Communication/IPosting';
import LoadingScreen from './LoadingScreen';
import ArrayHelper from '../../../util/ArrayHelper';

const useStyles = (theme: any) => ({

}); 

class TemplateDialog extends React.Component<ITemplateDialogProps, ITemplateDialogState>{
    constructor(props: ITemplateDialogProps){
        super(props);

        this.state= {
            postings: [],
            loading: true,
            selectValue: ""
        }
    }

    componentDidMount() {
        PostingService.get(this.postingSuccess.bind(this), this.postingError.bind(this))
    }

    postingSuccess(posts: IPosting[]) {
        this.setState({postings: posts, loading: false})
    }

    postingError(err: any) {
        console.log(err)
    }

    private changeSelected = (e: any) => {
        let index = ArrayHelper.indexOf(this.state.postings, (p) => p.identificationNumber === e)

        this.setState({selectValue: this.state.postings[index]});
    };

    render(){

        if(this.state.loading) {
            return(<LoadingScreen open={this.state.loading} className="loadingScreen"/>)
        }

        return (
            <Dialog 
                open={this.props.open}
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle>{this.props.title}</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            sx={{
                                marginTop: "10px",
                                color: "black",
                                width: "113%"
                            }}
                            label={"Kennnummer"}
                            value={this.state.selectValue.identificationNumber}
                            size="small"
                            select
                            onChange={(e) => {this.changeSelected(e.target.value)}}
                        >
                            {
                                this.state.postings.map((posting) => {
                                    return(
                                        <MenuItem 
                                            key={posting.identificationNumber} 
                                            value={posting.identificationNumber}
                                        >
                                            {posting.identificationNumber}
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>
                        <FormHelperText id="identificationNumber">
                            {"Kennummer der Vorlage wählen"}
                        </FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        sx={{backgroundColor: "#e5342a"}}
                        onClick={() => this.props.accept(this.state.selectValue)}
                    >
                        {this.props.acceptButtonText}
                    </Button>
                    <Button 
                        variant="text" 
                        sx={{color: "#e5342a"}}
                        onClick={this.props.decline}
                    >
                        {this.props.declineButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default withStyles(useStyles, {withTheme: true})(TemplateDialog);