import { config } from "../config";
import IParamFunction from "../Functions/IParamFunction";
import ISubject from "../models/Communication/ISubject";
import AxiosHelper from "../util/AxiosHelper";

export default class SubjectService {
    
    public static create(success: IParamFunction<any>, error: IParamFunction<object>, subject: string){
        AxiosHelper.get().put(config.api + config.restExtension + "/subject/", subject, "application/json")
        .then(response => {
            console.log(response)
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }
    
    public static get(success: IParamFunction<any>, error: IParamFunction<object>){
        AxiosHelper.get().get(config.api + config.restExtension + "/subject/")
        .then(response => {
            console.log(response)
            let data = response.data as ISubject[]
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    
    public static delete(success: IParamFunction<any>, error: IParamFunction<object>, id: number){
        AxiosHelper.get().delete(config.api + config.restExtension + "/subject/", id, "application/json;charset=UTF-8")
        .then(response => {
            console.log(response)
            let data = response.data
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }
}