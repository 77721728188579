import React, { Component } from 'react';
import IRegisterProps from '../../../interfaces/components/Public/Auth/IRegisterProps';
import IRegisterState from '../../../interfaces/components/Public/Auth/IRegisterState';


import { withStyles } from '@mui/styles';

import { Button, Box, Checkbox, FormControlLabel, Typography, InputBase, Tab, CircularProgress, TextField, Grid, Paper } from '@mui/material';
import StringDistributor from '../../../util/StringDistributor';
import AuthService from '../../../services/AuthService';
import IRegisterForm from '../../../models/Communication/IRegisterForm';
import { Redirect } from 'react-router-dom';
import { config } from '../../../config';
import LButton from '../../Layout/Elements/Pieces/LButton';

const useStyles = (theme: any) => ({
    body: {
        width: "80%"
    },
    typo: {
        color: "#f5655d",
        fontSize: "14px",
        borderLeft: "5px solid #e5342a"

    }
}); 

class Register extends React.Component<IRegisterProps, IRegisterState> {
    
    constructor(props: IRegisterProps) {
        super(props);

        this.state = {
            progress: false,
            finish: false,
            email: '',
            password1: '',
            password2: '',
            isRegisterError: false,
            errorMessage: ''
        }
    }
    
    private onMailChange(e: any) {
        this.setState({ email: e});
    }

    private onPasswordChange(e: any) {
        this.setState({ password1: e });
    }

    private onPassword2Change(e: any) {
        this.setState({ password2: e });
    }

    private register() {
        let auth = StringDistributor.get().auth
        let login = StringDistributor.get().login
        let form: IRegisterForm = {
            email: this.state.email,
            password1: this.state.password1,
            password2: this.state.password2
        }

        if(this.state.email === "" || this.state.email === null) {
            this.registerError(auth.missingMail)
        } else if (this.state.password1 === "" || this.state.password1 === null) {
            this.registerError(auth.missingPassword)
        } else if (this.state.password2 === "" || this.state.password2 === null) {
            this.registerError(auth.missingSecondPassword)
        } else if(this.state.password1 !== this.state.password2) {
            this.registerError(login.notIdentical)
        } else {
            AuthService.get().register(form, this.registerSuccess.bind(this), this.registerError.bind(this));
            this.setState({progress: true})
        }
    }

    private registerSuccess() {
        this.setState({finish: true})
    }

    private registerError(err: any) {
        console.log(err)
        this.setState({progress: false, isRegisterError: true, errorMessage: err})
    }
    
    render() {

        let strings = StringDistributor.get().login;

        let {classes} = this.props;

        if(this.state.finish) {
            return (<Redirect to={"/" + config.overviewRoute}/>);
        }
        
        return (
                <div className={classes.body}>
                    <Grid container spacing={1}>
                        <Grid item xs={10} md={10} sm={10}>
                            {this.state.isRegisterError &&
                                <Paper classes={classes.errorPaper}>
                                    <div className={classes.typo} >
                                        {this.state.errorMessage}
                                    </div>
                                </Paper>
                            }
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                placeholder={strings.email}
                                required
                                fullWidth
                                name="email"
                                autoComplete="off"
                                onChange={(e) => {this.onMailChange(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                placeholder={strings.password}
                                required
                                fullWidth
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => {this.onPasswordChange(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                placeholder={strings.secondPassword}
                                required
                                fullWidth
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => {this.onPassword2Change(e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12}
                            style={{display: "flex", justifyContent: "center"}}
                        >
                            {!this.state.progress && 
                                // <Button variant="contained"
                                //     sx={{
                                //         marginTop: "1em",
                                //         marginRight: "14.5em",
                                //         float: "right",
                                //         color: "#ffffff",
                                //         backgroundColor: "#e5342a",
                                //         "&:hover": {
                                //             backgroundColor: "#eb7771"
                                //         }
                                //     }}
                                //     onClick={() => this.register()}>
                                //     {strings.register}
                                // </Button>
                                <LButton label={strings.register} onClick={() => {this.register()}}/>
                            }
                            {this.state.progress &&
                                <CircularProgress 
                                    sx={{
                                        marginTop: "1em",
                                        marginRight: "14.5em",
                                        float: "right",
                                        color: "#e5342a"
                                    }}
                                />
                            }
                        </Grid>
                    </Grid>
                </div>
                // <div style={{width: "80%"}}>
                //     <TextField
                //         placeholder={strings.email}
                //         required
                //         fullWidth
                //         name="email"
                //         autoComplete="off"
                //         onChange={(e) => {this.onMailChange(e.target.value)}}
                //     />

                //     <div style={{marginTop: "20px"}}>
                //         <TextField
                //             placeholder={strings.password}
                //             required
                //             fullWidth
                //             name="password"
                //             type="password"
                //             autoComplete="new-password"
                //             onChange={(e) => {this.onPasswordChange(e.target.value)}}
                //         />
                //         <TextField
                //             placeholder={strings.password}
                //             required
                //             fullWidth
                //             name="password"
                //             type="password"
                //             autoComplete="new-password"
                //             onChange={(e) => {this.onPassword2Change(e.target.value)}}
                //         />
                //     </div>
                //     {!this.state.progress && 
                //         <Button variant="contained"
                //             sx={{
                //                 marginTop: "1em",
                //                 marginRight: "14.5em",
                //                 float: "right",
                //                 color: "#ffffff",
                //                 backgroundColor: "#e5342a",
                //                 "&:hover": {
                //                     backgroundColor: "#eb7771"
                //                 }
                //             }}
                //             onClick={() => this.register()}>
                //             {strings.register}
                //         </Button>
                //     }
                //     {this.state.progress &&
                //         <CircularProgress 
                //             sx={{
                //                 marginTop: "1em",
                //                 marginRight: "14.5em",
                //                 float: "right",
                //                 color: "#e5342a"
                //             }}
                //         />
                //     }
                // </div >
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(Register);