
import React from "react";
import { withStyles} from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Paper, Skeleton, Typography } from "@mui/material";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IApplicantDetailProps from "../../interfaces/components/Closed/IApplicantDetailProps";
import IApplicantDetailState from "../../interfaces/components/Closed/IApplicantDetailState";
import StringDistributor from "../../util/StringDistributor";
import Bewerberliste from "./Bewerberliste";
import FieldValueGetter from "../../util/FieldValueGetter";
import ApplicantFilter from "../Layout/Elements/ApplicantFilter";
import UserService from "../../services/UserService";
import IUser from "../../models/Communication/IUser";

const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        padding: "20px",
        height: "70vh",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "24px"
    },
    listItem: {
        width: "90%",
        display: "flex" as 'flex',
        flexDirection: "row" as 'row',
        justifyContent: "space-between" as 'space-between'
    }
}); 


class ApplicantDetail extends React.Component<IApplicantDetailProps, IApplicantDetailState> {
    constructor(props: IApplicantDetailProps) {
        super(props);

        this.state = {
            goBack: false,
            loading: true,
            error: false,
            user: undefined
        }
    }

    componentDidMount(): void {
        UserService.getOther(this.userSuccess.bind(this), this.userError.bind(this), this.props.applicant.id);
    }

    private userSuccess(ans: IUser) {
        this.setState({user: ans, loading: false})
    }

    private userError(err: any) {
        this.setState({error: true})
    }

    render(){
        let {classes} = this.props;
        let strings = StringDistributor.get().profile

        if(this.state.goBack) {
            return(
                <Bewerberliste/>
            )
        }

        if(this.state.loading) {
            return(
                <div style={{display: "flex", flexDirection: "column", width: "90%"}}>
                    <Skeleton animation="wave" style={{height: "40px"}}/>
                    <Skeleton 
                        variant="rectangular" 
                        style={{height: "90px"}}
                        height="24em"  
                        animation="wave"  
                    />
                </div>
            )
        }

        if(this.state.user !== undefined) {
            return(
                <div>
                    <Paper className={classes.paper}>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Typography variant="h5" style={{fontWeight: 600}}>
                                    {FieldValueGetter.getValue("Vorname", this.state.user.fieldGroups) 
                                            + " " + 
                                            FieldValueGetter.getValue("Nachname", this.state.user.fieldGroups)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {this.state.user.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.birthDate + ": "}
                                </Typography>
                                <Typography> {FieldValueGetter.getValue("Geburtsdatum", this.state.user.fieldGroups)}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.adress+ ": "}
                                </Typography>
                                <Typography>
                                    {
                                        FieldValueGetter.getValue("Straße", this.state.user.fieldGroups)
                                        + " " + FieldValueGetter.getValue("Hausnummer", this.state.user.fieldGroups)
                                        + ", " + FieldValueGetter.getValue("plz", this.state.user.fieldGroups)
                                        + " " + FieldValueGetter.getValueDescription("Ort", "Adresse", this.state.user.fieldGroups)
                                    }
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.nationality+ ": "}
                                </Typography>
                                <Typography>{FieldValueGetter.getValue("Nationalität", this.props.applicant.fieldGroups)}</Typography>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Accordion 
                                    defaultExpanded={true}
                                >
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="staatsexam eins">
                                    <Typography 
                                        style={{fontWeight: 600}}
                                        className={classes.title}
                                    >
                                        {strings.exam}
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            <Grid item xs={6} md={6} sm={6}>
                                                <Typography 
                                                    style={{fontWeight: 500}}
                                                    className={classes.title}
                                                >
                                                    {strings.exam + " 1"}
                                                </Typography>
                                                <ul>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.lehramt + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Lehramt", "Exam1", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.grade + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Note", "Exam1", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.location + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Ort", "Exam1", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.year + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Jahr", "Exam1", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.subject + " 1: "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Fach 1", "Exam1", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.subject + " 2: "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Fach 2", "Exam1",this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.subject + " 3: "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Fach 3", "Exam1", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                </ul>
                                            </Grid>
                                            <Grid item xs={6} md={6} sm={6}>
                                                <Typography 
                                                    style={{fontWeight: 500}}
                                                    className={classes.title}
                                                >
                                                    {strings.exam + " 2"}
                                                </Typography>
                                                <ul>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.lehramt + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Lehramt", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.grade + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Note", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.location + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Ort", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.year + ": "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Jahr", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.subject + " 1: "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Fach 1", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.subject + " 2: "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Fach 2", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                    <li className={classes.listItem}>
                                                        <Typography className={classes.title}>{strings.subject + " 3: "}</Typography>
                                                        <Typography>{FieldValueGetter.getValueDescription("Fach 3", "Exam2", this.state.user.fieldGroups)}</Typography>
                                                    </li>
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.apprenticeship + ": "}
                                </Typography>
                                <ul>
                                    <li><Typography>{FieldValueGetter.getValue("Ausbildung", this.state.user.fieldGroups)}</Typography></li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.internship + ": "}
                                </Typography>
                                <ul>
                                    <li><Typography>{FieldValueGetter.getValue("Praktika", this.state.user.fieldGroups)}</Typography></li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.foreignCountries + ": "}
                                </Typography>
                                <ul>
                                    <li><Typography>{FieldValueGetter.getValue("Ausland", this.state.user.fieldGroups)}</Typography></li>
                                </ul>    
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            )
        }
    }
}
export default withStyles(useStyles, {withTheme : true})(ApplicantDetail);