export default class IfHelper{
    public static isNull(obj : any) : boolean{
        return obj === null || typeof obj === "undefined";
    }

    public static IsNullOrWhitespace(obj : string | undefined | null) : boolean{
        if(this.isNull(obj)){
            return true;
        }
        let trimmed = (obj as string).trim();

        return trimmed === "";
    }
}