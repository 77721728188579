import moment from 'moment';
import cookie from 'react-cookies';
import axios from 'axios';
import { ApiPath } from '../Enums/ApiPath';
import { LoginErrorType } from '../Enums/LoginErrorType';

import ILoginErrorFunction from "../Functions/ILoginErrorFunction";
import IToken from '../models/JWT/IToken';
import ITokenHeader from '../models/JWT/ITokenHeader';
import ITokenPayload from '../models/JWT/ITokenPayload';
import ITokenResult from '../models/JWT/ITokenResult';
import ArrayHelper from '../util/ArrayHelper';
import IfHelper from '../util/IfHelper';
import ConfigService from './ConfigService';
import IBoolFunction from '../Functions/IBoolFunction';
import ILoginForm from '../models/ILoginForm';
import StringDistributor from '../util/StringDistributor';
import { config } from '../config';
import IRegisterForm from '../models/Communication/IRegisterForm';
import NavService from './NavService';

export default class AuthService {
    
    private jwtToken : IToken | null = null;
    private refreshToken : IToken | null = null;
    private static readonly COOKIE_NAME = "LEHRERPORTAL_AUTH_JWT";
    private static instance : AuthService | undefined;

    public static get(){
        if(typeof this.instance === "undefined")
            this.instance = new AuthService();

        return this.instance;
    }

    private constructor(){
        this.init();
    }

    private init(){
        let obj = cookie.load(AuthService.COOKIE_NAME);

        if(IfHelper.isNull(obj))
            return;

        let result = obj as ITokenResult;

        if(IfHelper.IsNullOrWhitespace(result.accessToken) || IfHelper.IsNullOrWhitespace(result.refreshToken))
            return;

        this.jwtToken = this.parseToken(result.accessToken);
        this.refreshToken = this.parseToken(result.refreshToken as string);
    }

    private parseToken(token: string) : IToken | null{
        let parts = token.split(".");

        if(parts.length !== 3)
            return null;
        
        let header = this.parseTokenPart<ITokenHeader>(parts[0]);
        let payload = this.parseTokenPart<ITokenPayload>(parts[1]);

        if(header === null || payload === null)
            return null;

        return {
            header: header,
            payload: payload,
            raw: token
        };
    }

    private parseTokenPart<T>(part : string) : T | null{
        try{
            let jsonString = atob(part);

            let partObj = JSON.parse(jsonString) as T;

            return partObj;
        }catch{
            return null;
        }
    }

    public getToken() : string {
        if(this.jwtToken === null) {
            return "";
        }
        return this.jwtToken.raw;
    }

    public getUserEmail(){
        return this.jwtToken?.payload.firstname;
    }

    public getRole(){
        if(this.jwtToken?.payload.lastname !== undefined)
            return parseInt(this.jwtToken?.payload.lastname)
    }

    public getGroup() : string | undefined{
        return this.jwtToken?.payload.role;
    }

    public getId() : number | undefined{
        return this.jwtToken?.payload.id;
    }

    public getPermissions() {
        return this.jwtToken?.payload.role;
    }

    // public getTimezone() : string | undefined{
    //     return this.jwtToken?.payload.timezone;
    // }

    public hasGroup(group: Array<string>){
        return ArrayHelper.contains(group, (gr) => this.getGroup() === gr);
    }

    public isAuthenticated() : boolean{
        if(IfHelper.isNull(this.jwtToken) || IfHelper.isNull(this.refreshToken))
            return false;

        return true;
    }

    public register(data: IRegisterForm, success: Function, error: ILoginErrorFunction) {
        let locale = StringDistributor.get().auth;
        
        if(IfHelper.isNull(data)){
            error(locale.noData, LoginErrorType.General)
            return;
        }

        if(data.password1 !== data.password2) {
            error("Die eingegebenen asswörter müssen identisch sein!", LoginErrorType.General)
            return;
        }
        
        if(IfHelper.IsNullOrWhitespace(data.email as string)){
            error(locale.missingMail, LoginErrorType.Mail)
            return;
        }

        if(IfHelper.IsNullOrWhitespace(data.password1 as string)){
            error(locale.missingPassword, LoginErrorType.Password)
            return;
        }

        axios.post(config.api + config.restExtension + '/token/register', data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async response =>{
            let result = response.data as ITokenResult;

            if(IfHelper.IsNullOrWhitespace(result.accessToken) || IfHelper.IsNullOrWhitespace(result.refreshToken)){
                error(locale.technicalProblems, LoginErrorType.General)
                return;
            }
            this.jwtToken = this.parseToken(result.accessToken);
            this.refreshToken = this.parseToken(result.refreshToken as string);

            if(IfHelper.isNull(this.jwtToken) || IfHelper.isNull(this.refreshToken)){
                error(locale.technicalProblems, LoginErrorType.General)
                return;
            }

            this.setStorage(result);
            success();
        }).catch(async reason => {
            if(reason.response.status === 401){
                error(locale.invalidLogin, LoginErrorType.General)
                return;
            }

            error(reason.message, LoginErrorType.General);
        })
    }

    public login(data: ILoginForm, success: Function, error: ILoginErrorFunction) {
        let locale = StringDistributor.get().auth;

        if(IfHelper.isNull(data)){
            error(locale.noData, LoginErrorType.General)
            return;
        }
        
        if(IfHelper.IsNullOrWhitespace(data.email as string)){
            error(locale.missingMail, LoginErrorType.Mail)
            return;
        }

        if(IfHelper.IsNullOrWhitespace(data.password as string)){
            error(locale.missingPassword, LoginErrorType.Password)
            return;
        }

        axios.post(config.api + config.restExtension + '/token/', data, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async response =>{
            console.log(response)
            let result = response.data as ITokenResult;

            if(IfHelper.IsNullOrWhitespace(result.accessToken) || IfHelper.IsNullOrWhitespace(result.refreshToken)){
                error(locale.technicalProblems, LoginErrorType.General)
                return;
            }

            this.jwtToken = this.parseToken(result.accessToken);
            this.refreshToken = this.parseToken(result.refreshToken as string);

            if(IfHelper.isNull(this.jwtToken) || IfHelper.isNull(this.refreshToken)){
                error(locale.technicalProblems, LoginErrorType.General)
                return;
            }

            this.setStorage(result);
            success();
        }).catch(async reason => {
            if(reason.response.status === 401){
                error(locale.invalidLogin, LoginErrorType.General)
                return;
            }

            error(reason.message, LoginErrorType.General);
        })
    }

    private setStorage(tokens: ITokenResult){
        cookie.save(AuthService.COOKIE_NAME, tokens, {
            sameSite: true,
            expires: moment().add(1, 'days').toDate()
        });
        NavService.get().setNav(config.overviewRoute);
    }

    public logout() {
        cookie.remove(AuthService.COOKIE_NAME);
        cookie.remove(NavService.COOKIE_NAME);
        this.jwtToken = null;
        this.refreshToken = null;
    }

    public refresh(refreshAction: IBoolFunction){
        axios.patch(config.api + config.restExtension + '/token/', {refreshToken : this.refreshToken?.raw}, {
            headers: {
                
            }
        })
        .then((response) => {
            if(!IfHelper.isNull(response.data)){

                let result = response.data as ITokenResult;
                result.refreshToken = this.refreshToken?.raw as string;

                this.jwtToken = this.parseToken(result.accessToken);

                this.setStorage(result);
                refreshAction(true);
                return;
            }
            refreshAction(false);
        }).catch(() => {
            refreshAction(false);
        }
        )
    }

    public promiseRefresh(){
        return axios.patch(config.api + config.restExtension + '/token/', {refreshToken : this.refreshToken?.raw}, {
            headers: {
                
            }
        })
        .then((response) => {
            if(response.status === 200){
                if(!IfHelper.isNull(response.data)){

                    let result = {
                        accessToken: response.data,
                        refreshToken: this.refreshToken?.raw
                    } as ITokenResult;

                    this.jwtToken = this.parseToken(result.accessToken);

                    this.setStorage(result);
                    return Promise.resolve();
                }
            }

            this.logout();
            return Promise.reject();
        })
    }

    public isValid(resultFunc : IBoolFunction){
        axios.post(ConfigService.getApiUrl(ApiPath.Public) + "/validate", {token : this.jwtToken?.raw})
        .then(response => {
            if(response.status === 200){
                resultFunc(true);
                return;
            }

            resultFunc(false);
        })
        .catch(error => {
            resultFunc(false);
        })
    }
}