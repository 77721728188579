import React from "react";
import { styled, withStyles} from '@mui/styles';
import MainPage from "../Public/MainPage";
import IProfileProps from "../../interfaces/components/Closed/IProfileProps";
import IProfileState from "../../interfaces/components/Closed/IProfileState";
import {Skeleton, Stack, Accordion, AccordionDetails, AccordionSummary, Divider, Grid, IconButton, Menu, MenuItem, MenuProps, Paper, Switch, Typography, CircularProgress, TextField, FormControlLabel } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import IProfile from "../../models/Communication/IProfile";
import StringDistributor from "../../util/StringDistributor";
import PageTitle from "../Layout/Elements/PageTitle";
import { config } from "../../config";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import IUser from "../../models/Communication/IUser";
import { groupEnd } from "console";
import IFieldGroup from "../../models/Communication/Field/IFieldGroup";
import ArrayHelper from "../../util/ArrayHelper";
import PasswordChange from "./PasswordChange";
import Editblock from "../Layout/Elements/Editblock";
import FieldValueGetter from "../../util/FieldValueGetter";
import IField from "../../models/Communication/Field/IField";
import INewValueField from "../../models/Communication/Field/INewValueField";
import IFieldValue from "../../models/Communication/Field/IFieldValue";

const useStyles = (theme: any) => ({
    paper: {
        width: "100%",
        padding: "1.25em",
        marginTop: "10px",
        height: "65vh",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "600",
        marginRight: "1em"
    },
    mainTitle: {
        color: theme.palette.primary.main,
    },
    listItem: {
        display: "flex",
        flexDirection: "row" as 'row',
        width: "100%"
    }
}); 

class ApplicantProfile extends MainPage<IProfileProps, IProfileState> {

    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            loading: true,
            edit: false,
            openSettingsMenu: false,
            changePw: false,
            menuAnchor: undefined,
            user: this.props.user,
            editedFields: [],
            editFieldsProgress: false,
            onlineProgress: false,
            switch: !this.props.user?.offline,
            newFields: [],
            fieldGroups: this.props.user?.fieldGroups,
            editProgress: false
        }   
    }

    private openSettingsMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({
                openSettingsMenu: true,
                menuAnchor: event.currentTarget
        });
    }

    private closeSettingsMenu() {
        this.setState({openSettingsMenu: false})
    }

    private switchOnline = (event: boolean) => {
        let s = !event;
        UserService.switchOffline(this.switchOfflineSuccess.bind(this), this.switchOfflineError.bind(this), s);
        this.setState({onlineProgress: true})
    }

    private switchOfflineSuccess(ans: any) {
        let s = !this.state.switch
        this.setState({onlineProgress: false, switch: s})
    }

    private switchOfflineError(err: any) {
        this.setState({onlineProgress: false})
    }

    private enableEdit() {
        this.setState({edit: true})
    }

    private saveEdit() {
        UserService.editFields(this.editFieldSuccess.bind(this), this.editFieldError.bind(this), this.state.editedFields);
        this.setState({openSettingsMenu: false, editProgress: true})
    }

    private editFieldSuccess(ans: IUser) {
        this.setState({edit: false, user: ans, fieldGroups: ans.fieldGroups, editProgress: false})
    }

    private editFieldError(err: any) {
        this.setState({edit: false,  editProgress: false})
    }

    private abortEdit() {
        this.setState({edit: false, openSettingsMenu: false, editedFields: []})
    }

    private changePassword() {
        this.setState({changePw: !this.state.changePw, openSettingsMenu: false})
    }

    private editField(newValue: INewValueField) {
        let fields = this.state.editedFields;
        
        if(ArrayHelper.contains(fields, (fields) => fields.id === newValue.field?.id)) {
            let valueO: IFieldValue = {
                value: newValue.newValue
            }
            fields[ArrayHelper.indexOf(fields, (fields) => fields.id === newValue.field?.id)].value = valueO;
        } else {
            if(newValue.field !== undefined) 
                fields.push(newValue.field);
        }
        this.setState({editedFields: fields})
        
    }

    // private addNewMultiple= (event: any, type: string) => {
    //     if(type === "Ausland") {

    //     } else if(type === "Ausbildung") {

    //     } else if(type === "Praktika") {

    //     }
    // }

    // private getNewFields(type: string) {
    //     let list = [];
    //     for(let field of this.state.newFields) {
    //         if(field.name === type) {
    //             list.push(field)
    //         }
    //     }
    // }

    render(){
        let {classes} = this.props;
        let strings= StringDistributor.get().profile

        if(this.state.changePw) {
            return(
                <div>
                     <IconButton style={{marginBottom: "5px"}} aria-label="bearbeiten" 
                        onClick={() => {this.changePassword()}}>
                        <ArrowBackIcon />
                    </IconButton>
                    <PasswordChange/>
                </div>
            )
        }

        return(
            <div>
                <PageTitle title={strings.profile}/>
                <div style={{width: "90%"}}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={11} style={{display: "flex", flexDirection: "row"}}>
                                <Editblock 
                                    name={"Vorname"} 
                                    edit={this.state.edit} 
                                    title
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Vorname", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Vorname", this.state.fieldGroups)}
                                />
                                <Editblock 
                                    style={{marginLeft: "10px"}}
                                    name={"Nachname"} 
                                    edit={this.state.edit} 
                                    title
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Nachname", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Nachname", this.state.fieldGroups)}/>
                            </Grid>
                            <Grid item xs={1}>
                                {!this.state.edit && !this.state.editProgress &&
                                    <div>
                                        <IconButton aria-label="einstellungen" aria-haspopup="true" aria-expanded={this.state.openSettingsMenu ? 'true' : undefined}
                                            onClick={event => this.openSettingsMenu(event)}>
                                            <EditIcon/>
                                        </IconButton>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={this.state.menuAnchor}
                                            open={this.state.openSettingsMenu}
                                            onClose={() => this.closeSettingsMenu()}
                                            MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => this.enableEdit()}>{strings.editProfile}</MenuItem>
                                            <MenuItem onClick={() => this.changePassword()}>{strings.changePassword}</MenuItem>
                                        </Menu>
                                    </div>
                                }
                                {this.state.edit && !this.state.editProgress &&
                                    <div>
                                        <IconButton aria-label="einstellungen" aria-haspopup="true" aria-expanded={this.state.openSettingsMenu ? 'true' : undefined}
                                            onClick={() => this.saveEdit()}>
                                            <CheckIcon/>
                                        </IconButton>
                                        <IconButton aria-label="einstellungen" aria-haspopup="true" aria-expanded={this.state.openSettingsMenu ? 'true' : undefined}
                                            onClick={() => this.abortEdit()}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                }
                                {this.state.editProgress && 
                                    <CircularProgress 
                                        sx={{
                                            marginTop: "1em",
                                            float: "right",
                                            color: "#e5342a"
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.birthDate + ": "}
                                </Typography>
                                <Editblock 
                                    name={"Geburtsdatum"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Geburtsdatum", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Geburtsdatum", this.state.fieldGroups)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.nationality+ ": "}
                                </Typography>
                                <Editblock 
                                    name={"Staatsangehörigkeit"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Staatsangehörigkeit", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Staatsangehörigkeit", this.state.fieldGroups)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.adress+ ": "}
                                </Typography>
                            <Grid item xs={12} style={{display: "flex", flexDirection: "row"}}>
                                <Editblock 
                                    style={{marginRight: "0.3em"}}
                                    name={"Straße"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Straße", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Straße", this.state.fieldGroups)}/>
                                <Editblock 
                                    name={"Hausnummer"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Hausnummer", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Hausnummer", this.state.fieldGroups)}/>
                                <Typography style={{marginRight: "0.5em"}}>{", "}</Typography>
                                <Editblock 
                                    style={{marginRight: ".3em"}}
                                    name={"Postleitzahl"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("plz", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("plz", this.state.fieldGroups)}/>
                                <Editblock 
                                    style={{marginRight: "1em"}}
                                    name={"Ort"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getFieldDescription("Ort", "Adresse", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValueDescription("Ort", "Adresse", this.state.fieldGroups)}/>
                            </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}aria-controls="staatsexam eins">
                                    <Typography 
                                        style={{fontWeight: 600}}
                                        className={classes.title}
                                    >
                                        {strings.exam + " 1"}
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.lehramt + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                    name={"Lehramt"} 
                                                    edit={this.state.edit} 
                                                    editFunction={this.editField.bind(this)}
                                                    field={FieldValueGetter.getFieldDescription("Lehramt", "Exam1", this.state.user?.fieldGroups)}
                                                    value={FieldValueGetter.getValueDescription("Lehramt", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.grade + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                    name={"Note"} 
                                                    edit={this.state.edit} 
                                                    editFunction={this.editField.bind(this)}
                                                    field={FieldValueGetter.getFieldDescription("Note", "Exam1", this.state.user?.fieldGroups)}
                                                    value={FieldValueGetter.getValueDescription("Note", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.location + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Ort"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Ort", "Exam1", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Ort", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.year + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Jahr"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Jahr", "Exam1", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Jahr", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.subject + " 1: "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Fach 1"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Fach 1", "Exam1", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Fach 1", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.subject + " 2: "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Fach 2"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Fach 2", "Exam1", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Fach 2", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.subject + " 3: "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Fach 3"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Fach 3", "Exam1", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Fach 3", "Exam1", this.state.fieldGroups)}/>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={8}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="staatsexam zwei">
                                    <Typography 
                                        style={{fontWeight: 600}}
                                        className={classes.title}
                                    >
                                        {strings.exam + " 2"}
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.lehramt + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                    name={"Lehramt"} 
                                                    edit={this.state.edit} 
                                                    editFunction={this.editField.bind(this)}
                                                    field={FieldValueGetter.getFieldDescription("Lehramt", "Exam2", this.state.user?.fieldGroups)}
                                                    value={FieldValueGetter.getValueDescription("Lehramt", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.grade + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                    name={"Note"} 
                                                    edit={this.state.edit} 
                                                    editFunction={this.editField.bind(this)}
                                                    field={FieldValueGetter.getFieldDescription("Note", "Exam2", this.state.user?.fieldGroups)}
                                                    value={FieldValueGetter.getValueDescription("Note", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.location + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Ort"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Ort", "Exam2", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Ort", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.year + ": "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Jahr"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Jahr", "Exam2", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Jahr", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.subject + " 1: "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Fach 1"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Fach 1", "Exam2", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Fach 1", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.subject + " 2: "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Fach 2"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Fach 2", "Exam2", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Fach 2", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.title}>{strings.subject + " 3: "}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Editblock 
                                                        name={"Fach 3"} 
                                                        edit={this.state.edit} 
                                                        editFunction={this.editField.bind(this)}
                                                        field={FieldValueGetter.getFieldDescription("Fach 3", "Exam2", this.state.user?.fieldGroups)}
                                                        value={FieldValueGetter.getValueDescription("Fach 3", "Exam2", this.state.fieldGroups)}/>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.apprenticeship + ": "}
                                </Typography>
                                <Editblock 
                                    name={"Ausbildung"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Ausbildung", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Ausbildung", this.state.fieldGroups)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.internship + ": "}
                                </Typography>
                                <Editblock 
                                    name={"Praktika"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Praktika", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Praktika", this.state.fieldGroups)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    style={{fontWeight: 600}}
                                    className={classes.title}
                                >
                                    {strings.foreignCountries + ": "}
                                </Typography>
                                <Editblock 
                                    name={"Ausland"} 
                                    edit={this.state.edit} 
                                    editFunction={this.editField.bind(this)}
                                    field={FieldValueGetter.getField("Ausland", this.state.user?.fieldGroups)}
                                    value={FieldValueGetter.getValue("Ausland", this.state.fieldGroups)}/>
                                {/* <ul>
                                    {FieldValueGetter.getValueGroup("Ausland", this.props.user?.fieldGroups)?.fields.map((field: IField) => (
                                        <li><Typography>{field.value.value}</Typography></li>
                                    ))} 
                                    {this.state.edit && 
                                        <TextField 
                                            size="small"
                                            onChange={(e) => {this.addNewMultiple(e.target.value, "Ausland")}}
                                            sx={{width: "50%"}}
                                        >
                                        </TextField>
                                    } 
                                </ul> */}
                            </Grid>
                        </Grid>
                    </Paper>
                    {!this.state.onlineProgress && 
                        <FormControlLabel
                            sx={{
                                float: "right"
                            }}
                            value="start"
                            control={
                                <Switch 
                                    
                                    color='success'
                                    disabled={!this.props.user?.complete}
                                    defaultChecked={this.state.switch}
                                    onChange={(e) => {this.switchOnline(e.target.checked)}}
                                />
                            }
                            label={this.state.switch ? "Online" : "Offline"}
                            labelPlacement="start"
                        />
                        
                    }
                    {this.state.onlineProgress && 
                        <CircularProgress 
                            sx={{
                                float: "right",
                                color: "#e5342a"
                            }}
                        />
                    }
                </div>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(ApplicantProfile);