import * as React from 'react';
import PropTypes from "prop-types";

import IMainLayoutProps from '../../interfaces/components/Layout/IMainLayoutProps';

import { withStyles} from '@mui/styles';
import Background from "./Background";

import Header from './Header';
import SideBar from './SideBar';
import { Button, Typography } from '@mui/material';
import IMainLayoutState from '../../interfaces/components/IMainLayoutState';
import AuthService from '../../services/AuthService';
import Footer from './Footer';


const useStyles = (theme: any) => ({
    mainLayoutContainer: {
        'overflow-x': 'hidden',
        'overflow-y': 'scroll',
        overflow: "auto",
        'flex': '1'
    },
    mainLayoutContainerMobile: {
        marginLeft: "4em",
        'overflow-x': 'hidden',
        'overflow-y': 'scroll',
        overflow: "auto",
        'flex': '1'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        marginTop: "100px",
    }
});

class MainLayout extends React.Component<IMainLayoutProps, IMainLayoutState>{
    constructor(props : IMainLayoutProps){
        super(props);

        this.state = {
            mobile: (window.innerWidth <= 910)
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        // this.setState({mobile: window.innerWidth <= 760});
        let currentHideNav = (window.innerWidth <= 910);
        if (currentHideNav !== this.state.mobile) {
            this.setState({mobile: currentHideNav});
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render(){
        let {classes} = this.props;
        //let [alignment, setAlignment] = React.useState(this.props.toggle);

        if(window.innerWidth > 768) {
            return(
                <Background>
                    <Header/>

                    <div className={classes.contentContainer}>
                        {AuthService.get().isAuthenticated() &&
                            <SideBar/>
                        }
                        {!AuthService.get().isAuthenticated() &&
                            <div style={{width: "6em"}}>

                            </div>
                        }
                        <div className={classes.mainLayoutContainer}>
                            {this.props.children}
                        </div>
                    </div>
            
                    <Footer/>
                </Background>
            )
        } else {
            return (
                <Background>
                    <Header/>
    
                    <div className={classes.contentContainer}>
                        {AuthService.get().isAuthenticated() &&
                            <SideBar/>
                        }
                        {!AuthService.get().isAuthenticated() &&
                            <div style={{width: "2em"}}>

                            </div>
                        }
                        <div className={classes.mainLayoutContainerMobile}>
                            {this.props.children}
                        </div>
                    </div>
            
                    <Footer/>
                </Background>
            );
        }
    }
}

export default withStyles(useStyles, {withTheme : true})(MainLayout);