import { Paper, Typography, Button, Grid } from '@mui/material';
import * as React from 'react';

import { withStyles } from '@mui/styles';


const useStyles = (theme: any) => ({
    paper: {
 
    }
}); 

class Footer extends React.Component<any, any> {
    constructor(props: any) {
		super(props);

		this.state = {
			
		}
	}

    render() {
        return (
            <Paper sx={{
                width: "100%",
                height: "5%",
                position: "fixed",
                bottom: 0,
                backgroundcolor: "white",
                display: "flex",
                flexDirection: "row"
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={0.3}></Grid>
                    <Grid item xs={3} md={2} sm={2} lg={1}>
                        <Typography>Impressum</Typography>
                    </Grid>
                    <Grid item xs={3} md={2} sm={2} lg={1}>
                        <Typography>Datenschutzerklärung</Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}
export default withStyles(useStyles, { withTheme: true })(Footer);