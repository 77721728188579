
import React from "react";
import { withStyles} from '@mui/styles';
import { Button, Checkbox, Divider, Grid, Paper, Typography, TextField, IconButton, CircularProgress } from "@mui/material";
import { PermissionService } from "../../../services/PermissionService";
import IEnterEmailProps from "../../../interfaces/components/Public/PasswordReset/IEnterEmailProps";
import IEnterEmailState from "../../../interfaces/components/Public/PasswordReset/IEnterEmailState";
import Header from "../../Layout/Header";
import LButton from "../../Layout/Elements/Pieces/LButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Background from "../../Layout/Background";
import { Redirect } from "react-router-dom";
import { config } from "../../../config";
import UserService from "../../../services/UserService";
import { ErrorCodes } from "../../../Enums/ErrorCodes";
import StringDistributor from "../../../util/StringDistributor";

const useStyles = (theme: any) => ({
    paper: {
        margin: "auto",
        display: "flex" as 'flex',
        flexDirection: "column" as 'column',
        justifyContent: "space-between" as 'space-between',
        padding: "20px",
        alignItems: "center"
    },
    errorPaper: {
        backgroundColor: "#e08e89",
        padding: "20px"
    },
    body: {
        width: "60%",
        margin: "auto" as 'auto',
        display: "flex" as 'flex',
        flexDirection: "column" as 'column',
        justifyContent: "space-between" as 'space-between',
    }
}); 

class EnterEmail extends React.Component<IEnterEmailProps, IEnterEmailState> {
    constructor(props: IEnterEmailProps) {
        super(props);

        this.state = {
            back: false,
            email: "",
            error: false,
            errorCode: 0,
            success: false,
            progress: false
        }
    }

    private sendLink() {
        UserService.resetPasswortLink(this.resetLinkSuccess.bind(this), this.resetLinkError.bind(this), this.state.email);
        this.setState({error: false, progress: true})
    }

    private resetLinkSuccess(ans: any) {
        console.log(ans)
        this.setState({success: true})
    }

    private resetLinkError(err: any) {
        console.log(err)
        this.setState({error: true, errorCode: err.response.data, progress: false})
    }

    private back(){
        this.setState({back: true})
    }

    private changeEmail(email: string) {
        this.setState({email: email})
    }

    render(){
        let {classes} = this.props;

        let strings = StringDistributor.get().login;

        if(this.state.back) {
            return (<Redirect to={"/" + config.loginRoute}/>);
        }

        return(
            <div>
                <Header/>
                <Background >
                    {!this.state.success &&
                        <div className={classes.body}>
                            <div className={classes.detailsDiv}>
                                <IconButton style={{}} aria-label="zurück" 
                                    onClick={() => {this.back()}}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            <Paper className={classes.paper}>
                                <Typography>{strings.enterEmail}</Typography>
                                    <TextField
                                            label={"E-Mail Adresse"}
                                            value={this.state.email}
                                            size="small"
                                            type="email"
                                            onChange={(e) => {this.changeEmail(e.target.value)}}
                                            sx={{width: "100%", marginTop: "20px"}}
                                        />
                                {!this.state.progress &&
                                    <LButton 
                                        sx={{width: "60%", marginTop: "20px"}} 
                                        label={strings.sendLink} 
                                        onClick={() => {this.sendLink()}}
                                    />
                                }
                                {this.state.progress &&
                                    <CircularProgress 
                                        sx={{
                                            marginTop: "1em",
                                            color: "#e5342a"
                                        }}
                                    />
                                }
                            </Paper>
                            {this.state.error &&
                                <Paper className={classes.errorPaper}>
                                    <Typography>
                                        {strings.notExists}
                                    </Typography>
                                </Paper>
                            }
                        </div>
                    }
                    {this.state.success &&
                        <div className={classes.body}>
                            <div className={classes.detailsDiv}>
                                <IconButton style={{}} aria-label="zurück" 
                                    onClick={() => {this.back()}}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            <Paper className={classes.paper}>
                                <Typography>{strings.linkSend}</Typography>
                            </Paper>
                        </div>
                    }
                </Background>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(EnterEmail);