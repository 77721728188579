import IFieldGroup from "../models/Communication/Field/IFieldGroup";

export default class FieldValueGetter {

    public static getValue(name: string, groups: IFieldGroup[] | undefined) {
        if(groups !== undefined) {
            for(let i = 0; i < groups.length; i++) {
                for(let j = 0; j < groups[i].fields.length; j++) {
                    if(groups[i].fields[j].name === name) {
                        if(groups[i].fields[j].value !== null) {
                            return groups[i].fields[j].value.value
                        }
                        return "-"
                    }
                }
            }
            return "-"
        }
        return "-"
    }

    public static getField(name: string, groups: IFieldGroup[] | undefined) {
        if(groups !== undefined && groups !== null) {
            for(let i = 0; i < groups.length; i++) {
                for(let j = 0; j < groups[i].fields.length; j++) {
                    if(groups[i].fields[j].name === name) {
                        let a = Object.assign({}, groups[i].fields[j]);
                        return a;
                    }
                }
            }
            return undefined
        }
        return undefined
    }

    public static getValueDescription(fieldName: string, description: string, groups: IFieldGroup[] | undefined) {
        if(groups !== undefined) {
            for(let i = 0; i < groups.length; i++) {
                for(let j = 0; j < groups[i].fields.length; j++) {
                    if(groups[i].fields[j].name === fieldName && description === groups[i].fields[j].description) {
                        if(groups[i].fields[j].value !== null) {
                            return groups[i].fields[j].value.value
                        }
                        return "-"
                    }
                }
            }
            return "-"
        }
        return "-"
        
    }

    public static getFieldDescription(fieldName: string, description: string, groups: IFieldGroup[] | undefined) {
        if(groups !== undefined && groups !== null) {
            for(let i = 0; i < groups.length; i++) {
                for(let j = 0; j < groups[i].fields.length; j++) {
                    if(groups[i].fields[j].name === fieldName && description === groups[i].fields[j].description) {
                        if(groups[i].fields[j] !== null) {
                            return Object.assign({}, groups[i].fields[j]);
                        }
                        return undefined
                    }
                }
            }
            return undefined
        }
        return undefined
        
    }

    public static getValueGroup(name: string, groups: IFieldGroup[] | undefined) {
        if(groups !== undefined && groups !== null) {
            for(let i = 0; i < groups.length; i++) {
                for(let j = 0; j < groups[i].fields.length; j++) {
                    if(groups[i].name === name) {
                        return groups[i]
                    }
                }
            }
            return undefined
        }
        return undefined
    }
}