import IfHelper from "../util/IfHelper";
import ArrayHelper from "../util/ArrayHelper";
import { Theme, createTheme, createMuiTheme } from "@mui/material/styles";
import { LegalFile } from "../Enums/LegalFile"; 
import IContent from "../models/IContent";

import { lato } from "../Fonts/Lato";
import axios from "axios";

export default class ThemeService {
    private static instance: ThemeService;

    private mainTheme: Theme | undefined;
    private initialized: boolean;
    private legalDocuments: Map<LegalFile, Map<number, IContent>> | undefined;
    private timeSelectTheme: Theme | undefined;

    private static activeTheme: Theme | undefined;

    private constructor() {
        this.initialized = false;
    }

    public static getAlphaColor(hex: string, alpha: number){
        if(alpha < 0 || alpha > 1){
            throw "Alpha value must be between 0 and 1";
        }
        
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        
        if(IfHelper.isNull(result)){
            throw "Could not parse hex color!";
        }

        result = result as RegExpExecArray;

        let colors = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }

        return `rgba(${colors.r}, ${colors.g}, ${colors.b}, ${alpha})`
    }

    public static get() {
        if (IfHelper.isNull(this.instance)) {
            this.instance = new ThemeService();
        }

        return this.instance;
    }

    public async init() {
        this.mainTheme = await this.getMainTheme();
        //this.legalDocuments = await this.getLegalDocuments();

        this.initialized = true;
    }

    public static getActiveTheme() {
        return this.activeTheme;
    }

    public getTheme() {
        if (!this.initialized)
            throw "CorporateIdentityService not initialized";

        // Object.assign(this.mainTheme, {
        //     overrides: {
        //         MUIRichTextEditor: {
        //             root: {
        //                 marginTop: 20,
        //                 width: "80%"
        //             },
        //             editor: {
        //                 borderBottom: "1px solid gray" 
        //             }
        //         }
        //     }
        // })

        return this.mainTheme as Theme;
    }

    public getLegalDocument(type: LegalFile) {
        // if (!this.initialized)
        //     throw "CorporateIdentityService not initialized";

        // let map = this.legalDocuments as Map<LegalFile, Map<number, IContent>>;

        // if (!map.has(type))
        //     return undefined;

        // let path = map.get() as Map<number, IContent>;
        // let final = path.get();

        // return final;
    }

    /*
light green (Hintergrund): #F4F9F8

blue(Überschriften + Buttons: #0768B5

light blue(Icons und Auswahl/highligt): #2F9FF8

light blue (mit Tranzparenz): #2F9FF8 10%

grey: #94A3B8

black (Textfarbe): #000000
    */

    private async getDarkTheme() {
        return createMuiTheme({
            palette: {
                secondary: {
                    main: '#000000',
                    contrastText: '#ffffff'
                },
                primary: {
                    main: '#e95130',
                    contrastText: '#ffffff'
                },
                
            }
        });
    }

    private getMainTheme() {

        let theme = createTheme({
            palette: {
                primary: {
                    main: '#e5342a', // rot (Uberschriften + Buttons)
                    light: '#2F9FF8', // light blue(Icons und Auswahl/ Highlights)
                    contrastText: '#000000'
                },
                secondary: {
                    main: '#F4F9F8', // light green (Hintergrund)
                    light: '#94A3B8', // grey
                    contrastText: '#000000'
                }
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        '@global': {
                            '@font-face': lato
                        }
                    }
                },
                
            },
        });

        Object.assign(theme, {
            overrides: {
               MUIRichTextEditor: {
                  root: {
                    border: "1px solid #d4d4d4",
                    borderRadius: "5px",
                    padding: "0.2em",
                  },
                  editor: {
                    maxHeight: '12.5em',
                    overflow: 'auto',
                  },
                  placeHolder: {
                    minHeight: "2em",
                    width: 'inherit',
                  },
                  toolbar: {
                    borderBottom: "1px solid #d4d4d4",
                  },
                  button: {
                    backgroundColor: "green"
                  }
               },
               MuiIconButton: {
                    root: {
                        backgroundColor: "#e5342a"
                    }
               },
               MuiButtonBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: "green",
                        textTransform: "none",
                    }
                },
                MuiTextField: {
                    root: {
                        "&:hover": {
                            color: "#e5342a"
                          },
                          // this will applied when hovered (input border color change)
                          "&:hover $textFieldNotchedOutline": {
                            borderColor:  "#e5342a"
                          },
                          // this will be applied when focused (input border color change)
                          "&$textFieldFocused $textFieldNotchedOutline": {
                            borderColor:  "#e5342a"
                          }
                    },
                    textFieldLabel: {
                        // this will be applied when input focused (label color change)
                        "&$textFieldLabelFocused": {
                          color: "#e5342a"
                        }
                      },
                }
            }
            },
         })

        return theme;
    }

   

    private async getLegalDocuments() {
        let map = new Map<LegalFile, Map<number, IContent>>();

        //let gtc = new Map<number, IContent>();
        //gtc.set(1033, { content: require("../assets/LegalTerms/AGB_1033.html"), isPdf: false });
        //gtc.set(1031, { content: require("../assets/LegalTerms/AGB_1031.html"), isPdf: false });

       let legalNotice = new Map<number, IContent>();
       legalNotice.set(1033, { content: await this.getPublicElement("/assets/LegalTerms/Impressum_1033.html") as string, isPdf: false });
       legalNotice.set(1031, { content: await this.getPublicElement("/assets/LegalTerms/Impressum_1031.html") as string, isPdf: false });

       let privacyPolicy = new Map<number, IContent>();
       privacyPolicy.set(1033, { content: await this.getPublicElement("/assets/LegalTerms/Privacy_Policy_1033.html") as string, isPdf: false });
       privacyPolicy.set(1031, { content: await this.getPublicElement("/assets/LegalTerms/Privacy_Policy_1031.html") as string, isPdf: false });

        //let cop = new Map<number, IContent>();
        //cop.set(1033, { content: require("../assets/LegalTerms/Conditions_of_Participation_1031.html"), isPdf: false });
        //cop.set(1031, { content: require("../assets/LegalTerms/Conditions_of_Participation_1031.html"), isPdf: false });
//
        //let faq = new Map<number, IContent>();
        //faq.set(1033, { content: require("../assets/LegalTerms/Information_Requirements_1033.pdf"), isPdf: true });
        //faq.set(1031, { content: require("../assets/LegalTerms/Information_Requirements_1031.pdf"), isPdf: true });
//
        //let rules = new Map<number, IContent>();
        //rules.set(1033, { content: require("../assets/LegalTerms/Rules_1033.html"), isPdf: false });
        //rules.set(1031, { content: require("../assets/LegalTerms/Rules_1031.html"), isPdf: false });

        //map.set(LegalFile.GTC, gtc);
       map.set(LegalFile.LEGAL_NOTICE, legalNotice);
       map.set(LegalFile.PRIVACY_POLICY, privacyPolicy);
        //map.set(LegalFile.CONDITION_OF_PARTICIPATION, cop);
        //map.set(LegalFile.FAQ, faq);
        //map.set(LegalFile.RULES, rules);

        return map;
    }

    private getPublicElement(url: string){
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(response => resolve(response.data))
            .catch(err => reject());
        })
    }
}