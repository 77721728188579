import IConfig from '../interfaces/IConfig';
import {config} from '../config';
import IfHelper from "../util/IfHelper";
import { ApiPath } from "../Enums/ApiPath"; 

export default class ConfigService{
    public static getJSONUrl(){
        let config : IConfig = this.getConfig();

        let url = this.combineUrl(config.api, config.restExtension);

        if(IfHelper.isNull(url))
            throw "Could not parse rest api url";

        return url;
    }

    // public static getFileUrl(){
    //     let config : IConfig = this.getConfig();

    //     let url = this.combineUrl(config.api, config.fileExtension);

    //     if(IfHelper.isNull(url))
    //         throw "Could not parse file api url";

    //     return url;
    // }

    public static getApiUrl(path: ApiPath){
        let config = this.getConfig();

        let pathStr = null;

        switch(path){
            case ApiPath.Public:
                pathStr = config.publicApi;
                break;
            case ApiPath.Secured:
                pathStr = config.securedApi;
                break;
            default:
                pathStr = null;
        }

        if(IfHelper.isNull(pathStr))
            throw "Invalid path parameter";

        let url = this.combineUrl(config.api, pathStr as string);

        if(IfHelper.isNull(url))
            throw "Could not parse api url";

        return url;
    }

    private static getConfig() : IConfig{
        return config;
    }

    private static combineUrl(arg1 : string | undefined, arg2 : string) : string | null{
        if(arg1 === undefined) {
            return null;
        }

        if(IfHelper.IsNullOrWhitespace(arg1)){
            return null;
        }

        if(IfHelper.IsNullOrWhitespace(arg2)){
            return arg1;
        }

        if(!arg1.endsWith('/')){
            arg1 += "/"
        }

        if(arg2.startsWith('/'))
            arg2 = arg2.substring(1);

        let complete = arg1 + arg2;

        return complete;
    }
}