import { CookieType } from "../Enums/CookieType";
import IStrings from "./IStrings";

export const Strings : IStrings = {
    login: {
        login: "anmelden",
        email: "E-Mail",
        password: "Passwort",
        secondPassword: "Passwort wiederholen",
        register: "Registrieren",
        forgotPasswort: "Passwort vergessen?",
        rememberMe: "An mich erinnern",
        nice: "Schön, dass du da bist!",
        welcomeBack: "Willkommen zurück! Jetzt an Deinem Konto anmelden.",
        enterEmail: "Geben Sie hier Ihre E-Mail Adresse ein, mit der Sie sich auf der Plattform registriert haben",
        sendLink: "Link senden",
        enterNewPassword: "Geben Sie hier Ihr neues Passwort ein",
        changePassword: "Passwort ändern",
        notExists: "Die angegebene E-Mail Adresse ist entweder falsch oder existiert nicht!",
        notIdentical: "Die beiden eingegebenen Passwörter stimmen nicht überein!",
        linkSend: "Es wurde eine E-Mail mit einem Link zum zurücksetzten Ihres Passworts an Sie versendet.",
        changedSuccess: "Ihr Password wurde erfolgreich geändern. Sie können sich nun mit Ihrem neuen Passwort anmelden!",
        error: "Dieser Link zum zurücksetzten Ihres Passworts scheint bereits abgelaufen zu sein. Sollte Ihr Problem weiterhin bestehen fordern Sie einen weiteren Link an."
    },
    auth: {
        noData: "Fehler beim Verarbeiten der Anfrage.",
        missingMail: "Bitte geben Sie eine E-Mail-Addresse ein.",
        missingPassword: "Bitte geben Sie ein Password ein.",
        missingSecondPassword: "Bitte geben Sie ihr Passwort wieder ein",
        technicalProblems: "Die Anmeldung ist auf Grund technischer Probleme fehlgeschlagen.",
        invalidLogin: "Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie die E-Mail und das Passwort."
    },
    header: {
        logout: 'abmelden',
        logoText1: 'Stellenportal',
        logoText2: 'für Lehrkräfte'
    },
    profile: {
        anrede: "Anrede",
        profile: "Dein Profil",
        birthDate: "Geburtsdatum",
        location: "Ort",
        email: "E-Mail",
        livingLocation: "Wohnort",
        nationality: "Staatsangehörigkeit",
        street: "Straße",
        houseNr: "Hausnummer",
        tel: "Telefonnummer",
        exam: "Staatsexamen",
        lehramt: "Lehramt",
        grade: "Note",
        year: "Jahr",
        subject: "Fach",
        furtherQualifications: "Weitere Qualifikationen",
        apprenticeship: "Ausbildung",
        internship: "Praktika",
        foreignCountries: "Ausland",
        adress: "Adresse",
        offline: "Offline",
        online: "Online",
        editProfile: "Profil bearbeiten",
        changePassword: "Passwort ändern"
    },
    announcement: {
        kennnummer: "Kennnummer",
        title: "Titel",
        infoText: "Infotext",
        deadline: "Frist",
        template: "Vorlage",
        locked: "Gesperrt",
        requirements: "Vorausetzungen",
        subject1: "Fach 1",
        subject2: "Fach 2",
        subject3: "Fach 3"
    },
    createPosting: {
        createNew: "Neue Stellenausschreibung erstellen",
        template: "Vorlage",
        useTemplate: "Vorlage wählen",
        identificationNumber: "Kennnummer",
        title: "Titel",
        locked: "Gesperrt",
        infoText: "Infotext",
        requirements: "Voraussetzungen",
        deadline: "Frist",
        create: "Erstellen",
        required: "Erforderlich",
        subject: "Fach",
        suc: "Erfolgreich!",
        success: "Die Ausschreibung wurde erfolgreich erstellt.",
        err: "Fehler!",
        error: "Bei der Erstellung der Ausschreibung ist etwas schiefgelaufen. \n Probieren Sie es erneut"
    },
    applicantList: {
        applicantList: "Bewerberliste"
    },
    documents: {
        yourDocuments: "Deine Dokumente",
        edit: "Bearbeiten",
        download: "Herunterladen",
        delete: "Löschen",
        addMore: "weitere Dokumente hinzufügen",
        cv: "Lebenslauf",
        firstStaatsexam: "1. Staatsexamen",
        secondStaatsexam: "2. Staatsexamen",
        additional: "zusätzliche Qualifikationen",
        questionnaire: "Personalfragebogen",
        contract: "Arbeitsvertrag",
        certificate: "Arbeitszeugnis"
    },
    sbVerwaltung: {
        subject: "Fächer",
        addSubject: "Fach hinzufügen",
        user: "Nutzer",
        addUser: "Nutzer hinzufügen",
        add: "hinzufügen",
        administration: "Verwaltung",
        email: "E-Mail Adresse",
        role: "Rolle",
        roles: "Rollen",
        selectRole: "Rolle des neuen Benutzers auswählen",
        addRole: "Rolle hinzufügen",
        bezeichnung: "Bezeichnung",
        berechtigungen: "Berechtigungen",
        emailHelperText: "E-Mail Adresse des neuen Nutzers eingeben"
    },
    reporting: {
        reporting: "Reporting",
        angebotNachfrage: "Angebot & Nachfrage",
        lage: "Bewerbungslage",
        amountApplicants: "Anzahl Bewerber",
        amount: "Anzahl"
    },
    postingOverview: {
        overview: "Stellenübersicht",
        apply: "Bewerben",
        applySelected: "Möchten Sie sich bei allen von Ihnen ausgewählten Stellen bewerben?",
        really: "Möchten Sie sich auf diese Stelle bewerben?",
        withdraw: "Bewerbung zurückziehen",
        withdrawText: "Möchten Sie ihre Bewerbung auf diese Stelle zurückziehen?"
    },
    filter: {
        apply: "anwenden",
        subject: "Fach",
        name: "Name",
        email: "E-Mail Adresse"
    },
    errorScreen: {
        title: "Oops!",
        loadingText: "Diese Seite konnte nicht geladen werden.",
        permissionText: "Sie verfügen nicht über die benötigten Berechtigungen um hier zu sein."
    },
    cookies: {
        message: "Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern.",
        title: "Datenschutzeinstellungen",
        accept: "Alle Akzeptieren",
        save: "Speichern",
        impress: "Impressum",
        privacyNotice: "Datenschutzerklärung",
        details: "Detailliert",
        defaultProvider: "Eigentümer dieser Website",
        cookiePurpose: new Map([
            ["LEHRERPORTAL_COOKIES", "Speichert, ob die Cookies akzeptiert worden sind"],
            ["LEHRERPORTAL_COOKIES_TYPE", "Speichert welche Cookies akzeptiert worden sind"],
            ["LEHRERPORTAL_NAV", "Speichert die aktuelle Navigation"], 
            ["LEHRERPORTAL_AUTH_JWT", "Wird für die Anmeldung verwendet und beinhaltet grundlegende Informationen zum Benutzer"],
            ["server_session_id", "?"],
            ["api_token", "?"],
            ["unique_id_durable", "?"],
            ["unique_id", "?"],
            ["referrer_url", "?"],
        ]),
        cookieExpiry: new Map([
            ["LEHRERPORTAL_COOKIES", "1 Jahr"],
            ["LEHRERPORTAL_COOKIES_TYPE", "1 Jahr"],
            ["LEHRERPORTAL_NAV", "1 Tag"], 
            ["LEHRERPORTAL_AUTH_JWT", "1 Tag"],
            ["server_session_id", "Nach Beendigung des Browsers"],
            ["api_token", "6 Monate"],
            ["unique_id_durable", "10 Jahre"],
            ["unique_id", "10 Jahre"],
            ["referrer_url", "1 Tag"]
        ]),
        types: new Map([
            [CookieType.Essential, "Essenziell"],
            [CookieType.ExternalMedia, "Externe Medien"]
        ]),
        nameHeader: "Name",
        providerHeader: "Anbieter",
        purposeHeader: "Zweck",
        expiryHeader: "Ablauf",
        simple: "Einfach"
    }
}