import React from "react";
import IHeaderProps from "../../interfaces/components/Layout/IHeaderProps";
import IHeaderState from "../../interfaces/components/Layout/IHeaderState";

import { withStyles } from "@mui/styles";
import { AppBar, Button, Icon, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SchoolIcon from '@mui/icons-material/School';
import LoginIcon from '@mui/icons-material/Login';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StringDistributor from "../../util/StringDistributor";
import ThemeService from "../../services/ThemeService";
import { config } from "../../config";
import { Redirect, withRouter } from "react-router-dom";
import AuthService from "../../services/AuthService";

const useStyles = (theme: any) => ({
    appBar: {
        width: "100%",
        display: 'flex',
        flexDirection: "row" as 'row',
        justifyContent: "space-between" as 'space-between'
    },
    userBlock: {
        display: 'flex',
        flexDirection: "row" as 'row',
        color: theme.palette.primary.main,
        justifyContent: "space-between" as 'space-between',
        alignItems: "center",
    },
    userText : {
        display: 'flex',
        flexDirection: "column" as 'column',
        color: theme.palette.primary.contrastText
    },
    logo: {
        width: "13em",
        display: 'flex',
        flexDirection: "row" as 'row',
        color: theme.palette.primary.main,
        justifyContent: "space-evenly" as 'space-evenly'
    },
    logoText: {
        display: 'flex',
        flexDirection: "column" as 'column',
    }
});

class Header extends React.Component<IHeaderProps, IHeaderState> {
    constructor(props: IHeaderProps) {
        super(props);

        this.state = {
            isAuth: false,
            openMenu: false,
            menuAnchor: undefined,
            mobile: (window.innerWidth <= 600),
            toLogin: false,
            toPostings: false,
            nav: ""
        }
    }

    private openMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({
                openMenu: true,
                menuAnchor: event.currentTarget
        });
    }

    private closeMenu() {
        this.setState({openMenu: false})
    }

    private logout() {
        AuthService.get().logout();
        this.props.history.push("/" + config.loginRoute)
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        let navi = window.location.href.split("/")[5]
        this.setState({nav: navi})
    }
    
    resize() {
        // this.setState({mobile: window.innerWidth <= 760});
        let currentHideNav = (window.innerWidth <= 600);
        if (currentHideNav !== this.state.mobile) {
            this.setState({mobile: currentHideNav});
        }
    }

    private toLogin() {
        this.setState({toLogin: true})
    }

    private toPostings() {
        this.setState({toPostings: true})
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {

        let strings = StringDistributor.get().header;
        let {classes} = this.props;

        if(this.state.toLogin) {
            return (<Redirect to={"/" + config.loginRoute}/>);
        } else if(this.state.toPostings) {
            return (<Redirect to={"/" + config.overviewRoute}/>);
        }

        return(
            <AppBar sx={{ bgcolor: "#F4F9F8" }} >
                <Toolbar>
                    <div className={classes.appBar}>
                        <div className={classes.logo}>
                            <SchoolIcon style={{fontSize: "3em"}} fontSize="inherit"/>
                            <div className={classes.logoText}>
                                <Typography style={{fontWeight: 600}} variant="h5">{strings.logoText1}</Typography>
                                <Typography>{strings.logoText2}</Typography>
                            </div>
                        </div>

                        {/* show only when logged-in */}
                        {AuthService.get().isAuthenticated() && !this.state.mobile &&
                            <div className={classes.userBlock}>
                                <PersonOutlineIcon style={{fontSize: "2em"}} fontSize="inherit"/>
                                <div className={classes.userText}>
                                    <Typography>{AuthService.get().getUserEmail()}</Typography>
                                </div>
                                <IconButton aria-label="" 
                                    onClick={event => this.openMenu(event)}>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={this.state.menuAnchor}
                                    open={this.state.openMenu}
                                    onClose={() => this.closeMenu()}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => this.logout()}>{strings.logout}</MenuItem>
                                </Menu>
                            </div>
                        }
                        {AuthService.get().isAuthenticated() && this.state.mobile &&
                            <div className={classes.userBlock}>
                                <IconButton aria-label="" 
                                    onClick={event => this.openMenu(event)}>
                                    <PersonOutlineIcon style={{fontSize: "1.5em", color: "#e5342a"}} fontSize="inherit"/>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={this.state.menuAnchor}
                                    open={this.state.openMenu}
                                    onClose={() => this.closeMenu()}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => this.logout()}>{strings.logout}</MenuItem>
                                </Menu>
                            </div>
                        }
                        {!AuthService.get().isAuthenticated() && this.state.nav === "uebersicht" &&
                            <div className={classes.userBlock}>
                                {/* <IconButton aria-label="" 
                                    onClick={() => this.toLogin()}>
                                    <LoginIcon style={{fontSize: "1.5em", color: "#e5342a"}} fontSize="inherit"/>
                                </IconButton> */}
                                <Button
                                    onClick={() => this.toLogin()}
                                    sx={{ 
                                        color: "#e5342a", 
                                        border: "none", 
                                        marginBottom: "5px",
                                        marginTop: "5px",
                                        "&:hover": { 
                                            borderColor: "#e5342a"
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: "1px solid #e5342a",
                                            borderRadius: "5px 5px 0 0"
                                        },
                                    }}
                                >
                                    {"Zum Login"}
                                </Button>
                            </div>
                        }
                        {!AuthService.get().isAuthenticated() && (this.state.nav === "" || this.state.nav === "login") &&
                            <div className={classes.userBlock}>
                                {/* <IconButton aria-label="" 
                                    onClick={() => this.toPostings()}>
                                    <FormatListBulletedIcon style={{fontSize: "1.5em", color: "#e5342a"}} fontSize="inherit"/>
                                </IconButton> */}
                                <Button
                                    onClick={() => this.toPostings()}
                                    sx={{ 
                                        color: "#e5342a", 
                                        border: "none", 
                                        marginBottom: "5px",
                                        marginTop: "5px",
                                        "&:hover": { 
                                            borderColor: "#e5342a"
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            border: "1px solid #e5342a",
                                            borderRadius: "5px 5px 0 0"
                                        },
                                    }}
                                >
                                    {"Zur Stellenübersicht"}
                                </Button>
                            </div>
                        }
                    </div>
                </Toolbar>
            </AppBar>
        )
    }
}
export default withRouter(withStyles(useStyles, {withTheme : true})(Header));
