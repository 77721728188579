import IComperatorFunctions from "../Functions/IComperatorFunction";
import IfHelper from "./IfHelper";
import IReturnFunction from "../Functions/IReturnFunction";

export default class ArrayHelper{
    public static contains<T>(array: Array<T>, func: IComperatorFunctions<T>){
        if(IfHelper.isNull(array))
            return false;
        if(array.length === 0)
            return false;

        for(let i = 0; i < array.length; i++){
            let item = array[i];
            let value = func(item);

            if(value)
                return true;
        }

        return false;
    }

    public static indexOf<T>(array: Array<T>, func: IComperatorFunctions<T>){
        if(IfHelper.isNull(array))
            return -1;
        if(array.length === 0)
            return -1;

        for(let i = 0; i < array.length; i++){
            let item = array[i];
            let value = func(item);

            if(value)
                return i;
        }

        return -1;
    }

    public static select<T, V>(array: Array<T>, func: IReturnFunction<T, V>): Array<V>{
        let arr = [] as Array<V>;

        for(let i = 0; i < array.length; i++){
            let obj = array[i];
            arr.push(func(obj));
        }

        return arr;
    }

    public static where<T>(array: Array<T>, func: IComperatorFunctions<T>){
        if(IfHelper.isNull(array))
            return undefined;
        if(array.length === 0)
            return new Array<T>();

        let nArray = new Array<T>();

        for(let i = 0; i < array.length; i++){
            let item = array[i];
            let value = func(item);

            if(value){
                nArray.push(item);
            }
        }

        return nArray;
    }
}