import IConfig from "./interfaces/IConfig";

export const config : IConfig = 
{
    api: process.env.REACT_APP_API_URL,
    restExtension: "api",
    publicApi: "",
    securedApi: "",

    defaultLanguage: 1033,
    loginRoute: "login",
    registerRoute:"register",
    logoutRoute: "logout",
    overviewRoute: "uebersicht",
    jobDetailRoute: "detail",
    resetPasswortRoute: "passwoertZurueksetzen",
    profileRoute: "profil",
    documentsRoute: "dokumente",
    createPostingRoute: "auschreibungErstellen",
    clerkAdministration: "sachbearbeiterVerwaltung",
    administration: "administration",
    reportingRoute: "reporting",
    applicantListRoute: "bewerberliste",
    tabsRoute:"Tabs",
    angebotNachfrageRoute: "angebot&nachfrage",
    lageRoute: "bewerberlage",
    enterEmailRoute: "email",
    enterNewPassword: "neuesPasswort",
    impressumRoute: "impressum",
    privacyRoute: "datenschutzerklaerung"
}