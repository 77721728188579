import React from "react";
import { styled, withStyles} from '@mui/styles';
import MainPage from "../Public/MainPage";
import IProfileProps from "../../interfaces/components/Closed/IProfileProps";
import IProfileState from "../../interfaces/components/Closed/IProfileState";
import {Skeleton, Stack, Accordion, AccordionDetails, AccordionSummary, Divider, Grid, IconButton, Menu, MenuItem, MenuProps, Paper, Switch, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

import IProfile from "../../models/Communication/IProfile";
import StringDistributor from "../../util/StringDistributor";
import PageTitle from "../Layout/Elements/PageTitle";
import { config } from "../../config";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import IUser from "../../models/Communication/IUser";
import { groupEnd } from "console";
import IFieldGroup from "../../models/Communication/Field/IFieldGroup";
import ArrayHelper from "../../util/ArrayHelper";
import PasswordChange from "./PasswordChange";
import SachbearbeiterProfile from "./SachbearbeiterProfile";
import SchulleiterProfile from "./SchulleiterProfile";
import ApplicantProfile from "./ApplicantProfile";

const useStyles = (theme: any) => ({

}); 

class Profile extends MainPage<IProfileProps, IProfileState> {
    constructor(props: IProfileProps) {
        super(props);

        this.state = {
            loading: true,
            edit: false,
            openSettingsMenu: false,
            changePw: false,
            menuAnchor: undefined,
            user: undefined,
            editFieldsProgress: false,
            editedFields: [],
            onlineProgress: false,
            switch: false,
            newFields: [],
            fieldGroups: undefined,
            editProgress: false
        }
    }

    componentDidMount() {
        UserService.get(this.userSuccess.bind(this), this.userError.bind(this));
    }

    private userSuccess(user: IUser) {
        this.setState({loading: false, user: user})
    }

    private userError(err: any) {
    }

    getContent(){
        let strings= StringDistributor.get().profile

        if(this.state.loading) {
            return(
                <div style={{display: "flex", flexDirection: "column", width: "90%"}}>
                    <PageTitle title={strings.profile}/>
                 
                    <Skeleton animation="wave" style={{height: "40px"}}/>
                    {/* <Skeleton animation="wave" style={{height: "40px"}}/>
                    <Skeleton animation="wave" style={{height: "40px"}}/> */}

                    <Skeleton 
                        variant="rectangular" 
                        style={{height: "90px"}}
                        height="24em"  
                        animation="wave"  
                    />
                </div>
            )
        }

        if(this.state.user?.role === 2) {
            return (
                <SachbearbeiterProfile user={this.state.user}/>
            )
        } else if(this.state.user?.role === 3) {
            return (
                <SchulleiterProfile user={this.state.user}/>
            )
        } else if(this.state.user?.role === 4) {
            return (
                <ApplicantProfile user={this.state.user}/>
            )
        }
    }
}
export default withStyles(useStyles, {withTheme : true})(Profile);