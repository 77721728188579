import * as React from 'react';
import ILayoutProps from '../../interfaces/components/Layout/ILayoutProps';
import { withStyles} from '@mui/styles';
import ThemeService from '../../services/ThemeService';

//const imgBackground = require("../../../components/assets/controller.jpg");

const useStyles = (theme: any) => ({
    heightFallback1: {
        height: '100%'
    },
    heightFallback2: {
        height: '100vh'
    },
    App:{
        height: 'calc(var(--vh, 1vh) * 100)',
        display: 'flex',
        'flex-flow': 'column',
        
        backgroundSize: "cover",
        width: '100vw',
        backgroundColor: ThemeService.getAlphaColor(theme.palette.secondary.main, 1)
    }
});

class Background extends React.Component<ILayoutProps>{
    constructor(props: ILayoutProps){
        super(props);
    }

    render(){
        let {classes} = this.props;

        return(
            <div className={classes.App + " " + classes.heightFallback2 + " " + classes.heightFallback1}>
                {this.props.children}
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(Background);