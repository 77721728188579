import React, { Component } from "react";
import { withStyles} from '@mui/styles';
import IMainPageProps from "../../interfaces/components/Public/Main/IMainPageProps";
import IMainPageState from "../../interfaces/components/Public/Main/IMainPageState";
import MainLayout from "../Layout/MainLayout";

export default class MainPage <T extends IMainPageProps, S> extends Component<T, S> {
    
    protected getContent() : any{
        return (<div></div>);
    }

    render(){
        return(
            <MainLayout>
                <div  style={{overflowY: "auto"}}>
                    {this.getContent()}
                </div>
            </MainLayout>
        )
    }
}