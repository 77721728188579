import React from "react";
import { styled, withStyles} from '@mui/styles';
import { TextField, Typography } from "@mui/material";

import IEditBlockProps from "../../../interfaces/components/Layout/Elements/IEditBlockProps";
import StringDistributor from "../../../util/StringDistributor";
import IEditBlockState from "../../../interfaces/components/Layout/Elements/IEditBlockState";
import INewValueField from "../../../models/Communication/Field/INewValueField";


const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        padding: "1.25em",
        marginTop: "10px",
        height: "65vh",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "600",
        marginRight: "1em"
    },
    mainTitle: {
        color: theme.palette.primary.main,
    },
    listItem: {
        display: "flex",
        flexDirection: "row" as 'row',
        width: "100%"
    }
}); 

class EditBlock extends React.Component<IEditBlockProps, IEditBlockState> {
    constructor(props: IEditBlockProps) {
        super(props);

        this.state = {
            value: ""
        }
    }

    componentDidMount(): void {
        if(this.props.field?.value !== null) {
            this.setState({value: this.props.field?.value.value})
        } else {
            this.setState({value: this.props.value})
        }
    }

    private changeValue = (event: any) => {
        
        let newField: INewValueField = {
            field: this.props.field,
            newValue: event
        }
        if(this.props.editFunction !== undefined) {
            this.props.editFunction(newField)
        }
        this.setState({value: event});
    };

    render(){
        if(this.props.field !== null && this.props.field !== undefined) {
            if(this.props.field.value !== undefined && this.props.field.value !== null) {
                if(this.props.edit) {
                    return(
                        <div>
                            <TextField 
                                style={{...this.props.style}}
                                label={this.props.name}
                                value={this.state.value}
                                required={this.props.field.mandatory}
                                size="small"
                                onChange={(e) => {this.changeValue(e.target.value)}}
                                sx={{width: "75%"}}
                            >
                            </TextField>
                        </div>
                    )
                }
                else {
                    if(this.props.title) {
                        return(
                            <div>
                                <Typography style={{fontSize: "1.25em", ...this.props.style}}>
                                    {this.props.value}
                                </Typography>
                            </div>
                        )
                    } else {
                        return(
                            <div>
                                <Typography style={{...this.props.style}}>
                                    {this.props.value}
                                </Typography>
                            </div>
                        )
                    }
                }
            } else { // Field has no previous value set
                return(
                    <div>
                        <TextField
                            style={{...this.props.style}}
                            label={this.props.name}
                            value={this.state.value}
                            size="small"
                            disabled={!this.props.edit}
                            onChange={(e) => {this.changeValue(e.target.value)}}
                            sx={{width: "75%"}}
                        >
                        </TextField>
                    </div>
                )
            }
        } else {
            return(
                <div>
                    <Typography style={{...this.props.style}}>
                                {"-"}
                    </Typography>
                </div>
            )
        }
    }
}
export default withStyles(useStyles, {withTheme : true})(EditBlock);