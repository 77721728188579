
import React from "react";
import { withStyles} from '@mui/styles';
import { Button, Checkbox, Divider, Grid, Paper, Typography } from "@mui/material";
import IJobCardProps from "../../../interfaces/components/Layout/Elements/IJobCardProps";
import { Redirect } from "react-router-dom";
import IPosting from "../../../models/Communication/IPosting";
import JobDetail from "../../Public/Main/JobDetail";
import { PermissionService } from "../../../services/PermissionService";

const useStyles = (theme: any) => ({
    paper: {
        marginBottom: "0.75em",
        padding: PermissionService.checkPermission("posting.apply") ? "0.33em" : "1em",
        paddingLeft: "1em"
    },
    gridContainer: {
        display: "flex",
        flexDirection: "row" as 'row'
    },
    gridItem: {
        display: "flex",
        justifyContent: "flex-start" as 'flex-start',
        padding: "auto"
    },
    title: {
        color: theme.palette.primary.main
    }

}); 

class JobCard extends React.Component<IJobCardProps, any> {
    constructor(props: IJobCardProps) {
        super(props);

        this.state = {

        }
    }


    render(){
        let {classes} = this.props;

        return(
            <div tabIndex={this.props.tabIndex}>
                <Paper 
                        sx={{':hover': {boxShadow: 4, cursor: "pointer"},}} 
                    className={classes.paper}
                >
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid  alignItems="center" className={classes.gridItem} onClick={() => this.props.clickDetail()} item 
                            xs={3} md={2} sm={2} lg={1}>
                            <Typography noWrap >{this.props.announcement.identificationNumber}</Typography>
                        </Grid>
                        <Grid className={classes.gridItem} onClick={() => this.props.clickDetail()} item xs={1}>
                            <Divider flexItem={true} orientation="vertical"></Divider>
                        </Grid>
                        <Grid 
                            alignItems="center"
                            className={classes.gridItem} 
                            onClick={() => this.props.clickDetail()} 
                            item 
                            xs={5} md={7} sm={7} lg={8}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start"
                            }}
                        >
                            <Typography noWrap className={classes.title}>{this.props.announcement.title}</Typography>
                        </Grid>
                        <Grid className={classes.gridItem} onClick={() => this.props.clickDetail()} item xs={1}>
                            {PermissionService.checkPermission("posting.apply") && 
                                <Divider flexItem={true} orientation="vertical"></Divider>}
                        </Grid>
                        <Grid className={classes.gridItem} item xs={1}>
                            {PermissionService.checkPermission("posting.apply") && 
                                <Checkbox
                                    //checked={this.props.checked}
                                    disabled={this.props.applied}
                                    defaultChecked={this.props.applied}
                                    onClick={() => this.props.checkBox()}
                                    style={{
                                        color: "#e5342a"
                                    }}
                                />}
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(JobCard);