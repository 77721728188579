
import React from "react";
import { withStyles} from '@mui/styles';
import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Skeleton, Paper, TextField, Typography } from "@mui/material";

import ICreatePostingProps from "../../interfaces/components/Closed/ICreatePostingProps";
import ICreatePostingState from "../../interfaces/components/Closed/ICreatePostingState";
import MainPage from "../Public/MainPage";
import StringDistributor from "../../util/StringDistributor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MUIRichTextEditor from 'mui-rte'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PageTitle from "../Layout/Elements/PageTitle";
import moment from "moment";
import { convertToRaw } from 'draft-js'
import PostingService from "../../services/PostingService";
import IPosting from "../../models/Communication/IPosting";
import MessageBox from "../Layout/Elements/MessageBox";
import TemplateDialog from "../Layout/Elements/TemplateDialog";
import SubjectService from "../../services/SubjectService";
import ISubject from "../../models/Communication/ISubject";
import ConfirmDialog from "../Layout/Elements/ConfirmDialog";

const useStyles = (theme: any) => ({
    paper: {
        padding: "20px",
        height: "95%",
        maxHeight: "66vh",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "24px"
    },
    typo: {
        color: theme.palette.primary.main,
        fontWeight: 600
    }
}); 

class CreatePosting extends MainPage<ICreatePostingProps, ICreatePostingState> {
    constructor(props: ICreatePostingProps) {
        super(props);

        this.state = {
            openTemplateDialog: false,
            openConfirmDialog: false,
            openMessageBoxSuccess: false,
            openMessageBoxError: false,
            deadline: "",
            kennnummer: "",
            title: "",
            infoText: "",
            req: "",
            locked: false,
            changeInfoText: true,
            changeReq: true,
            subject1: "",
            subject2: "",
            subjects: [],
            error: false,
            loading: true,
            newBody: "",
            newReq: "",
            errorMessage: "",
            showErrorMessage: false
        }
    }

    componentDidMount(): void {
        SubjectService.get(this.getSubjectSuccess.bind(this), this.getSubjectError.bind(this));
    }

    private getSubjectSuccess(ans: ISubject[]) {
        this.setState({subjects: ans, loading: false})
    }

    private getSubjectError(err: any) {
        this.setState({error: true})
    }

    private setDate(date: any) {
        let onlyDate = date.toDate();
        this.setState({deadline: onlyDate});
    }

    private onChangeID(v: string) {
        this.setState({kennnummer: v})
    }

    private onChangeTitle(v: string) {
        this.setState({title: v})
    }

    private changesubject1 = (event: any) => {
        this.setState({subject1: event});
    };

    private changesubject2 = (event: any) => {
        this.setState({subject2: event});
    };

    private onChangeReq(v: any) {
        let text = JSON.stringify(v)
        this.textReq(text)
        if(this.state.changeReq) {
            this.setState({req: text, changeReq: false})
        }
    }

    private textReq(v: any) {
        this.setState({newReq: v})
    }

    private onChangeInfoText(v: any) {
        let text = JSON.stringify(v)
        this.textBackupInfo(text)

        if(this.state.changeInfoText) {
            this.setState({infoText: text, changeInfoText: false})
        }

    }

    private textBackupInfo(v: any) {
        this.setState({newBody: v})
    }

    private onChangeLocked() {
        let v = this.state.locked;
        this.setState({locked: !v})
    }

    private openConfirmDialog() {
        if(this.state.kennnummer !== "" || this.state.title !== "" || this.state.subject1 !== "" || this.state.subject2 !== "" || this.state.deadline !== "") {
            this.setState({openConfirmDialog: true, changeInfoText: true, changeReq: true})
        } else {
            this.setState({showErrorMessage: true, errorMessage: "Bitte füllen Sie alle notwendigen Felder aus"})
        }
    }

    private createPosting() {
        let posting: IPosting = {
            identificationNumber: this.state.kennnummer,
            title: this.state.title,
            body: this.state.newBody,
            deadline: this.state.deadline,
            locked: this.state.locked,
            requirements: this.state.newReq,
            subject1: this.state.subject1,
            subject2: this.state.subject2
        }
        PostingService.create(this.postingCreateSuccess.bind(this), this.postingCreateError.bind(this), posting);
    }

    private postingCreateSuccess(data: any) {
        this.setState({
            deadline: "",
            kennnummer: "",
            title: "",
            infoText: "",
            req: "",
            locked: false,
            openMessageBoxSuccess: true,
            changeInfoText: false, changeReq: false
        })
    }

    private postingCreateError(err: any) {
        this.setState({openMessageBoxError: true, changeInfoText: false, changeReq: false})
    }

    private handleDialogClose() {
        this.setState({openMessageBoxSuccess: false, openMessageBoxError: false, openTemplateDialog: false, openConfirmDialog: false, showErrorMessage: false})
    }

    private openTemplateDialog() {
        this.setState({openTemplateDialog: true})
    }

    private applyTemplate(temp: IPosting) {
        this.changesubject1(temp.subject1)
        this.changesubject2(temp.subject2)


        this.setState({
            kennnummer: "",
            title: temp.title,
            infoText: temp.body,
            req: temp.requirements,
            deadline: temp.deadline,
            locked: temp.locked,
            openTemplateDialog: false,
            changeInfoText: true,
            changeReq: true,
            subject1: temp.subject1,
            subject2: temp.subject2
        })
    }

    getContent(){
        let {classes} = this.props;
        let strings = StringDistributor.get().createPosting

        if(this.state.loading) {
            return(
                <div style={{display: "flex", flexDirection: "column", width: "90%"}}>
                    <PageTitle title={strings.createNew}/>
                
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                </div>
            )
        }

        return(
            <div style={{height: "75vh"}}>
                <PageTitle title={strings.createNew}/>
                <div style={{width: "90%"}}>
                    <Paper className={classes.paper}>
                        <Grid style={{display: "flex"}} container spacing={2}>
                            <Grid item xs={12}>
                                <Button 
                                    onClick={() => this.openTemplateDialog()}
                                    sx={{
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        "&:hover": { 
                                            backgroundColor: "#c75a54"
                                        },
                                        "&:disabled": {
                                            backgroundColor: "#ffffff"
                                        }
                                    }}
                                >
                                    {strings.useTemplate}
                                </Button>
                            </Grid>
                            <Divider flexItem={true} orientation="horizontal"></Divider>
                            <Grid item xs={6}>
                                <TextField 
                                    label={strings.identificationNumber} 
                                    sx={{
                                        color: "black",
                                        width: "60%"
                                    }}
                                    size="small" 
                                    required
                                    value={this.state.kennnummer}
                                    onChange={(e) => {this.onChangeID(e.target.value)}}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                               <TextField 
                                    label={strings.title} 
                                    sx={{
                                        color: "black",
                                        width: "60%"
                                    }}
                                    size="small" 
                                    required
                                    value={this.state.title}
                                    onChange={(e) => {this.onChangeTitle(e.target.value)}}
                                >
                                </TextField>
                            </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        label={strings.subject + "1 "} 
                                        sx={{
                                            color: "black",
                                            width: "60%"
                                        }}
                                        size="small" 
                                        required
                                        select
                                        value={this.state.subject1}
                                        onChange={(e) => {this.changesubject1(e.target.value)}}
                                    >
                                        {
                                            this.state.subjects.map((sub: ISubject) => {
                                                return(
                                                    <MenuItem key={sub.id} value={sub.displayName}>{sub.displayName}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField 
                                        label={strings.subject + "2 "} 
                                        sx={{
                                            color: "black",
                                            width: "60%"
                                        }}
                                        size="small" 
                                        required
                                        select
                                        value={this.state.subject2}
                                        onChange={(e) => {this.changesubject2(e.target.value)}}
                                    >
                                        {
                                            this.state.subjects.map((sub) => {
                                                return(
                                                    <MenuItem key={sub.id + "2"} value={sub.displayName}>{sub.displayName}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.typo}>{strings.infoText + ": "}</Typography>
                                <MUIRichTextEditor 
                                    onChange={(e) => {this.onChangeInfoText(convertToRaw(e.getCurrentContent()))}}
                                    toolbarButtonSize="small"
                                    defaultValue={this.state.infoText}
                                    controls={
                                        [
                                            "title", 
                                            "bold", 
                                            "italic", 
                                            "underline", 
                                            "strikethrough", 
                                            "highlight", 
                                            "undo", 
                                            "redo",
                                            "numberList",
                                            "bulletList",
                                            "quote",
                                            "code",
                                            "clear"
                                        ]
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.typo}>{strings.requirements + ": "}</Typography>
                                <MUIRichTextEditor 
                                    onChange={(e) => {this.onChangeReq(convertToRaw(e.getCurrentContent()))}}
                                    toolbarButtonSize="small"
                                    defaultValue={this.state.req}
                                    controls={
                                        [
                                            "title", 
                                            "bold", 
                                            "italic", 
                                            "underline", 
                                            "strikethrough", 
                                            "highlight", 
                                            "undo", 
                                            "redo",
                                            "numberList",
                                            "bulletList",
                                            "quote",
                                            "code",
                                            "clear"
                                        ]
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("de")}>
                                    <DatePicker
                                        label={strings.deadline}
                                        value={this.state.deadline}
                                        onChange={(newValue) => {
                                                this.setDate(newValue);
                                            }}
                                        renderInput={(params) => <TextField {...params} />}
                                        inputFormat="DD.MM.YYYY"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel 
                                    control={<Checkbox 
                                                value={this.state.locked}
                                                defaultChecked={this.state.locked}
                                                onClick={() => this.onChangeLocked()}
                                                style={{
                                                    color: "#e5342a"
                                                }}
                                            />
                                            } 
                                    label={strings.locked}/>
                            </Grid>
                            
                        </Grid>
                    </Paper>

                    <Button
                        onClick={() => this.openConfirmDialog()}
                        sx={{
                            float: "right",
                            color: "#ffffff",
                            marginTop: "1em",
                            backgroundColor: "#e5342a",
                            "&:hover": { 
                                backgroundColor: "#c75a54"
                            },
                            "&:disabled": {
                                backgroundColor: "#ffffff"
                            }
                        }}
                    >
                        {strings.create}
                    </Button>
                </div>

                <ConfirmDialog open={this.state.openConfirmDialog}
                    title={"Ausschreibung erstellen?"}
                    message={"Möchten Sie diese Ausschreibung erstellen?"}
                    acceptButtonText={"OK"}
                    declineButtonText={"Abbruch"}
                    decline={this.handleDialogClose.bind(this)}
                    accept={this.createPosting.bind(this)}/>
                
                <MessageBox open={this.state.openMessageBoxSuccess}
                            title={strings.suc}
                            message={strings.success}
                            buttonText={"OK"} 
                            onClose={this.handleDialogClose.bind(this)}/>

                <MessageBox open={this.state.showErrorMessage}
                            title={"Nicht vollständig"}
                            message={this.state.errorMessage}
                            buttonText={"OK"} 
                            onClose={this.handleDialogClose.bind(this)}/>

                <MessageBox open={this.state.openMessageBoxError}
                            title={strings.err}
                            message={strings.error}
                            buttonText={"OK"} 
                            onClose={this.handleDialogClose.bind(this)}/>

                <TemplateDialog open={this.state.openTemplateDialog}
                            title={strings.template}
                            message={""}
                            acceptButtonText={"OK"}
                            declineButtonText={"Abbruch"}
                            decline={this.handleDialogClose.bind(this)}
                            accept={this.applyTemplate.bind(this)}/>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(CreatePosting);