import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import CorporateIdentityService from './services/ThemeService';
import { ThemeProvider } from '@mui/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ThemeService from './services/ThemeService';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

async function start(){
  await ThemeService.get().init();

  let theme = ThemeService.get().getTheme();

  document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);

  window.onresize = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
  }

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </ThemeProvider >
    ,document.getElementById('root')
  );
}

start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.unregister();
