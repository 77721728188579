import React from "react";

import { styled, StylesProvider, withStyles} from '@mui/styles';
import ILButtonProps from "../../../../interfaces/components/Layout/Elements/Pieces/ILButtonProps";
import { Button, ButtonProps } from "@mui/material";

// const LButton = styled(Button)<ButtonProps>(({ theme }) => ({
//     color: "#ffffff",
//     backgroundColor: theme.palette.primary.main,
//     "&:hover": { 
//         backgroundColor: "#eb7771"
//     }
// }));

const useStyles = (theme: any) => ({
});

class LButton extends React.Component<ILButtonProps, any> {
    constructor(props: ILButtonProps) {
        super(props);
        this.state = {
        
        }
    }
    render() {
        let {classes} = this.props;
        return(
            <Button
                onClick={() => this.props.onClick()}
                sx={{
                    ...this.props.sx,
                    color: "#ffffff",
                    backgroundColor: "#e5342a",
                    "&:hover": { 
                        backgroundColor: "#eb7771"
                    },
                    "&:disabled": {
                        backgroundColor: "#ffffff"
                    }
                }}
                style={{
                    ...this.props.style
                }}
                disabled={(this.props.disabled === undefined) ? false : this.props.disabled}
            >
                {this.props.label}
            </Button>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(LButton);