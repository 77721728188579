import { config } from "../config";
import IParamFunction from "../Functions/IParamFunction";
import IField from "../models/Communication/Field/IField";
import IPasswordChange from "../models/Communication/IPasswordChange";
import IPasswordReset from "../models/Communication/IPasswordReset";
import IUser from "../models/Communication/IUser";
import ICreateUser from "../models/ICreateUser";
import AxiosHelper from "../util/AxiosHelper";
import AuthService from "./AuthService";

export default class UserService {
    public static get(success: IParamFunction<any>, error: IParamFunction<object>){
        //console.log(AuthService.get().getToken());
        AxiosHelper.get().get(config.api + config.restExtension + "/user/")
        .then(response => {
            let data = response.data as IUser
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static create(success: IParamFunction<any>, error: IParamFunction<object>, user: ICreateUser){
        AxiosHelper.get().put(config.api + config.restExtension + "/user/", user,"application/json;charset=UTF-8")
        .then(response => {
            let data = response.data as IUser
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static changePassword(success: IParamFunction<any>, error: IParamFunction<object>, newPass: IPasswordChange) {
        AxiosHelper.get().patch(config.api + config.restExtension + "/user/changePassword", newPass)
        .then(response => {
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static markUser(success: IParamFunction<any>, error: IParamFunction<object>, userId: number) {
        AxiosHelper.get().put(config.api + config.restExtension + "/user/mark", userId, "application/json")
        .then(response => {
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static getApplicants(success: IParamFunction<any>, error: IParamFunction<object>) {
        AxiosHelper.get().get(config.api + config.restExtension + "/user/applicants")
        .then(response => {
            let data = response.data as IUser[] 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static resetPasswortLink(success: IParamFunction<any>, error: IParamFunction<object>, email: string) {
        AxiosHelper.get().put(config.api + config.restExtension + "/user/resetPasswordLink", email, "application/json")
        .then(response => {
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static resetPasswort(success: IParamFunction<any>, error: IParamFunction<object>, reset: IPasswordReset) {
        AxiosHelper.get().patch(config.api + config.restExtension + "/user/resetPassword", reset)
        .then(response => {
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static getOther(success: IParamFunction<any>, error: IParamFunction<object>, id: number){
        //console.log(AuthService.get().getToken());
        AxiosHelper.get().put(config.api + config.restExtension + "/user/other", id, "")
        .then(response => {
            let data = response.data as IUser
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static editFields(success: IParamFunction<any>, error: IParamFunction<object>, fields: IField[]){
        AxiosHelper.get().put(config.api + config.restExtension + "/user/fields", fields, "")
        .then(response => {
            let data = response.data
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static switchOffline(success: IParamFunction<any>, error: IParamFunction<object>, online: boolean) {
        let json = JSON.stringify(online)
        AxiosHelper.get().patch(config.api + config.restExtension + "/user/online", json)
        .then(response => {
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }
}