import React from "react";
import { withStyles} from '@mui/styles';
import MainPage from "../Public/MainPage";

import { Button, buttonClasses, Grid, IconButton, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import IDocumentsProps from "../../interfaces/components/Closed/IDocumentsProps";
import IDocumentsState from "../../interfaces/components/Closed/IDocumentsState";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IDocument from "../../models/Communication/IDocument";
import StringDistributor from "../../util/StringDistributor";
import PageTitle from "../Layout/Elements/PageTitle";
import LButton from "../Layout/Elements/Pieces/LButton";
import ConfirmDialog from "../Layout/Elements/ConfirmDialog";
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = (theme: any) => ({
    paper: {
        width: "80%",
        height: "4em",
        padding: "5px",
        paddingTop: "12px",
        marginBottom: "0.65em",
        marginLeft: "5em"
    },
    gridItem: {
        paddingLeft: "2em"
    },
    uploadDiv: {
        width: "50%",
        height: "10vh",
        border: "2px dashed #94A3B8",
        display: "flex",
        justifyContent: "center"
    }
}); 

const documents: IDocument[] = [
    {
        id: 1,
        file: "Base64IGuess",
        name: "Lebenslauf"
    },
    {
        id: 2,
        file: "Base64IGuess",
        name: "Erste Staatsprüfung"
    },
    {
        id: 3,
        file: "Base64IGuess",
        name: "Anschreiben"
    },
]


class Documents extends MainPage<IDocumentsProps, IDocumentsState> {
    constructor(props: IDocumentsProps) {
        super(props);

        this.state = {
            openDialog: false,
            addFile: false,
            fileList: [],
            tempFile: "",
            mobile: (window.innerWidth <= 780),
            openMenu: false,
            menuAnchor: undefined,
            deleteIndex: 0
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    
    resize() {
        // this.setState({mobile: window.innerWidth <= 760});
        let currentHideNav = (window.innerWidth <= 780);
        if (currentHideNav !== this.state.mobile) {
            this.setState({mobile: currentHideNav});
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    private openMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({
                openMenu: true,
                menuAnchor: event.currentTarget
        });
    }

    private closeMenu() {
        this.setState({openMenu: false})
    }

    private deleteDocument(index: number) {
        this.setState({openDialog: true, deleteIndex: index})
    }

    private downloadDocument(id: number) {
    }

    private editDocument(id: number) {
    }

    private addDocuments() {
        this.setState({addFile: true})
    }

    private handleAccept() {
        let docs = this.state.fileList;
        docs = docs.splice(this.state.deleteIndex, 1);
        this.setState({openDialog: false, fileList: docs})
    }

    private handleCancel() {
        this.setState({openDialog: false})
    }

    private fileChange(file: any) {
        this.setState({tempFile: file.target.files[0]})
    }

    private uploadDocument() {
        let files = this.state.fileList;
        files.push(this.state.tempFile)
        this.setState({addFile: false, fileList: files, tempFile: ""})
    }

    getContent(){
        let {classes} = this.props;

        let strings = StringDistributor.get().documents;

        return(
            <div>
                <Grid container spacing={2} justifyItems="center" style={{width: "70vw"}}>
                    <Grid item xs={12}>
                        <PageTitle title={strings.yourDocuments}/>
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    {
                        this.state.fileList.map((doc, index) => {
                            return (
                                <Paper key={doc.id} className={classes.paper}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={0} sm={2} md={2}>
                                            <PictureAsPdfIcon style={{color: "#e5342a", marginLeft: "1em"}}/>
                                        </Grid>
                                        <Grid item xs={5} sm={8} md={6} >
                                            <Typography noWrap style={{fontSize: "18px"}}>{doc.name}</Typography>
                                        </Grid>
                                        <Grid item xs={1} sm={2} md={3}>
                                            {this.state.mobile && 
                                                <div>
                                                    <IconButton aria-label="bearbeiten" 
                                                        onClick={event => this.openMenu(event)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={this.state.menuAnchor}
                                                        open={this.state.openMenu}
                                                        onClose={() => this.closeMenu()}
                                                        MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => {this.editDocument(doc.id)}}>{"Bearbeiten"}</MenuItem>
                                                        <MenuItem onClick={() => {this.downloadDocument(doc.id)}}>{"Herunterladen"}</MenuItem>
                                                        <MenuItem onClick={() => {this.deleteDocument(index)}}>{"Löschen"}</MenuItem>
                                                    </Menu>
                                                </div>
                                            }
                                            {!this.state.mobile &&
                                                <Stack direction="row" spacing={1}>
                                                    <IconButton aria-label="bearbeiten" 
                                                        onClick={() => {this.editDocument(doc.id)}}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="herunterladen" 
                                                        onClick={() => {this.downloadDocument(doc.id)}}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="löschen" 
                                                        onClick={() => {this.deleteDocument(doc.id)}}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Stack>
                                            }
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )
                        })
                    } 
                    </Grid>
                    <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                        {this.state.addFile &&
                            <div className={classes.uploadDiv}>
                                <input style={{margin: "auto"}} type="file" onChange={(file) => this.fileChange(file)}></input>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} style={{display: "flex",  justifyContent: "center"}}>
                        {/* <Button
                            sx={{
                                backgroundColor: "#e5342a",
                                color: "#ffffff",
                                marginLeft: "27em"
                            }}
                        >
                            {strings.addMore}
                        </Button> */}
                        {/* <LButton 
                            label={strings.addMore} 
                            onClick={() => {this.addDocuments()}}
                        /> */}
                        {!this.state.addFile && 
                            <IconButton
                                sx={{
                                    height:"30px",
                                    width: "30px",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    "&:hover": { 
                                        backgroundColor: "#c75a54"
                                    }
                                }}
                                onClick={() => this.addDocuments()}
                            >
                                <AddIcon/>
                            </IconButton>
                        }
                        {this.state.addFile &&
                            <Button
                                sx={{
                                    marginTop: "1em",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    "&:hover": {
                                        backgroundColor: "#eb7771"
                                    }
                                }} 
                                disabled={this.state.tempFile === ""}
                                onClick={() => this.uploadDocument()}>
                                {"Hochladen"}
                            </Button>
                        }
                    </Grid>
                </Grid>

                <ConfirmDialog open={this.state.openDialog}
                    title={"Löschen"}
                    message={"Möchten Sie das Dokument löschen?"}
                    acceptButtonText={"OK"}
                    declineButtonText={"Abbruch"}
                    decline={this.handleCancel.bind(this)}
                    accept={this.handleAccept.bind(this)}/>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(Documents);