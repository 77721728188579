
import React from "react";
import { withStyles} from '@mui/styles';
import { Box, Button, Grid, IconButton, Paper, Tab, Tabs,   Typography } from "@mui/material";
import { Link, Route, Switch } from "react-router-dom";

import IReportingProps from "../../interfaces/components/Closed/IReportingProps";
import IReportingState from "../../interfaces/components/Closed/IReportingState";
import MainPage from "../Public/MainPage";
import StringDistributor from "../../util/StringDistributor";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PageTitle from "../Layout/Elements/PageTitle";
import LIconButton from "../Layout/Elements/Pieces/LIconButton";
import { config } from "../../config";

import ReactCSSTransitionGroup from "react-transition-group";
import AngebotNachfrage from "./Reporting/AngebotNachfrage";
import Lage from "./Reporting/Lage";

const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        padding: "20px",
        display: "flex",
        flexDirection: "column" as 'column',
        justifyContent: "flex-start"
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "24px"
    }
}); 


class Reporting extends MainPage<IReportingProps, IReportingState> {
    constructor(props: IReportingProps) {
        super(props);

        this.state = {
            tab: 0
        }
    }

    private AN() {
        console.log("AN")
    }

    private lage() {
        console.log("lage")
    }

    private handleTabChange(e: any) {
        this.setState({tab: e})
    }

    handleChange = (event: any, value: any) => {
        this.setState({tab: value });
    };

    getContent(){
        let {classes} = this.props;
        let strings = StringDistributor.get().reporting

        return(
            <div>
                <PageTitle title={strings.reporting}/>
                <Paper className={classes.paper}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={this.state.tab} onChange={this.handleChange} aria-label="basic tabs example">
                                <Tab label="Angebot & Nachfrage" />
                                <Tab label="Bewerbundslage" />
                            </Tabs>
                        </Box>
                        {this.state.tab === 0  &&
                            <AngebotNachfrage/>
                        }
                        {this.state.tab === 1  &&
                            <Lage/>
                        }
                </Paper>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(Reporting);