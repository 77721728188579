import * as React from 'react';
import {withStyles} from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';
import CorporateIdentityService from '../../services/ThemeService';
import { Typography } from '@mui/material';
import StringDistributor from '../../util/StringDistributor';

const useStyles = (theme: any) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: CorporateIdentityService.getAlphaColor(theme.palette.primary.main, 0.3),
        width: '100vw',
        height: '100vh',
        'flex-flow': 'column'
    },
    icon: {
        color: 'red',
        width: theme.spacing(8),
        height: theme.spacing(8)
    }
});

class ErrorScreen extends React.Component<any>{
    constructor(props: any){
        super(props);
    }

    render(){

        let {classes} = this.props;

        let strings = StringDistributor.get().errorScreen

        return (
            <div className={classes.modal}>
                <ErrorIcon className={classes.icon}/>
                <Typography variant="h4">
                    {strings.title}
                </Typography>
                <Typography variant="h5">
                    {strings.loadingText}
                </Typography>
            </div>
        );
    }
}
export default withStyles(useStyles, {withTheme: true})(ErrorScreen)