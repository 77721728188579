import React from "react";
import { withStyles} from '@mui/styles';

import {Checkbox, Skeleton, Accordion, AccordionDetails, AccordionSummary, Button, Divider, FormControl, FormControlLabel, CircularProgress, FormHelperText, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StringDistributor from "../../util/StringDistributor";
import MainPage from "../Public/MainPage";
import IVerwaltungProps from "../../interfaces/components/Closed/IVerwaltungProps";
import IVerwaltungState from "../../interfaces/components/Closed/IVerwaltungState";
import PageTitle from "../Layout/Elements/PageTitle";

import DeleteIcon from '@mui/icons-material/Delete';
import LButton from "../Layout/Elements/Pieces/LButton";
import AuthService from "../../services/AuthService";
import { PermissionService } from "../../services/PermissionService";
import PermissionErrorScreen from "../Layout/PermissionErrorScreen";
import RoleService from "../../services/RoleService";
import IRole from "../../models/Communication/IRole";
import UserService from "../../services/UserService";
import ICreateUser from "../../models/ICreateUser";
import { CheckBox } from "@mui/icons-material";
import SubjectService from "../../services/SubjectService";
import ISubject from "../../models/Communication/ISubject";
import ArrayHelper from "../../util/ArrayHelper";
import IPermission from "../../models/Communication/IPermission";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import IRoleModify from "../../models/Communication/IRoleModify";
import IRolePermission from "../../models/Communication/IRolePermission";

const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        height: "100%",
        padding: "20px",
        overflow: 'scroll' as "scroll"
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "24px"
    }
});

interface Fields {
    1: string;
    2: string;
}

const permissionNeeded = "administration.access";
const rolePer = "role.create"

class Verwaltung extends MainPage<IVerwaltungProps, IVerwaltungState> {
    constructor(props: IVerwaltungProps) {
        super(props);

        this.state = {
            loading: true,
            permissionCheck: true,
            userEmail: "",
            userRole: 2,
            userFirstName: "",
            userLastName: "",
            rolesToAssign: [],
            roleToEdit: 2,
            subjectName: "",
            subjects: [],
            allPermissions: [],
            roleEditPermissions: [],
            addNewRole: false,
            newRoleName: "",
            newRolePermissions: [],
            newRole: undefined,
            permissionsChecked: [],
            usercreated: false,
            usercreateProgress: false
        }
    }

    componentDidMount() {
        if(PermissionService.checkPermission(permissionNeeded)) {
            this.setState({permissionCheck: false})
        }
        
        //only if Admin
        if(AuthService.get().getRole() === 1) {
           RoleService.getAllPermissions(this.getAllPermSuccess.bind(this), this.getAllPermError.bind(this))
        }

        RoleService.getAll(this.getRolesSuccess.bind(this), this.getRoleError.bind(this))

        SubjectService.get(this.getSubjectsSuccess.bind(this), this.getSubjectsError.bind(this))
    }

    private getAllPermSuccess(ans: IPermission[]) {
        this.setState({allPermissions: ans})
    }

    private getAllPermError(err: any) {
    }

    private getSubjectsSuccess(ans: ISubject[]) {
        this.setState({subjects: ans, loading: false})
    }

    private getSubjectsError(err: any) {
    }

    private getRolesSuccess(ans: Array<IRole>) {
        let arr : IRole[] = [];

        for(let role of ans) {
            if(role.name !== "Admin" && role.name !== "Bewerber")
                arr.push(role)
        }

        //set perms from state.roleToEdit into roleEditPermissions

        this.setState({rolesToAssign: arr, 
                        loading: false,
                    })

        // console.log(
        //     this.state.rolesToAssign[
        //         ArrayHelper.indexOf(this.state.rolesToAssign, (role) => role.id === this.state.roleToEdit)
        //     ].permissions
        // )
    }

    private getRoleError(err: any) {
    }

    private deleteSubject(subject: any) {
        SubjectService.delete(this.subjectDeleteSuccess.bind(this), this.subjectDeleteError.bind(this), subject.id)
    }

    private subjectDeleteSuccess(ans: any) {
        SubjectService.get(this.getSubjectsSuccess.bind(this), this.getSubjectsError.bind(this))
    }

    private subjectDeleteError(err: any) {
    }

    private addUser() {
        let fields : Fields = {
            1: this.state.userFirstName,
            2: this.state.userLastName
        }

        let createUser: ICreateUser = {
            email: this.state.userEmail,
            fields: fields,
            role: this.state.userRole
        }

        UserService.create(this.userCreateSuccess.bind(this), this.userCreateError.bind(this), createUser)

        this.setState({usercreateProgress: true})
    }

    private createSubject() {
        SubjectService.create(this.createSubjectSuccess.bind(this), this.createSubjectError.bind(this), this.state.subjectName)
    }

    private createSubjectSuccess(ans: any) {
        SubjectService.get(this.getSubjectsSuccess.bind(this), this.getSubjectsError.bind(this))
    }

    private createSubjectError(err: any) {
    }

    private userCreateSuccess(ans: any) {
        this.setState({usercreated: true, usercreateProgress: false, userFirstName: "", userLastName: "", userEmail: ""})
    }

    private userCreateError(err: any) {
    }

    private addNewRoleMode() {
        this.setState({addNewRole: true})
    }

    private abortAddNewRoleMode() {
        this.setState({
            addNewRole: false,
            newRoleName: "", 
            newRolePermissions: []
        })
    }

    private changeSelectedRole = (event: any) => {
        this.setState({userRole: event});
    };

    private changeRoleToEdit = (event: any) => {
        this.setState({roleToEdit: event});
    };

    private changeUserFirstName = (event: any) => {
        this.setState({userFirstName: event});
    };

    private changeUserLastName = (event: any) => {
        this.setState({userLastName: event});
    };

    private changeNewUserEmail = (event: any) => {
        this.setState({userEmail: event});
    };

    private changeSubjectName = (event: any) => {
        this.setState({subjectName: event});
    };

    private changeNewRoleName = (event: any) => {
        this.setState({newRoleName: event});
    };

    private changeVariant = (permission: IPermission, event: any) => {
        //find index in list of permissions
        //set variant there
    };

    private clickCheckbox(perm : IPermission) {
        let list = this.state.roleEditPermissions;
        let index = 0; 

        if(ArrayHelper.contains(list, (permission) => permission.permission.value === perm.value)) {
            index = ArrayHelper.indexOf(list, (permission) => permission.permission.value === perm.value)

            list.slice(
                index, 1
            )
        } else {
            list.push(this.state.roleEditPermissions[index]);
        }

        console.log(list)

        this.setState({roleEditPermissions: list})
    }

    private createNewRole() {
        let newRole: IRoleModify = {
            name: this.state.newRoleName,
            description: " ",
            permissions: this.state.newRolePermissions,
        }
    }

    /**
     * deterimenes if the permission has has varint and thus a Dropbox should be rendered
     * @param perm 
     * @returns 
     */
    private hasVariant(perm: IPermission) {
        if(perm.value.includes("{ROLE}")) {
            return true;
        } else {
            return false;
        }
    }

    getContent(){
        let {classes} = this.props;
        let strings = StringDistributor.get().sbVerwaltung;

        if(this.state.permissionCheck) {
            return(<PermissionErrorScreen/>)
        }

        if(this.state.loading) {
            return(
                <div style={{display: "flex", flexDirection: "column", width: "90%"}}>
                    <PageTitle title={strings.administration}/>
                
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                </div>
            )
        }

        return(
            <div>
                <PageTitle title={strings.administration}/>
                <div style={{height: "70vh", overflow: "scroll"}} >
                    <Paper className={classes.paper}>
                        <Grid  container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="fächer hinzufügen">
                                        <Typography
                                            style={{
                                                marginLeft: "1em",
                                                fontWeight: 600,
                                                fontSize: "1.2em",
                                                color: "#e5342a"
                                            }}>
                                                {strings.subject}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            <Grid item xs={6}
                                                style={{
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    justifyContent: "flex-start",
                                                }}
                                            >
                                                <TextField
                                                    label={"Fachbezeichnung"}
                                                    size="small"
                                                    style={{
                                                        marginBottom: "2em",
                                                        width: "80%"
                                                    }}
                                                    onChange={(e) => {this.changeSubjectName(e.target.value)}}
                                                />
                                                {/* <Button
                                                    style={{
                                                        width: "80%"
                                                    }}
                                                >
                                                    {strings.addSubject}
                                                </Button> */}
                                                <div style={{width: "80%"}}>
                                                    <LButton label={strings.addSubject} onClick={() => {this.createSubject()}}/>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <List style={{
                                                    maxHeight: '70%',
                                                    overflow: 'auto',
                                                    backgroundColor: "#F4F9F8",
                                                    borderRadius: "5px"
                                                }}>
                                                    {
                                                        this.state.subjects.map((s) => {
                                                            return(
                                                                <ListItem
                                                                    key={s.id}
                                                                    divider
                                                                    secondaryAction={
                                                                        <IconButton
                                                                            onClick={() => this.deleteSubject(s)}
                                                                            edge="end"
                                                                            aria-label="fach löschen"
                                                                        >
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    }
                                                                >
                                                                    <ListItemText id={s.displayName} primary={s.displayName} />
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="nutzer hinzufügen">
                                        <Typography
                                            style={{
                                                marginLeft: "1em",
                                                fontWeight: 600,
                                                fontSize: "1.2em",
                                                color: "#e5342a"
                                            }}>
                                                {strings.user}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <FormControl>
                                                    <TextField
                                                        label={strings.email}
                                                        value={this.state.userEmail}
                                                        size="small"
                                                        onChange={(e) => {this.changeNewUserEmail(e.target.value)}}
                                                        sx={{width: "100%"}}
                                                    >
                                                    </TextField>
                                                    <FormHelperText id="component-helper-text">
                                                        {strings.emailHelperText}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl>
                                                        <TextField
                                                            label={"Vorname"}
                                                            value={this.state.userFirstName}
                                                            size="small"
                                                            onChange={(e) => {this.changeUserFirstName(e.target.value)}}
                                                            sx={{width: "100%"}}
                                                        >
                                                        </TextField>
                                                        <FormHelperText id="component-helper-text">
                                                            {"Vorname des neuen Nutzers eingeben"}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl>
                                                        <TextField
                                                            label={"Nachname"}
                                                            value={this.state.userLastName}
                                                            size="small"
                                                            onChange={(e) => {this.changeUserLastName(e.target.value)}}
                                                            sx={{width: "100%"}}
                                                        >
                                                        </TextField>
                                                        <FormHelperText id="component-helper-text">
                                                            {"Nachname des neuen Nutzers eingeben"}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <TextField
                                                        sx={{
                                                            color: "black",
                                                            width: "110%"
                                                        }}
                                                        select
                                                        label={strings.role}
                                                        value={this.state.userRole}
                                                        size="small"
                                                        onChange={(e) => {this.changeSelectedRole(e.target.value)}}
                                                    >
                                                        {
                                                            this.state.rolesToAssign.map((role) => {
                                                                return(
                                                                    <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                    <FormHelperText id="component-helper-text">
                                                        {strings.selectRole}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {!this.state.usercreateProgress &&
                                                    <LButton sx={{width: "90%"}} label={strings.addUser} onClick={() => {this.addUser()}}/>
                                                }
                                                {this.state.usercreateProgress &&
                                                    <CircularProgress 
                                                        sx={{
                                                            
                                                            color: "#e5342a"
                                                        }}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {/* <Grid item xs={12}> */}
                                {//PermissionService.checkPermission(rolePer) && 

                                    // <Accordion defaultExpanded={true}>
                                    //     <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="fächer hinzufügen">
                                    //         <Typography
                                    //             style={{
                                    //                 marginLeft: "1em",
                                    //                 fontWeight: 600,
                                    //                 fontSize: "1.2em",
                                    //                 color: "#e5342a"
                                    //             }}>
                                    //                 {strings.roles}
                                    //         </Typography>
                                    //     </AccordionSummary>
                                    //     <AccordionDetails>
                                    //         <Grid container spacing={4}>
                                    //             <Grid item xs={8}>
                                    //                 {
                                    //                     !this.state.addNewRole &&
                                    //                     <TextField
                                    //                         sx={{
                                    //                             color: "black",
                                    //                         }}
                                    //                         select
                                    //                         label={strings.role}
                                    //                         value={this.state.roleToEdit}
                                    //                         size="small"
                                    //                         onChange={(e) => {this.changeRoleToEdit(e.target.value)}}
                                    //                     >
                                    //                         {
                                    //                             this.state.rolesToAssign.map((role) => {
                                    //                                 return(
                                    //                                     <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                    //                                 )
                                    //                             })
                                    //                         }
                                    //                     </TextField>
                                    //                 }
                                    //                 {
                                    //                     this.state.addNewRole &&
                                    //                     <div>
                                    //                         <FormControl>
                                    //                             <TextField
                                    //                                 label={"Rollenname"}
                                    //                                 value={this.state.newRoleName}
                                    //                                 size="small"
                                    //                                 onChange={(e) => {this.changeNewRoleName(e.target.value)}}
                                    //                                 sx={{width: "100%"}}
                                    //                             >
                                    //                             </TextField>
                                    //                             <FormHelperText id="component-helper-text">
                                    //                                 {"Geben Sie die Bezeichnung der neuen Rolle ein"}
                                    //                             </FormHelperText>
                                    //                         </FormControl> 
                                    //                         <Tooltip title="Neue Rolle erstellen">
                                    //                             <IconButton 
                                    //                                 onClick={() => {this.createNewRole()}}
                                    //                                 sx={{
                                    //                                     height:"30px",
                                    //                                     width: "30px",
                                    //                                     color: "#ffffff",
                                    //                                     backgroundColor: "#00b825",
                                    //                                     "&:hover": { 
                                    //                                         backgroundColor: "#04d62e"
                                    //                                     }
                                    //                                 }}>
                                    //                                 <CheckIcon/>
                                    //                             </IconButton>
                                    //                         </Tooltip>
                                    //                         <Tooltip title="Abbruch">
                                    //                             <IconButton 
                                    //                                 onClick={() => {this.abortAddNewRoleMode()}}
                                    //                                 sx={{
                                    //                                     height:"30px",
                                    //                                     width: "30px",
                                    //                                     color: "#ffffff",
                                    //                                     backgroundColor: "#e5342a",
                                    //                                     "&:hover": { 
                                    //                                         backgroundColor: "#c75a54"
                                    //                                     }
                                    //                                 }}
                                    //                             >
                                    //                                 <AddIcon/>
                                    //                             </IconButton>
                                    //                         </Tooltip>
                                    //                     </div>
                                    //                 }
                                    //             </Grid>
                                    //             <Grid item xs={4}>
                                    //                 {/* <FormControl>
                                    //                     <TextField
                                    //                         label={"Rollenname"}
                                    //                         value={this.state.newRoleName}
                                    //                         size="small"
                                    //                         onChange={(e) => {this.changeNewRoleName(e.target.value)}}
                                    //                         sx={{width: "100%"}}
                                    //                     >
                                    //                     </TextField>
                                    //                     <FormHelperText id="component-helper-text">
                                    //                         {"Geben Sie die Bezeichnung der neuen Rolle ein"}
                                    //                     </FormHelperText>
                                    //                 </FormControl> */}
                                    //                 <Tooltip title="Neue Rolle hinzufügen">
                                    //                     <IconButton 
                                    //                         disabled={this.state.addNewRole}
                                    //                         onClick={() => {this.addNewRoleMode()}}
                                    //                         sx={{
                                    //                             height:"30px",
                                    //                             width: "30px",
                                    //                             color: "#ffffff",
                                    //                             backgroundColor: "#e5342a",
                                    //                             "&:hover": { 
                                    //                                 backgroundColor: "#c75a54"
                                    //                             }
                                    //                         }}>
                                    //                         <AddIcon/>
                                    //                     </IconButton>
                                    //                 </Tooltip>
                                    //             </Grid>
                                                
                                    //                 {
                                    //                     this.state.allPermissions.map((permission) => {
                                    //                         return(
                                    //                             <Grid item columnSpacing={0} xs={12}>
                                    //                                 <FormControlLabel
                                    //                                    control={<Checkbox
                                    //                                         style={{
                                    //                                             color: "#e5342a"
                                    //                                         }}
                                    //                                         checked={ArrayHelper.contains(this.state.rolesToAssign
                                    //                                             [
                                    //                                                 ArrayHelper.indexOf(this.state.rolesToAssign, (role) => role.id === this.state.roleToEdit)
                                    //                                             ].permissions,  (perm: any) => perm === permission.value)}
                                    //                                        onChange={() => this.clickCheckbox(permission)}
                                    //                                     />}
                                    //                                    label={permission.value.replace(".{ROLE}", "")}/>
                                    //                                    {this.hasVariant(permission) &&
                                    //                                         <TextField
                                    //                                             sx={{
                                    //                                                 color: "black",
                                    //                                                 width: "30%"
                                    //                                             }}
                                    //                                             select
                                    //                                             label={strings.role}
                                    //                                             value={this.state.userRole}
                                    //                                             size="small"
                                    //                                             onChange={(e) => {this.changeVariant(permission, e.target.value)}}
                                    //                                         >
                                    //                                             {
                                    //                                                 this.state.rolesToAssign.map((role) => {
                                    //                                                     return(
                                    //                                                         <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                    //                                                     )
                                    //                                                 })
                                    //                                             }
                                    //                                         </TextField>
                                    //                                    }
                                    //                             </Grid>
                                    //                         )
                                    //                     })
                                    //                 }
                                                
                                    //         </Grid>
                                    //     </AccordionDetails>
                                    // </Accordion>
                                }
                            {/* </Grid> */}
                        </Grid>
                    </Paper>
                </div>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(Verwaltung);