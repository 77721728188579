import React from "react";
import { withStyles} from '@mui/styles';
import { Typography } from "@mui/material";
import ITitleProps from "../../../interfaces/components/Layout/Elements/ITitleProps";

const useStyles = (theme: any) => ({

}); 

class PageTitle extends React.Component<ITitleProps, any> {
    constructor(props: ITitleProps) {
        super(props);

    }

    render(){
        return(
            <Typography 
                style={{
                    fontWeight: 500,
                    fontSize: "1.75em",
                    color: "#e5342a",
                    marginBottom: "0.5em"
                }}>
                    {this.props.title}
            </Typography>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(PageTitle);