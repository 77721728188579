import React from "react";
import { styled, withStyles} from '@mui/styles';
import MainPage from "../Public/MainPage";
import {TextField, Grid, Button, IconButton, Menu, MenuItem, MenuProps, Paper, Switch, Typography, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StringDistributor from "../../util/StringDistributor";
import PageTitle from "../Layout/Elements/PageTitle";
import IPasswordChangeProps from "../../interfaces/components/Closed/IPasswordChangeProps";
import IPasswordChangeState from "../../interfaces/components/Closed/IPasswordChangeState";
import IPasswordChange from "../../models/Communication/IPasswordChange";
import UserService from "../../services/UserService";
import { config } from "../../config";
import { Redirect } from "react-router-dom";

const useStyles = (theme: any) => ({
    paper: {
        width: "90%",
        padding: "1.25em",
        marginTop: "10px",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "600",
        marginRight: "1em"
    },
    mainTitle: {
        color: theme.palette.primary.main,
    },
    listItem: {
        display: "flex",
        flexDirection: "row" as 'row',
        width: "100%"
    },
    body: {
        
    }
}); 

class PasswordChange extends React.Component<IPasswordChangeProps, IPasswordChangeState> {
    constructor(props: IPasswordChangeProps) {
        super(props);

        this.state = {
            oldPass: "",
            newPass1: "",
            newPass2: "",
            error: false,
            progress: false,
            errorMessage: "",
            successMessage: "",
            success: false
        }
    }

    private onChangeOldPass(e: any) {
        this.setState({oldPass: e})
    }

    private onChangeNewPass1(e: any) {
        this.setState({newPass1: e})
    }
    private onChangeNewPass2(e: any) {
        this.setState({newPass2: e})
    }

    private change() {
        if(this.state.newPass1 !== this.state.newPass2) {
            this.setState({error: true, errorMessage: "Die Passwörter stimmen nicht überein!"})
        } else {
            let form: IPasswordChange = {
                oldPass: this.state.oldPass,
                newPass: this.state.newPass1
            }
            UserService.changePassword(this.changeSuccess.bind(this), this.changeError.bind(this), form)
            this.setState({progress: true})
        }
    }

    private changeSuccess(suc: any) {
        this.setState({progress: false, success: true, successMessage: "Passwort erfolgreich geändert!", oldPass: "", newPass1: "", newPass2: ""})
    }

    private changeError(err: any) {
        this.setState({progress: false, errorMessage: "Es ist ein Fehler aufgetreten, Probieren Sie es später noch einmal"})
    }

    render(){
        let {classes} = this.props;

        let strings= StringDistributor.get().profile

        return(
            <div>
                <PageTitle title={strings.profile + ": Passwort ändern"}/>
                <Paper className={classes.paper}>
                    <Grid container className={classes.body} spacing={2}>
                        {this.state.error &&
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Typography>{this.state.errorMessage}</Typography>
                            </Grid>
                        }
                        {this.state.success &&
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Typography>{this.state.successMessage}</Typography>
                            </Grid>
                        }
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <TextField 
                                    label={"Altes Passwort"} 
                                    size="small" 
                                    required
                                    type="password"
                                    value={this.state.oldPass}
                                    onChange={(e) => {this.onChangeOldPass(e.target.value)}}
                                >
                                </TextField>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <TextField 
                                    label={"Neues Passwort"} 
                                    size="small" 
                                    required
                                    type="password"
                                    value={this.state.newPass1}
                                    onChange={(e) => {this.onChangeNewPass1(e.target.value)}}
                                >
                                </TextField>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            <TextField 
                                    label={"Neues Passwort wiederholen"} 
                                    size="small" 
                                    required
                                    type="password"
                                    value={this.state.newPass2}
                                    onChange={(e) => {this.onChangeNewPass2(e.target.value)}}
                                >
                                </TextField>
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                            {!this.state.progress && 
                                <Button
                                    sx={{
                                        marginTop: "1em",
                                        width: "20%",
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        textTransform: "none",
                                        "&:hover": { 
                                            backgroundColor: "#c75a54"
                                        },
                                        "&:disabled": {
                                            backgroundColor: "#ffffff"
                                        }
                                    }}
                                    disabled={this.state.newPass1 === this.state.newPass2 && this.state.newPass1 !== "" && this.state.oldPass !== "" ? false : true}
                                    onClick={() => this.change()}
                                >
                                    Ändern
                                </Button>
                            }
                            {this.state.progress &&
                                <CircularProgress 
                                    sx={{
                                        marginTop: "1em",
                                        float: "right",
                                        color: "#e5342a"
                                    }}
                                />
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(PasswordChange);
