import { config } from "../config";
import IParamFunction from "../Functions/IParamFunction";
import IPosting from "../models/Communication/IPosting";
import IPostingResponse from "../models/Communication/IPostingResponse";
import AxiosHelper from "../util/AxiosHelper";

export default class PostingService {

    public static create(success: IParamFunction<any>, error: IParamFunction<object>, posting: IPosting){
        AxiosHelper.get().put(config.api + config.restExtension + "/postings/", posting, "application/json")
        .then(response => {
            //console.log(response)
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static get(success: IParamFunction<any>, error: IParamFunction<object>){
        AxiosHelper.get().get(config.api + config.restExtension + "/postings/")
        .then(response => {
            //console.log(response)
            let data = response.data as IPostingResponse[]
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

        public static getUnAuth(success: IParamFunction<any>, error: IParamFunction<object>){
        AxiosHelper.get().get(config.api + config.restExtension + "/postings/unAuth")
        .then(response => {
            //console.log(response)
            let data = response.data as IPostingResponse[]
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static getApplied(success: IParamFunction<any>, error: IParamFunction<object>, postingId: string) {
        AxiosHelper.get().put(config.api + config.restExtension + "/postings/applicants", postingId, "application/json")
        .then(response => {
            //console.log(response)
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static apply(success: IParamFunction<any>, error: IParamFunction<object>, postingId: number) {
        AxiosHelper.get().put(config.api + config.restExtension + "/postings/apply", postingId, "application/json")
        .then(response => {
            //console.log(response)
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static withdraw(success: IParamFunction<any>, error: IParamFunction<object>, postingId: any) {
        AxiosHelper.get().delete(config.api + config.restExtension + "/postings/withdraw", postingId, "application/json")
        .then(response => {
            console.log(response)
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

    public static applyMultiple(success: IParamFunction<any>, error: IParamFunction<object>, postingId: Array<string>) {
        AxiosHelper.get().put(config.api + config.restExtension + "/postings/applyMultiple", postingId, "application/json")
        .then(response => {
            //console.log(response)
            let data = response.data 
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }

}