import React from "react";

import { withStyles} from '@mui/styles';
import IApplicantFilterProps from "../../../interfaces/components/Layout/Elements/IApplicantFilterProps";
import IApplicantFilterState from "../../../interfaces/components/Layout/Elements/IApplicantFilterState";
import { Button,Checkbox, Divider, FormControlLabel, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Paper} from "@mui/material";
import StringDistributor from "../../../util/StringDistributor";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { PermissionService } from "../../../services/PermissionService";
import IFilter from "../../../models/IFilter";
import FieldValueGetter from "../../../util/FieldValueGetter";

const useStyles = (theme: any) => ({
    container: {
        width: "90%",
        height: "2em",
        marginBottom: "2em"
    },
    textField: {
        fontSize: 20,
        backgroundColor:"#ffffff",
        height: "30px",
        "&.Mui-focused fieldset": {
                borderColor: '#e5342a',
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": { 
                borderRadius: "20px",
                borderColor: "#000fff"
            },
        },
        "& .MuiInputLabel": {
            fontSize: "8px"
        }
    }
});

const subjects: string[] = [
    "Mathematik",
    "Deutsch",
    "Englisch",
    "Sport",
    "Chemie",
    "Physik",
    "Informatik",
    "Biologie"
]

class ApplicantFilter extends React.Component<IApplicantFilterProps, IApplicantFilterState> {
    constructor(props: IApplicantFilterProps) {
        super(props);

        this.state = {
            active: false,
            applicantList: this.props.applicants,
            subject1: "",
            subject2: "",
            name:"",
            email:""
        }
    }

    componentDidMount() {

    }

    private changeSubject1(e: string) {
        this.setState({subject1: e});
    };

    private changeSubject2(e: string) {
        this.setState({subject2: e});
    };

    private changeName(e: string) {
        this.setState({name: e});
    };

    private changeEmail(e: string) {
        this.setState({email: e});
    };

    private filter() {
        let arr = this.state.applicantList;

        let found;

        if(this.state.name !== "") {
           found = arr.filter(applicant => {

                return (FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase().includes(this.state.name.toLowerCase()) ||
                FieldValueGetter.getValue("Nachname", applicant.fieldGroups).toLowerCase().includes(this.state.name.toLowerCase()) ||
                FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase().includes(this.state.name.toLowerCase()) )  ||
                this.state.name.toLowerCase() === FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase() + " " + FieldValueGetter.getValue("Nachname", applicant.fieldGroups).toLowerCase() ||
                this.state.subject1 === FieldValueGetter.getValue("Fach 1", applicant.fieldGroups) ||
                this.state.subject2 === FieldValueGetter.getValue("Fach 2", applicant.fieldGroups) ||
                this.state.subject2 === FieldValueGetter.getValue("Fach 1", applicant.fieldGroups) ||
                this.state.subject1 === FieldValueGetter.getValue("Fach 2", applicant.fieldGroups) ||
                this.state.email === applicant.email;
            });
        } else {
            found = arr.filter(applicant => {

                return this.state.subject1 === FieldValueGetter.getValue("Fach 1", applicant.fieldGroups) ||
                this.state.subject2 === FieldValueGetter.getValue("Fach 2", applicant.fieldGroups) ||
                this.state.subject2 === FieldValueGetter.getValue("Fach 1", applicant.fieldGroups) ||
                this.state.subject1 === FieldValueGetter.getValue("Fach 2", applicant.fieldGroups) ||
                this.state.email === applicant.email;
            });
        }
        // var x = arr.filter(applicant => {

        //     return (FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase().includes(this.state.name.toLowerCase()) ||
        //     FieldValueGetter.getValue("Nachname", applicant.fieldGroups).toLowerCase().includes(this.state.name.toLowerCase()) ||
        //     FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase().includes(this.state.name.toLowerCase()) )  ||
        //     this.state.subject1 === FieldValueGetter.getValue("Fach 1", applicant.fieldGroups) ||
        //     this.state.subject2 === FieldValueGetter.getValue("Fach 2", applicant.fieldGroups) ||
        //     this.state.subject2 === FieldValueGetter.getValue("Fach 1", applicant.fieldGroups) ||
        //     this.state.subject1 === FieldValueGetter.getValue("Fach 2", applicant.fieldGroups) ||
        //     this.state.email === applicant.email //||
//   (          this.state.name.toLowerCase() === FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase() + " " + FieldValueGetter.getValue("Nachname", applicant.fieldGroups).toLowerCase() ||
//             this.state.name.toLowerCase() === FieldValueGetter.getValue("Vorname", applicant.fieldGroups).toLowerCase() ||
//             this.state.name.toLowerCase() === FieldValueGetter.getValue("Nachname", applicant.fieldGroups).toLowerCase()) ||
            

        //     ;
        // });

        this.props.getFiltered(found);

        this.setState({active: true})
    }

    public getFilterStatus(): IFilter {
        let filter: IFilter = {
            name: this.state.name,
            email: this.state.email,
            subject1: this.state.subject1,
            subject2: this.state.subject2
        }
        return filter;
    }

    private removeFilter() {

        this.setState({
            active: false,
            subject1: "",
            subject2: "",
            name:"",
            email:""
        })

        this.props.getFiltered(this.state.applicantList);
    }

    render() {

        let {classes} = this.props;
        let strings = StringDistributor.get().filter;

        return(
            <div className={classes.container}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={2}>
                        <TextField 
                            size="small"
                            InputProps={{
                                className: classes.textField,
                                style: {fontSize: "14px"}
                            }}
                            value={this.state.name}
                            label={strings.name}
                            onChange={(e) => {this.changeName(e.target.value)}}
                        >
                            {strings.name}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField 
                            size="small"
                            InputProps={{
                                className: classes.textField,
                                style: {fontSize: "14px"}
                            }}
                            value={this.state.email}
                            label={strings.email}
                            onChange={(e) => {this.changeEmail(e.target.value)}}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            InputProps={{
                                className: classes.textField,
                                style: {fontSize: "14px"}
                            }}
                            sx={{
                                color: "black",
                                width: "100%"
                            }}
                            select
                            label={strings.subject + " 1"}
                            value={this.state.subject1}
                            size="small"
                            onChange={(e) => {this.changeSubject1(e.target.value)}}
                        >
                            {
                                subjects.map((subject) => {
                                    return(
                                        <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            InputProps={{
                                className: classes.textField,
                                style: {fontSize: "14px"}
                            }}
                            sx={{
                                color: "black",
                                width: "100%"
                            }}
                            select
                            label={strings.subject + " 2"}
                            value={this.state.subject2}
                            size="small"
                            onChange={(e) => {this.changeSubject2(e.target.value)}}
                        >
                            {
                                subjects.map((subject) => {
                                    return(
                                        <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        {PermissionService.checkPermission("user.mark") && 
                            <FormControlLabel control={<Checkbox
                                    style={{
                                        color: "#e5342a"
                                    }}
                                    defaultChecked />}
                                    label={"Markiert"}
                            />
                        }
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            onClick={() => this.filter()}
                            sx={{
                                height:"30px",
                                width: "30px",
                                color: "#ffffff",
                                backgroundColor: "#e5342a",
                                "&:hover": { 
                                    backgroundColor: "#c75a54"
                                }
                            }}
                        >
                            <CheckIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                        {this.state.active && 
                            <IconButton
                                onClick={() => this.removeFilter()}
                                sx={{
                                    height:"30px",
                                    width: "30px",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    "&:hover": { 
                                        backgroundColor: "#c75a54"
                                    }
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(ApplicantFilter);