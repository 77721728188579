import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogContentText, FormControlLabel, Grid, Checkbox, Button, Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {TableContainer, Table, TableCell, TableRow, TableBody, Box, Divider, Switch, Tab, Tabs} from "@mui/material";

import { styled, withStyles} from '@mui/styles';

import CookieHelper from "../../../util/CookieHelper";
import IfHelper from "../../../util/IfHelper";
import {Link} from "react-router-dom";
import {config} from "../../../config";
import ArrayHelper from "../../../util/ArrayHelper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {withRouter} from "react-router";
import ICookieBannerProps from '../../../interfaces/components/Layout/Elements/ICookieBannerProps';
import ICookieBannerState from '../../../interfaces/components/Layout/Elements/ICookieBannerState';
import { CookieType } from '../../../Enums/CookieType';
import ICookieInfo from '../../../models/ICookieInfo';
import StringDistributor from '../../../util/StringDistributor';

const usesStyles = (theme: any) => ({
    link: {
        'text-decoration': 'none' as 'none',
        color: '#e5342a',
        fontSize: '0.875rem'
    },
    button: {
        textTransform: 'initial' as 'initial'
    },
    list: {
        'list-style-type': 'none',
        padding: 0,
        width: '100%',
    },
    table: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        "& td": {
            border: '1px solid ' + '#e5342a'
        },
        "& tr > td:first-child":{
            width: '15%'
        }
    },
    tableContainer: {
        width: '100%',
    },
    divider: {
        backgroundColor: '#e5342a'
    },
    tabPanel: {
        marginTop: theme.spacing(4)
    },
    tabs: {
        "& button": {
            width: '50%'
        }
    }
});

class CookieBanner extends React.Component<ICookieBannerProps, ICookieBannerState>{
    constructor(props : ICookieBannerProps){
        super(props);

        this.state = {
            open: false,
            accOpen: new Map<CookieType, boolean>(),
            checkState: new Map([
                [CookieType.Essential, true],
                [CookieType.ExternalMedia, false]
            ]),
            tab: 1
        }
    }

    componentDidMount(){
        (window as any).openBanner = () => {
            let externalMedia = CookieHelper.getAcceptedCookies();

            this.setState({open: true, checkState: externalMedia});
        }

        let prop = this.props as any;

        prop.history.listen((location: any) => {
            let pathName = location.pathname as string;

            if(pathName === "/" + config.impressumRoute || pathName === "/" + config.privacyRoute){
                this.setState({open: false});
            }else{
                let open = CookieHelper.hasCookiesAccepted();
                if(open){
                    this.setState({open: true});
                }
            }
        });

        let open = CookieHelper.hasCookiesAccepted();

        let externalMedia = CookieHelper.getAcceptedCookies();

        if(!open && !IfHelper.isNull(this.props.open)){
            open = this.props.open as boolean;
        }

        if(window.location.pathname === "/" + config.impressumRoute || window.location.pathname === "/" + config.privacyRoute){
            open = false;
        }

        this.setState({open: open, checkState: externalMedia})
    }

    private handleCheckChange(check: CookieType){
        let vals = this.state.checkState;
        let val = vals.get(check);

        if(IfHelper.isNull(val)){
            vals.set(check, true);
        }else{
            vals.set(check, !val);
        }

        this.setState({checkState: vals});
    }

    private handleAcceptAll(){
        let values = new Map<string, boolean>();

        for(let item in CookieType){
            if(isNaN(Number(item))){
                values.set(item, true);
            }
        }

        CookieHelper.saveCookies(values);
        this.setState({open: false});
    }
    
    private handleSave(){
        let values = new Map<string, boolean>();

        this.state.checkState.forEach((value, key) => values.set(CookieType[key], value));

        CookieHelper.saveCookies(values);
        this.setState({open: false});
    }

    private handleAccChange(acc: CookieType, event: React.ChangeEvent<{}>){
        let target = event.target as any;

        if(target.nodeName !== 'INPUT'){
            let vals = this.state.accOpen;
            let val = vals.get(acc);
    
            if(IfHelper.isNull(val)){
                vals.set(acc, true);
            }else{
                vals.set(acc, !val);
            }
    
            this.setState({accOpen: vals});
        }
    }

    private handleTabChange(event: React.ChangeEvent<{}>, value: number){
        this.setState({tab: value});
    }

    render(){
        let {classes} = this.props as any;

        let rootLocale = StringDistributor.get();
        let locale = rootLocale.cookies;

        let infos = CookieHelper.getCookieInformation(rootLocale);
        let groups = this.getGroupedInfo(infos);
        let types = this.extractTypes(infos);

        return (
            <Dialog disableEscapeKeyDown open={this.state.open} maxWidth="xs">
                <DialogTitle>{locale.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {locale.message}
                    </DialogContentText>
                    <Tabs value={this.state.tab} onChange={this.handleTabChange.bind(this)} className={classes.tabs}>
                        <Tab label={locale.simple} value={1}/>
                        <Tab label={locale.details} value={2}/>
                    </Tabs>
                    <div hidden={this.state.tab !== 1} className={classes.tabPanel}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    {types.map((type) => (
                                        <FormControlLabel control={<Checkbox checked={this.state.checkState.get(type)} color="secondary" onChange={(event: any) => this.handleCheckChange(type)}/>} 
                                        label={locale.types.get(type)} disabled={type === CookieType.Essential}/>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Button sx={{
                                        marginTop: "1em",
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        "&:hover": {
                                            backgroundColor: "#eb7771"
                                        }
                                    }}  variant="contained" onClick={this.handleAcceptAll.bind(this)}>
                                    {locale.accept}
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Button sx={{
                                        marginTop: "1em",
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        "&:hover": {
                                            backgroundColor: "#eb7771"
                                        }
                                    }}  variant="contained" onClick={this.handleSave.bind(this)}>
                                    {locale.save}
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Link to={"/" + config.privacyRoute} className={classes.link}>{locale.privacyNotice}</Link>
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Link to={"/" + config.impressumRoute} className={classes.link}>{locale.impress}</Link>
                            </Grid>
                        </Grid>
                    </div>
                    <div hidden={this.state.tab !== 2} className={classes.tabPanel}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} className="center">
                                <Button 
                                    sx={{
                                        marginTop: "1em",
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        "&:hover": {
                                            backgroundColor: "#eb7771"
                                        }
                                    }} 
                                    variant="contained" 
                                    onClick={this.handleAcceptAll.bind(this)}>
                                    {locale.accept}
                                </Button>
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Button sx={{
                                            marginTop: "1em",
                                            color: "#ffffff",
                                            backgroundColor: "#e5342a",
                                            "&:hover": {
                                                backgroundColor: "#eb7771"
                                            }
                                        }} 
                                        variant="contained" 
                                        onClick={this.handleSave.bind(this)}>
                                    {locale.save}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {types.map((type) => (
                                <Accordion expanded={this.state.accOpen.get(type)} onChange={(event: any) => this.handleAccChange(type, event)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>{locale.types.get(type)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                            <FormControlLabel
                                                onClick={(event: any) => event.stopPropagation()}
                                                onFocus={(event: any) => event.stopPropagation()} 
                                                label=""
                                                control={<Switch 
                                                    checked={this.state.checkState.get(type)} 
                                                    onChange={(event) => {this.handleCheckChange(type); event.stopPropagation()}} 
                                                    disabled={type === CookieType.Essential}/>} />
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul className={classes.list}>
                                            {(groups.get(type) as ICookieInfo[]).map((info, index) => (
                                                <li key={index}>
                                                    <TableContainer className={classes.tableContainer}>
                                                        <Table className={classes.table}>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>{locale.nameHeader}</TableCell>
                                                                    <TableCell>{info.name}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>{locale.providerHeader}</TableCell>
                                                                    <TableCell>{info.provider}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>{locale.purposeHeader}</TableCell>
                                                                    <TableCell>{info.purpose}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell>{locale.expiryHeader}</TableCell>
                                                                    <TableCell>{info.expiration}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        <Divider className={classes.divider}/>
                                                    </TableContainer>
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                ))}
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Link to={"/" + config.privacyRoute} className={classes.link}>{locale.privacyNotice}</Link>
                            </Grid>
                            <Grid item xs={6} className="center">
                                <Link to={"/" + config.impressumRoute} className={classes.link}>{locale.impress}</Link>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    private getGroupedInfo(infos: ICookieInfo[]){
        let types = this.extractTypes(infos);

        let groups = new Map<CookieType, ICookieInfo[]>();

        for(let i = 0; i < types.length; i++){
            let type = types[i];

            groups.set(type, ArrayHelper.where(infos, (info) => info.type === type) as ICookieInfo[]);
        }

        return groups;
    }

    private extractTypes(infos: ICookieInfo[]){
        let types = [] as CookieType[];

        for(let i = 0; i < infos.length; i++){
            let info = infos[i];

            if(types.indexOf(info.type) === -1){
                types.push(info.type);
            }
        }

        return types;
    }
}
export default withRouter(withStyles(usesStyles, {withTheme: true})(CookieBanner) as any);