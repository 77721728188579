import React from 'react';
import './App.css';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import { withStyles } from '@mui/styles';

import { lato } from "./Fonts/Lato";

import IAppProps from './interfaces/IAppProps';
import IAppState from './interfaces/IAppState';
import ErrorScreen from './components/Layout/ErrorScreen';
import AuthContainer from './components/Layout/AuthContainer';
import LoginPage from './components/Public/Authentication/LoginPage';
import MainPage from './components/Public/MainPage';
import JobOverview from './components/Public/Main/JobOverview';
import { config } from './config';
import Profile from './components/Closed/Profile';
import Documents from './components/Closed/Documents';
import JobDetail from './components/Public/Main/JobDetail';
import CreatePosting from './components/Closed/CreatePosting';
import Reporting from './components/Closed/Reporting';
import Bewerberliste from './components/Closed/Bewerberliste';
import Register from './components/Public/Authentication/Register';
import Verwaltung from './components/Closed/Verwaltung';
import AngebotNachfrage from './components/Closed/Reporting/AngebotNachfrage';
import Lage from './components/Closed/Reporting/Lage';
import EnterEmail from './components/Public/PasswordReset/EnterEmail';
import NewPassword from './components/Public/PasswordReset/NewPassword';
import CookieBanner from './components/Layout/Elements/CookieBanner';

const usesStyles = (theme: any) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: "5px" 
    },
    overflowY: "hidden" as 'hidden',
    overflowX: "hidden" as 'hidden',
    '@font-face': [lato],
    FontFace: lato,
  }
});

class App extends React.Component<IAppProps, IAppState>{
  constructor(props: IAppProps) {
		super(props);

		this.state = {
			error: false
		}
		//require('dotenv').config()
	}

  componentDidMount() {
	}

  render() {
    if (this.state.error) {
			return (
				<ErrorScreen />
			)
		}

    return (
        <AuthContainer>
				  <CookieBanner />
          <HashRouter basename='/App'>
          <Route
              path={"/"}
              exact
              component={() => <LoginPage />}
            />
            <Route
              path={"/" + config.loginRoute}
              component={() => <LoginPage />}
            />
            <Route
              path={"/" + config.overviewRoute}
              render={() => <JobOverview />}
            />
            {/* <Route
              path="/"
              render={() => <JobOverview />}
            /> */}
            <Route
              path={"/" + config.profileRoute}
              render={() => <Profile />}
            />
            <Route
              path={"/" + config.documentsRoute}
              render={() => <Documents />}
            />
            <Route
              path={"/" + config.createPostingRoute}
              render={() => <CreatePosting />}
            />
            <Route
              path={"/" + config.reportingRoute}
              render={() => <Reporting />}
            />
            <Route
              path={"/" + config.applicantListRoute}
              render={() => <Bewerberliste />}
            />
            <Route
              path={"/" + config.clerkAdministration}
              render={() => <Verwaltung />}
            />
             <Route
              path={"/" + config.angebotNachfrageRoute}
              render={() => <AngebotNachfrage />}
            />
             <Route
              path={"/" + config.lageRoute}
              render={() => <Lage />}
            />
             <Route
              path={"/" + config.enterEmailRoute}
              render={() => <EnterEmail />}
            />
             <Route
              path={"/" + config.enterNewPassword}
              render={() => <NewPassword />}
            />
            {/* <Route
             path={"/"+config.registerRoute}
             exact
             component={() => <Register />}
    /> */}
            <Redirect
              from="*"
              to="/"
            />
          </HashRouter>
          {/* <Switch>
            <Route
              path={"/"}
              exact
              component={() => <LoginPage />}
            />
            <Route
              path={"/" + config.overviewRoute}
              render={() => <JobOverview />}
            />
            <Route
              path={"/" + config.profileRoute}
              render={() => <Profile />}
            />
            <Route
              path={"/" + config.documentsRoute}
              render={() => <Documents />}
            />
            <Route
              path={"/" + config.createPostingRoute}
              render={() => <CreatePosting />}
            />
            <Route
              path={"/" + config.reportingRoute}
              render={() => <Reporting />}
            />
            <Route
              path={"/" + config.applicantListRoute}
              render={() => <Bewerberliste />}
            />
            <Route
              path={"/" + config.clerkAdministration}
              render={() => <Verwaltung />}
            />
             <Route
              path={"/" + config.angebotNachfrageRoute}
              render={() => <AngebotNachfrage />}
            />
             <Route
              path={"/" + config.lageRoute}
              render={() => <Lage />}
            />
             <Route
              path={"/" + config.enterEmailRoute}
              render={() => <EnterEmail />}
            />
             <Route
              path={"/" + config.enterNewPassword}
              render={() => <NewPassword />}
            />
            <Route
              path={"/*"}
              render={() => <JobOverview />}
            />
            <Redirect
              from="*"
              to="/"
            />
          </Switch> */}
        </AuthContainer>
		);
  }
}

export default withStyles(usesStyles, { withTheme: true })(App);
