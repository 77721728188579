import axios from 'axios';
import IParamFunction from '../Functions/IParamFunction';
import AuthService from '../services/AuthService';

export default class AxiosHelper{

    private static instance: AxiosHelper | undefined;

    public static get(){
        if(typeof this.instance === "undefined")
            this.instance = new AxiosHelper();

        return AxiosHelper.instance as AxiosHelper;
    }

    private constructor(){}

    public get(url: string) : Promise<any>{
        let didRetry = false;

        return axios.get(url, {
            headers: {
                "Authorization": AuthService.get().getToken(),
                "X-Auth": AuthService.get().getToken(),
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(err => {
            if(typeof err !== "undefined" && err.response?.status === 401){
                if(!didRetry){
                    didRetry= true;
                    return AuthService.get().promiseRefresh()
                    .then(() => {
                        return this.get(url);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }else{
                    return Promise.reject();
                }
            }

            return Promise.reject(err);
        })
    }

    public getParam(url: string, load: any) : Promise<any>{
        let didRetry = false;

        return axios.get(url, {
            headers: {
                "X-Auth": AuthService.get().getToken(),
                "Content-Type": "application/json;charset=UTF-8",
            }
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(err => {
            if(typeof err !== "undefined" && err.response?.status === 401){
                if(!didRetry){
                    didRetry= true;
                    return AuthService.get().promiseRefresh()
                    .then(() => {
                        return this.get(url);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }else{
                    return Promise.reject();
                }
            }

            return Promise.reject(err);
        })
    }



    public patch(url: string, obj: any) : Promise<any>{
        let didRetry = false;

        return axios.patch(url, obj, {
            headers: {
                "Authorization": AuthService.get().getToken(),
                "X-Auth": AuthService.get().getToken(),
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(err => {
            if(typeof err !== "undefined" &&err.response?.status === 401){
                if(!didRetry){
                    didRetry = true;
                    return AuthService.get().promiseRefresh()
                    .then(() => {
                        return this.patch(url, obj);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }else{
                    return Promise.reject();
                }
            }

            return Promise.reject(err);
        });
    }

    public post(url: string, obj: any, contentType: string) : Promise<any>{
        let didRetry = false;

        return axios.post(url, obj, {
            headers: {
                "Authorization": AuthService.get().getToken(),
                "X-Auth": AuthService.get().getToken(),
                'Content-type': contentType,
                "Content-Type": "application/json;charset=UTF-8"
            },
            
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(err => {
            if(typeof err !== "undefined" && err.response.status === 401){
                if(!didRetry){
                    didRetry = true;
                    return AuthService.get().promiseRefresh()
                    .then(() => {
                        return this.post(url, obj, contentType);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }else{
                    return Promise.reject();
                }
            }

            return Promise.reject(err);
        });
    }   

    public put(url: string, obj: any, contentType: string) : Promise<any>{
        let didRetry = false;
        return axios.put(url, obj, {
            headers: {
                "Authorization": AuthService.get().getToken(),
                "X-Auth": AuthService.get().getToken(),
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(err => {
            if(typeof err !== "undefined" &&err.response?.status === 401){
                if(!didRetry){
                    didRetry = true;
                    return AuthService.get().promiseRefresh()
                    .then(() => {
                        return this.put(url, obj, "application/json;charset=UTF-8");
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }else{
                    return Promise.reject();
                }
            }

            return Promise.reject(err);
        });
    }

    public delete(url: string, obj: any, contentType: string) : Promise<any>{
        let didRetry = false;
        return axios.delete(url, {
            data: obj,
            headers: {
                "X-Auth": AuthService.get().getToken(),
                'Content-type': contentType,
                "Content-Type": "application/json;charset=UTF-8"
            }
        })
        .then(response => {
            return Promise.resolve(response);
        })
        .catch(err => {
            if(typeof err !== "undefined" &&err.response.status === 401){
                if(!didRetry){
                    didRetry = true;
                    return AuthService.get().promiseRefresh()
                    .then(() => {
                        return this.delete(url, obj, contentType);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }else{
                    return Promise.reject();
                }
            }

            return Promise.reject(err);
        });
    }
}