import React, { Component } from 'react';
import IRegisterProps from '../../../interfaces/components/Public/Auth/IRegisterProps';
import IRegisterState from '../../../interfaces/components/Public/Auth/IRegisterState';
import { withStyles } from '@mui/styles';

import { Button, Box, Checkbox, FormControlLabel, Typography, InputBase, Tab, CircularProgress, TextField, Grid, Paper } from '@mui/material';
import ILoginProps from '../../../interfaces/components/Public/Auth/ILoginProps';
import ILoginState from '../../../interfaces/components/Public/Auth/ILoginState';
import ILoginForm from '../../../models/ILoginForm';
import AuthService from '../../../services/AuthService';
import { Redirect, Link } from 'react-router-dom';
import { config } from '../../../config';
import StringDistributor from '../../../util/StringDistributor';

const useStyles = (theme: any) => ({
    body: {
        width: "80%"
    },
    typo: {
        color: "#f5655d",
        fontSize: "14px",
        borderLeft: "5px solid #e5342a",
        padding: "5px"
    }
}); 

class Login extends React.Component<ILoginProps, ILoginState> {
    
    constructor(props: ILoginProps) {
        super(props);

        this.state = {
            progress: false,
            finish: false,
            email: '',
            password: '',
            enterEmail: false,
            isLoginError: false,
            errorMessage: ''
        }
    }

    private onMailChange(e: any) {
        this.setState({ email: e});
    }
    private onPasswordChange(e: any) {
        this.setState({ password: e });
    }

    private login() {
        let auth = StringDistributor.get().auth
        let form: ILoginForm = {
            email: this.state.email,
            password: this.state.password
        }

        if(this.state.email === "" || this.state.email === null) {
            this.loginError(auth.missingMail)
        } else if (this.state.password === "" || this.state.password === null) {
            this.loginError(auth.missingPassword)
        } else {
            AuthService.get().login(form, this.loginSucces.bind(this), this.loginError.bind(this))
            this.setState({progress: true})
        }
    }

    private loginSucces() {
        this.setState({finish: true})
    }

    private loginError(err: any) {
        this.setState({progress: false, isLoginError: true, errorMessage: err})
    }

    private passwordResetClick() {
        this.setState({enterEmail: true})
    }
    
    render() {
        let strings = StringDistributor.get().login;

        let auth = StringDistributor.get().auth;

        let {classes} = this.props;

        if(this.state.finish) {
            return (<Redirect to={"/" + config.overviewRoute}/>);
        }

        if(this.state.enterEmail) {
            return (<Redirect to={"/" + config.enterEmailRoute}/>);
        }
        
        return (
            <div className={classes.body}>
                <Grid container spacing={1}>
                    <Grid item xs={10} md={10} sm={10}>
                        {this.state.isLoginError &&
                            <Paper classes={classes.errorPaper}>
                                <div className={classes.typo} >
                                    {this.state.errorMessage}
                                </div>
                            </Paper>
                        }
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            id="email"
                            placeholder={strings.email}
                            required
                            fullWidth
                            name="email"
                            type="email"
                            onChange={(e) => {this.onMailChange(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <TextField
                            id="password"
                            placeholder={strings.password}
                            required
                            fullWidth
                            name="password"
                            type="password"
                            onChange={(e) => {this.onPasswordChange(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                        <FormControlLabel control={<Checkbox
                            style={{
                                color: "#e5342a"
                            }}
                            defaultChecked />}
                            label={strings.rememberMe}/>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6}>
                        <Link 
                            onClick={() => this.passwordResetClick()}
                            style={{ textDecoration: 'underline', color: "#333535" }} 
                            to={"/" + config.enterEmailRoute}>
                            {strings.forgotPasswort}
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}
                        style={{display: "flex", justifyContent: "center"}}
                    >
                        {!this.state.progress && 
                            <Button variant="contained"
                                sx={{
                                    marginTop: "1em",
                                    color: "#ffffff",
                                    backgroundColor: "#e5342a",
                                    "&:hover": {
                                        backgroundColor: "#eb7771"
                                    }
                                }}
                                onClick={() => this.login()}>
                                {strings.login}
                            </Button>
                        }
                        {this.state.progress &&
                            <CircularProgress 
                                sx={{
                                    marginTop: "1em",
                                    float: "right",
                                    color: "#e5342a"
                                }}
                            />
                        }
                    </Grid>
                </Grid>
                

                
                {/* <Grid container>
                    <Grid item xs={6} md={6} sm={6} container spacing={1}>
                        <FormControlLabel control={<Checkbox
                            style={{
                                color: "#e5342a"
                            }}
                            defaultChecked />}
                            label={strings.rememberMe}/>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6} lg={6}>
                        <Link 
                            onClick={() => this.passwordResetClick()}
                            style={{ textDecoration: 'none', color: "#333535" }} 
                            to={"/" + config.enterEmailRoute}>
                            {strings.forgotPasswort}
                        </Link>
                    </Grid>
                </Grid> */}
                
            </div >
        );
    }
}

export default withStyles(useStyles, {withTheme: true})(Login);