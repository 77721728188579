
import cookie from 'react-cookies';
import moment from 'moment';

export default class NavService {
    private static instance : NavService | undefined;
    public static readonly COOKIE_NAME = "LEHRERPORTAL_NAV";
    private currentPage: string = "";
 
    public static get(){
        if(this.instance === undefined)
            this.instance = new NavService();

        return this.instance;
    }

    public setNav(nav: string) {
        this.currentPage = nav;

        cookie.save(NavService.COOKIE_NAME, nav, {
            sameSite: true,
            expires: moment().add(1, 'days').toDate()
        });
    }

    public getNav() {
        return cookie.load(NavService.COOKIE_NAME);
    }


}