import React from "react";
import { withStyles} from '@mui/styles';
import MainPage from "../MainPage";
import IJobOverviewState from "../../../interfaces/components/Public/Main/IJobOverviewState";
import IJobOverviewProps from "../../../interfaces/components/Public/Main/IJobOverviewProps";
import { Button, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import JobCard from "../../Layout/Elements/JobCard";
import IPosting from "../../../models/Communication/IPosting";
import JobDetail from "./JobDetail";
import PageTitle from "../../Layout/Elements/PageTitle";
import ArrayHelper from "../../../util/ArrayHelper";
import StringDistributor from "../../../util/StringDistributor";
import PublicService from "../../../services/PublicService";
import PostingService from "../../../services/PostingService";
import LoadingScreen from "../../Layout/Elements/LoadingScreen";
import { PermissionService } from "../../../services/PermissionService";
import ConfirmDialog from "../../Layout/Elements/ConfirmDialog";
import IPostingResponse from "../../../models/Communication/IPostingResponse";
import LButton from "../../Layout/Elements/Pieces/LButton";
import AuthService from "../../../services/AuthService";
import MessageBox from "../../Layout/Elements/MessageBox";

const useStyles = (theme: any) => ({
    detailsDiv: {
        height:"100%"
    }
}); 

class JobOverview extends MainPage<IJobOverviewProps, IJobOverviewState> {
    constructor(props: IJobOverviewProps) {
        super(props);

        this.state = {
            loading: true,
            openDetail: false,
            openConfimDialog: false,
            openWithdrawDialog: false,
            withdrawId: 0,
            openIndex: 0,
            checkedBoxes: [],
            postings: [],
            openApplySuccessDialog: false
        }
    }

    componentDidMount() {
        if(AuthService.get().isAuthenticated()) {
            PostingService.get(this.getPostingsSuccess.bind(this), this.getPostingsError.bind(this));
        } else {
            PostingService.getUnAuth(this.getPostingsSuccess.bind(this), this.getPostingsError.bind(this));
        }
    }

    private getPostingsSuccess(posts: IPostingResponse[]) {
        this.setState({postings: posts, loading: false})
    }

    private getPostingsError(err: any) {
    }

    private openJobDetail(index: number) {
        this.setState({openDetail: true, openIndex: index})
    }

    private back() {
        this.setState({openDetail: false, checkedBoxes: []})
    }

    private clickCheckBox(identificationNumber: string, applied: boolean, id: number | undefined) {
        if(!applied) { // noch nicht auf diese Stelle beworben
            let boxes = this.state.checkedBoxes;

            if(ArrayHelper.contains(boxes, (box) => box === identificationNumber)) {
                let index = ArrayHelper.indexOf(boxes, (box) => box === identificationNumber);

                boxes.splice(index, 1);

                this.setState({checkedBoxes: boxes})
            } else {
                boxes.push(identificationNumber);

                this.setState({checkedBoxes: boxes})
            }
        } else { // bewerbung zurückziehen
            if(id !== undefined) {
                this.setState({openWithdrawDialog: true, withdrawId: id})
            }
        }
    }

    private withdraw() {
        PostingService.withdraw(this.withdrawSuccess.bind(this), this.withdrawError.bind(this), this.state.withdrawId)
    }

    private withdrawSuccess(ans: any) {
        this.setState({openWithdrawDialog: false, withdrawId: 0})
    }

    private withdrawError(err: any) {
    }

    private openConfimDialog() {
        this.setState({openConfimDialog: true})
    }

    private closeDialog() {
        this.setState({openConfimDialog: false, openWithdrawDialog: false})
    }

    private applyButton() {
        this.setState({openConfimDialog: false})
        PostingService.applyMultiple(this.applyMSuccess.bind(this), this.applyMError.bind(this), this.state.checkedBoxes);
    }

    private applyMSuccess(ans: any) {
        this.setState({openApplySuccessDialog: true})
    }

    private applyMError(err: any) {
    }

    private handleDialogClose() {
        this.setState({openApplySuccessDialog: false})
    }

    getContent(){
        let {classes} = this.props;

        let strings = StringDistributor.get().postingOverview;

        if(this.state.openDetail) {
            return (
                <div className={classes.detailsDiv}>
                    {/* <IconButton style={{marginBottom: "5px"}} aria-label="bearbeiten" 
                        onClick={() => {this.back()}}>
                        <ArrowBackIcon />
                    </IconButton> */}
                    <Button 
                        sx={{ 
                            color: "#e5342a", 
                            border: "none", 
                            marginBottom: "5px",
                            marginTop: "5px",
                            "&:hover": { 
                                borderColor: "#e5342a"
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #e5342a",
                                borderRadius: "5px 5px 0 0"
                            },
                        }}
                        variant="outlined" 
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {this.back()}}>
                        {"Zurück zur Übersicht"}
                    </Button>
                    <JobDetail content={this.state.postings[this.state.openIndex]}/>
                </div>
            )
        }

        if(this.state.loading) {
            return(
                <div style={{display: "flex", flexDirection: "column", width: "90%",height:"10%"}}>
                    <PageTitle title={strings.overview}/>
                
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                    <Skeleton animation="wave" style={{height: "80px"}}/>
                </div>
            )
        }

        return(
            <div style={{width: "90%"}}>
                <PageTitle title={strings.overview}/>

                <div style={{display: "flex", flexDirection: "column", overflow: "scroll",height:"65vh" }}>
                    {this.state.postings.map((job: IPostingResponse, index: number) => (
                            <div key={job.identificationNumber} >
                                <JobCard 
                                    key={job.identificationNumber}
                                    tabIndex={index}
                                    checked={ArrayHelper.contains(this.state.checkedBoxes, (box) => box === job.identificationNumber)}
                                    aria-label={job.title}
                                    announcement={job} 
                                    clickDetail={() => this.openJobDetail(index)} 
                                    checkBox={() => this.clickCheckBox(job.identificationNumber, job.applied, job.id)}
                                    applied={job.applied}
                                />
                            </div>
                            
                            
                    ))}
                </div>
                <div style={{display: "flex", flexDirection: "column",justifyContent:"left"}}>
                    {PermissionService.checkPermission("posting.apply") && 
                        <LButton 
                            label={strings.apply} 
                            disabled={this.state.checkedBoxes.length > 0 ? false : true} 
                            onClick={() => {this.openConfimDialog()}}
                            sx={{
                                width: "15%",
                                marginLeft: "auto",
                            }}
                        />
                    }
                </div>
               
                
                <ConfirmDialog open={this.state.openConfimDialog}
                    title={strings.apply}
                    message={strings.applySelected}
                    acceptButtonText={"OK"}
                    declineButtonText={"Abbruch"}
                    decline={this.closeDialog.bind(this)}
                    accept={this.applyButton.bind(this)}/>

                <ConfirmDialog open={this.state.openWithdrawDialog}
                    title={strings.withdraw}
                    message={strings.withdrawText}
                    acceptButtonText={"OK"}
                    declineButtonText={"Abbruch"}
                    decline={this.closeDialog.bind(this)}
                    accept={this.withdraw.bind(this)}/>

                <MessageBox open={this.state.openApplySuccessDialog}
                            title={"Beworben"}
                            message={"Sie haben sich erfolgreich auf die ausgewählten Stellen beworben"}
                            buttonText={"OK"} 
                            onClose={this.handleDialogClose.bind(this)}/>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(JobOverview);