import * as React from 'react';
import PropTypes from "prop-types";
import {Backdrop, CircularProgress} from "@mui/material";
import { withStyles} from '@mui/styles';
import classes from '*.module.css';
import ThemeService from '../../../services/ThemeService';
import ILoadingScreenProps from '../../../interfaces/components/Layout/Elements/ILoadingScreenProps';
import { borderColor } from '@mui/system';

const usesStyles = (theme: any) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: "20em",
    },
    icon: {
        color: "#e5342a !important"
    }
  });

class LoadingScreen extends React.Component<ILoadingScreenProps>{
    static propTypes = {
        open : PropTypes.bool.isRequired,
        classes : PropTypes.any
    };


    constructor(props : ILoadingScreenProps){
        super(props);
    }

    render(){
        let {classes} = this.props as any;

        return (
            <div className={this.props.className + " " + classes.modal} style={{display: this.props.open ? 'flex' : 'none'}}>
                <CircularProgress  className={classes.icon}/>
            </div>
        )
    }
}

export default withStyles(usesStyles, {withTheme: true})(LoadingScreen);