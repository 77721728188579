import * as React from 'react';
import Background from './Background';

import {withStyles} from "@mui/styles";
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import CorporateIdentityService from '../../services/ThemeService';

const useStyles = (theme: any) => ({
    mainContainer: {
        maxWidth: 1600,
        'overflow': 'auto',
        'flex': '2'
    },
    skeleton: {
        backgroundColor: CorporateIdentityService.getAlphaColor(theme.palette.primary.main, 0.5)
    }
});

class LoadingSkeleton extends React.Component<any>{
    constructor(props: any){
        super(props);
    }

    render(){

        let {classes} = this.props;

        return (
        <Background>

            <Container className={classes.mainContainer}>
                <Skeleton width={250} variant="text" className={classes.skeleton}/>
                <Skeleton height={250} variant="rectangular" className={classes.skeleton}/>
                <Skeleton variant="text" className={classes.skeleton}/>
            </Container>

        </Background>
        );
    }
}
export default withStyles(useStyles, {withTheme: true})(LoadingSkeleton)