import ArrayHelper from "../util/ArrayHelper";
import AuthService from "./AuthService";
import { config } from "../config";
import IParamFunction from "../Functions/IParamFunction";
import IPermission from "../models/Communication/IPermission";
import AxiosHelper from "../util/AxiosHelper";

export class PermissionService {

    public static checkPermission(permissionToCheck: string): boolean {
        let userPermissions = AuthService.get().getPermissions();
        if(userPermissions !== undefined) {
            let perms = userPermissions.split(";", 30);

            if(ArrayHelper.contains(perms, (perm) => perm === permissionToCheck)) {
                return true
            }
            return false
        }
        return false
    }

    public static get(success: IParamFunction<any>, error: IParamFunction<object>){
        AxiosHelper.get().get(config.api + config.restExtension + "/subject/")
        .then(response => {
            console.log(response)
            let data = response.data
            success(data);
        })
        .catch(err => {
            error(err);
        })
    }
}