
import React from "react";
import { withStyles} from '@mui/styles';
import MainPage from "../MainPage";
import { Button, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import JobCard from "../../Layout/Elements/JobCard";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IJobDetailProps from "../../../interfaces/components/Public/Main/IJobDetailProps";
import IJobDetailState from "../../../interfaces/components/Public/Main/IJobDetailState";
import StringDistributor from "../../../util/StringDistributor";
import JobOverview from "./JobOverview";
import AuthService from "../../../services/AuthService";
import ConfirmDialog from "../../Layout/Elements/ConfirmDialog";
import MUIRichTextEditor from "mui-rte";
import PostingService from "../../../services/PostingService";
import ArrayHelper from "../../../util/ArrayHelper";
import { PermissionService } from "../../../services/PermissionService";
import IUser from "../../../models/Communication/IUser";
import UserService from "../../../services/UserService";
import ApplicantCard from "../../Layout/Elements/ApplicantCard";
import ApplicantDetail from "../../Closed/ApplicantDetail";
import SuccessDialog from "../../Layout/Elements/SuccessDialog";

const useStyles = (theme: any) => ({
    paper: {
        padding: "20px",
        height: "32em",
        overflowY: "scroll" as 'scroll',
        overflowX: "hidden" as 'hidden'
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: "24px"
    }
}); 


class JobDetail extends React.Component<IJobDetailProps, IJobDetailState> {
    constructor(props: IJobDetailProps) {
        super(props);

        this.state = {
            goBack: false,
            openDialog: false,
            openWithdrawDialog: false,
            loading: true,
            applicants: [],
            openApplicantDetails: false,
            openApplicantDetailsIndex: 0,
            applySuccessDialog: false
        }
    }

    componentDidMount(): void {
        if(AuthService.get().getRole() === 2) {
            PostingService.getApplied(this.getApplicantsSuccess.bind(this), this.getApplicantsError.bind(this), this.props.content.identificationNumber);
        }
    }

    private getApplicantsSuccess(ans: IUser[]) {
        this.setState({loading: false, applicants: ans})
    }

    private getApplicantsError(err: any) {
        console.log(err)
    }

    private openDialog() {
        this.setState({openDialog: true})
    }

    private openWithdrawDialog() {
        this.setState({openWithdrawDialog: true})
    }

    private handleAccept() {
        this.apply();
    }

    private handleCancel() {
        this.setState({openDialog: false, openWithdrawDialog: false, applySuccessDialog: false})
    }

    private applySuccessDialogClose() {
        this.setState({applySuccessDialog: false})
    }

    private apply() {
        if(this.props.content.id !== undefined) 
            PostingService.apply(this.applySucess.bind(this), this.applyError.bind(this), this.props.content.id);
        
    }

    private withdraw() {
        if(this.props.content.id !== undefined) 
            PostingService.withdraw(this.withdrawSuccess.bind(this), this.withdrawError.bind(this), this.props.content.id)
    }

    private withdrawSuccess(ans: any) {
        console.log(ans)
        this.setState({openWithdrawDialog: false})
    }

    private withdrawError(err: any) {
        console.log(err)
    }

    private applySucess(e: any) {
        console.log(e)
        this.setState({openDialog: false, applySuccessDialog: true})
    }

    private applyError(err: any) {
        console.log(err)
        this.setState({openDialog: false})
    }

    private markUser(id: number) {
        UserService.markUser(this.markSuccess.bind(this), this.markError.bind(this), id);
    }
    
    private markSuccess(ans: any) {
        console.log(ans)
    }

    private markError(err: any) {
        console.log(err)
    }

    private openDetail(index: number) {
        this.setState({openApplicantDetails: true, openApplicantDetailsIndex: index})
    }

    private back() {
        this.setState({openApplicantDetails: false})
    }

    private getDate(): string {
        let splitted = this.props.content.deadline.toString().split("T")[0].split("-");
        let date = splitted[2] + "." + splitted[1] + "." + splitted[0];

        return date;
    }

    render(){
        let {classes} = this.props;
        let strings = StringDistributor.get().announcement
        let dia = StringDistributor.get().postingOverview

        if(this.state.goBack) {
            return(
                <JobOverview/>
            )
        }

        if(this.state.openApplicantDetails) {
            return (
                <div className={classes.detailsDiv}>
                    {/* <IconButton style={{marginBottom: "5px"}} aria-label="bearbeiten" 
                        onClick={() => {this.back()}}>
                        <ArrowBackIcon />
                    </IconButton> */}
                    <Button 
                        sx={{ 
                            color: "#e5342a", 
                            border: "none", 
                            marginBottom: "2px",
                            "&:hover": { 
                                borderColor: "#e5342a"
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #e5342a",
                                borderRadius: "5px 5px 0 0"
                            },
                        }}
                        variant="outlined" 
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {this.back()}}>
                        {"Zurück zur Ausschreibung"}
                    </Button>
                    <ApplicantDetail applicant={this.state.applicants[this.state.openApplicantDetailsIndex]}/>
                </div>
            )
        }

        return(
            <div style={{width: "100%", height: "65vh", overflow: "scroll"}}>
                <div style={{width: "90%", display: "flex", flexDirection: "column", justifyContent: "center"}}> 
                    <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Typography variant="h5" style={{fontWeight: 600}}>{this.props.content.title}</Typography>
                            </Grid>
                            <Grid item xs={3} alignItems="center" style={{display: "flex", flexDirection: "row"}}>
                                <Typography  className={classes.title}>{strings.deadline + ": "}</Typography>
                                <Typography
                                    style={{marginLeft: "10px"}}
                                >
                                    {
                                        // this.props.content.deadline.getDay() + "." +
                                        // this.props.content.deadline.getMonth() + "." +
                                        // this.props.content.deadline.getFullYear()
                                        this.getDate()
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.title}>{strings.kennnummer + ": "}</Typography>
                                <Typography>{this.props.content.identificationNumber}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <MUIRichTextEditor 
                                    defaultValue={this.props.content.body}
                                    toolbar={false}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" className={classes.title}>{strings.subject1 + ": "}</Typography>
                                <Typography>{this.props.content.subject1}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="h6" className={classes.title}>{strings.subject2 + ": "}</Typography>
                                <Typography>{this.props.content.subject2}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.title}>{strings.requirements + ": "}</Typography>
                                <MUIRichTextEditor 
                                    defaultValue={this.props.content.requirements}
                                    toolbar={false}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={9}>

                            </Grid>
                            <Grid item xs={3}>
                                
                            </Grid>
                        </Grid>
                    </Paper>
                    {PermissionService.checkPermission("posting.apply") && 
                        <div>
                            {!this.props.content.applied &&
                                <Button
                                    sx={{
                                        marginTop: "1em",
                                        float: "right",
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        "&:hover": { 
                                            backgroundColor: "#eb7771"
                                        }
                                    }}
                                    onClick={() => this.openDialog()}
                                >
                                    Bewerben
                                </Button>
                            }
                            {this.props.content.applied &&
                                <Button
                                    sx={{
                                        marginTop: "1em",
                                        float: "right",
                                        color: "#ffffff",
                                        backgroundColor: "#e5342a",
                                        "&:hover": { 
                                            backgroundColor: "#eb7771"
                                        }
                                    }}
                                    onClick={() => this.openWithdrawDialog()}
                                >
                                    Zurückziehen
                                </Button>
                            }
                        </div>
                    }
                    <div style={{ marginTop: "1em", marginLeft: "15%", overflowX: "hidden"}}>
                        {
                            !this.state.loading && AuthService.get().getRole() === 2 &&
                                this.state.applicants.map((appl, index) => (
                                    <ApplicantCard 
                                        click={() => {this.openDetail(index)}} 
                                        mark={() => {this.markUser(appl.id)}}
                                        key={appl.id} 
                                        applicant={appl}/>
                                ))
                        }
                        {this.state.loading && AuthService.get().getRole() === 2 &&
                                    <CircularProgress 
                                        sx={{
                                            marginTop: "1em",
                                            color: "#e5342a",
                                            marginLeft:"40% "
                                            
                                        }}
                                    />
                        }
                    </div>
                </div>

                <ConfirmDialog open={this.state.openDialog}
                    title={dia.apply}
                    message={dia.really}
                    acceptButtonText={"OK"}
                    declineButtonText={"Abbruch"}
                    decline={this.handleCancel.bind(this)}
                    accept={this.handleAccept.bind(this)}/>

                <ConfirmDialog open={this.state.openWithdrawDialog}
                    title={dia.withdraw}
                    message={dia.withdrawText}
                    acceptButtonText={"OK"}
                    declineButtonText={"Abbruch"}
                    decline={this.handleCancel.bind(this)}
                    accept={this.withdraw.bind(this)}/>

                <SuccessDialog open={this.state.applySuccessDialog}
                    title={"Beworben!"}
                    message={"Sie habe sich erfolgreich auf diese Stelle beworben!"}
                    acceptButtonText={"OK"}
                    accept={this.applySuccessDialogClose.bind(this)}/>
            </div>
        )
    }
}
export default withStyles(useStyles, {withTheme : true})(JobDetail);